import React from 'react';
import axios from 'axios';
import clerk from '../utils/clerk';

type props = {};

type state = {
    transactions: any[];
    loading: boolean;
    creators: any[];
};

export default class Invoices extends React.PureComponent<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {
            transactions: [],
            loading: true,
            creators: []
        }
    }

    async componentDidMount() {
        const invoices = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/stripe/myInvoices`, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then(res => {
            return res.data;
        });
        console.log(invoices);
        // console.log(invoices.creators[0]);

        if (!invoices.msg) {
            this.setState({ transactions: invoices.invoices.data, loading: false, creators: invoices.creators });
        } else {
            this.setState({ loading: false });
        }

        // console.log(this.state.transactions);
    }

    getCreatorName(creatorID: string) {
        const creator = this.state.creators.find(creator => creator._id === creatorID);
        return creator ? creator.displayName : 'Unknown';
    }

    getUsername(creatorID: string) {
        const creator = this.state.creators.find(creator => creator._id === creatorID);
        return creator ? creator.username : 'Unknown';
    }

    render() {
        function formatCurrency(amount: number) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount / 100);
        }

        return <div className="flex bg-gray-800 rounded-md px-2 py-2 mt-4 h-full">
            <div className="w-full px-4 mt-2 w-full">
                <table className="table-auto w-full mt-2">
                    <thead>
                        <tr className="bg-gray-900 rounded-md">
                            <th className="text-left px-2 py-1">Creator</th>
                            <th className="text-left px-2 py-1">Amount</th>
                            <th className="text-left px-2 py-1">Date</th>
                            <th className="text-left px-2 py-1">Type</th>
                            <th className="text-left px-2 py-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            if (this.state.loading === false && this.state.transactions.length !== 0) {
                                return this.state.transactions.map((invoice, index) => {
                                    return <tr key={index} className="h-12">
                                        <td className="px-2 py-1"><a href={`${process.env.REACT_APP_URL}app/user/${this.getUsername(invoice.creatorID)}`} target="_blank">{this.getCreatorName(invoice.creatorID)}</a></td>
                                        <td className="px-2 py-1">{formatCurrency(invoice.amount_paid)}</td>
                                        <td className="px-2 py-1">{(() => {
                                            var d = new Date(0);
                                            d.setUTCSeconds(invoice.created)
                                            return d.toLocaleString();
                                        })()}</td>
                                        {/* <td className="px-2 py-1">{new Date(invoice.created).toLocaleDateString()}</td> */}
                                        <td className="px-2 py-1">{invoice.type === 'subscription' ? 'Subscription' : 'Post'}</td>
                                        {(() => {
                                            if (invoice.hosted_invoice_url !== null) {
                                                return <a href={invoice.hosted_invoice_url} target='_blank' className="px-2 py-1 text-black bg-white rounded-md shadow-md mt-2 absolute">View</a>
                                            }
                                        })()}
                                    </tr>
                                });
                            } else if (this.state.loading === false && this.state.transactions.length == 0) {
                                return <tr><td colSpan={5} className="px-2 py-1 text-center">No transactions found</td></tr>
                            }
                        })()}




                        {/* {this.state.loading ? <tr><td colSpan={4} className="px-2 py-1">Loading...</td></tr> : this.state.transactions.map((invoice, index) => {
                            return <tr key={index}>
                                <td className="px-2 py-1">{this.getCreatorName(invoice.creatorID)}</td>
                                <td className="px-2 py-1">{formatCurrency(invoice.amount)}</td>
                                <td className="px-2 py-1">{new Date(invoice.date).toLocaleDateString()}</td>
                                <td className="px-2 py-1">{invoice.type === 'subscription' ? 'Subscription' : 'Post'}</td>
                            </tr>
                        })} */}
                        {/* <tr>
                            <td className="px-2 py-1">ChrisLongFilms</td>
                            <td className="px-2 py-1">$10.00</td>
                            <td className="px-2 py-1">2021-09-01</td>
                            <td className="px-2 py-1">Subscription</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1">ChrisLongFilms</td>
                            <td className="px-2 py-1">$10.00</td>
                            <td className="px-2 py-1">2021-09-01</td>
                            <td className="px-2 py-1">Post</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1">ChrisLongFilms</td>
                            <td className="px-2 py-1">$10.00</td>
                            <td className="px-2 py-1">2021-09-01</td>
                            <td className="px-2 py-1">Post</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1">ChrisLongFilms</td>
                            <td className="px-2 py-1">$10.00</td>
                            <td className="px-2 py-1">2021-09-01</td>
                            <td className="px-2 py-1">Subscription</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    }
}