import Footer from "../components/Footer";
import Header from "../components/Header";
import Subscriptions from "../components/Subscriptions";
import ChatComponent from "../components/live/ChatComponent";
import VideoComponent from "../components/live/VideoComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import clerk from '../utils/clerk';

export default function LivePage() {
    // const [playbackID, setPlaybackID] = useState('');

    return <div>
        <Header />
        <ChatComponent />
        <Footer />
    </div>
}