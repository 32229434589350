import React from 'react';
import clerk from '../../utils/clerk';
import axios from 'axios';

type props = {

}

type state = {
    loading: boolean;
    users: number;
    posts: number;
    earnings: number;
    fairplay: number;
    widevine: number;
};

export default class DashboardComponent extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            users: 0,
            posts: 0,
            earnings: 0,
            fairplay: 0,
            widevine: 0
        }
    }

    componentDidMount() {

        // Check user permissions...

        clerk.session?.getToken().then((token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/admin/summary`, { headers: { 'x-auth-token': token } }).then((response) => {
                this.setState({
                    loading: false,
                    users: response.data.userCount,
                    posts: response.data.posts,
                    earnings: response.data.earnings,
                    fairplay: response.data.licenseRequests.fairplay,
                    widevine: response.data.licenseRequests.widevine
                });
            }).catch((error) => {
                console.error(error);
            });
        })


        // this.setState({
        //     loading: false,
        //     users: 100,
        //     posts: 1000,
        //     earnings: 10000,
        //     fairplay: 100,
        //     widevine: 100
        // });
    }

    render() {
        return <>
            <div className="grid grid-cols-5 gap-4 mt-4">
                <div className="bg-gray-800 rounded-md pb-4">
                    <h1 className="text-white text-xl font-bold p-4">Users</h1>
                    {(() => {
                        if (this.state.loading === true) {
                            return <div className="animate-pulse h-2 bg-slate-700 mx-4 mt-4 rounded"></div>
                        } else {
                            return <h1 className="text-white text-2xl font-bold p-4">{this.state.users}</h1>
                        }
                    })()}
                </div>
                <div className="bg-gray-800 rounded-md pb-4">
                    <h1 className="text-white text-xl font-bold p-4">Posts</h1>
                    {(() => {
                        if (this.state.loading === true) {
                            return <div className="animate-pulse h-2 bg-slate-700 mx-4 mt-4 rounded"></div>
                        } else {
                            return <h1 className="text-white text-2xl font-bold p-4">{this.state.posts}</h1>
                        }
                    })()}
                </div>
                <div className="bg-gray-800 rounded-md pb-4">
                    <h1 className="text-white text-xl font-bold p-4">Earnings</h1>
                    {(() => {
                        if (this.state.loading === true) {
                            return <div className="animate-pulse h-2 bg-slate-700 mx-4 mt-4 rounded"></div>
                        } else {
                            return <h1 className="text-white text-2xl font-bold p-4">${this.state.earnings}</h1>
                        }
                    })()}
                </div>
                <div className="bg-gray-800 rounded-md pb-4">
                    <h1 className="text-white text-xl font-bold p-4">Fairplay</h1>
                    {(() => {
                        if (this.state.loading === true) {
                            return <div className="animate-pulse h-2 bg-slate-700 mx-4 mt-4 rounded"></div>
                        } else {
                            return <h1 className="text-white text-2xl font-bold p-4">{this.state.fairplay}</h1>
                        }
                    })()}
                </div>
                <div className="bg-gray-800 rounded-md pb-4">
                    <h1 className="text-white text-xl font-bold p-4">Widevine</h1>
                    {(() => {
                        if (this.state.loading === true) {
                            return <div className="animate-pulse h-2 bg-slate-700 mx-4 mt-4 rounded"></div>
                        } else {
                            return <h1 className="text-white text-2xl font-bold p-4">{this.state.widevine}</h1>
                        }
                    })()}
                </div>
            </div>
        </>
    }
}