import Header from '../components/Header';
import Profile from "../components/Profile";
import UpcomingLives from "../components/UpcomingLives";
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import MoonLoader from 'react-spinners/MoonLoader';
import { Toaster, toast } from 'react-hot-toast';
import SettingsSidebar from '../components/SettingsSidebar';

export default function Settings() {
    const [user, setUser] = useState();
    const [uploading, setUploading] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');
    const [newUsername, setNewUsername] = useState('');

    useEffect(() => {
        // Check User Token
        var token = localStorage.getItem('token');
        if (token) {
            // Axios request to backend ...
            axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, {}, { headers: { 'x-auth-token': token } }).then((res) => {
                console.log(res.data);
                setUser(res.data);
                if (res.data.displayName) {
                    setNewDisplayName(res.data.displayName);
                }
                if (res.data.username) {
                    setNewUsername(res.data.username);
                }
            }).catch(err => {
                if (err.response.data.msg === 'Not Verified') {
                    // Redirect to login
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                console.error(err);
            });
        } else {
            // Redirect to login
            window.location.href = '/login';
        }
    }, []);

    function ProfileHeaderUpload(e: any) {
        // console.log('uploading ....')
        var profileUpload = document.getElementById('profileUpload');
        profileUpload?.click();
        profileUpload?.addEventListener('change', (e: any) => {
            console.log(e.target.files[0]);
            var formData = new FormData();
            formData.append('profile', e.target.files[0]);
            // axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, formData, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            //     console.log(res.data);
            // }).catch(err => {
            //     console.error(err);
            // });
        });
    }

    function ProfilePictureUpload(e: any) {
        // console.log('uploading ....')
        var profilePicUpload = document.getElementById('profilePicUpload');
        profilePicUpload?.click();
        profilePicUpload?.addEventListener('change', (e: any) => {
            console.log(e.target.files[0]);
            var formData = new FormData();
            formData.append('profile', e.target.files[0]);
            // axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, formData, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            //     console.log(res.data);
            // }).catch(err => {
            //     console.error(err);
            // });
        });
    }

    function openNameHeader() {
        setProfileModal(true);
    }

    async function closeNameHeader() {
        // Check for change, if no change close instantly. or submit to API...
        if (user) {
            // @ts-ignore
            if (newDisplayName === user.displayName && newUsername === user.username) {
                console.log('both Same')
                setProfileModal(false);
                toast.error('Nothing has been changed', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
                return;
            } else {
                // @ts-ignore
                if (newDisplayName !== user.displayName && newUsername !== user.username) {
                    console.log('both Changed');
                    setUploading(true);
                    await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, { displayName: newDisplayName, username: newUsername }, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
                        console.log(res.data);
                        setUploading(false);
                        setProfileModal(false);
                        toast.success('Changes have been saved!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                    }).catch(err => {
                        toast.error('An error has occurred!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    });

                // @ts-ignore
                } else if (newDisplayName !== user.displayName) {
                    console.log('Display Name Changed');
                    await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, { displayName: newDisplayName }, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
                        console.log(res.data);
                        setUploading(false);
                        setProfileModal(false);
                        toast.success('Changes have been saved!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                    }).catch(err => {
                        toast.error('An error has occurred!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    });
                // @ts-ignore
                } else if (newUsername !== user.username) {
                    console.log(`Username Changed`)
                    await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, { username: newUsername }, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
                        console.log(res.data);
                        setUploading(false);
                        setProfileModal(false);
                        toast.success('Changes have been saved!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                    }).catch(err => {
                        toast.error('An error has occurred!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    });
                }
            }
        }
    }

    function closeDismiss() {
        setProfileModal(false);
    }

    return <div>
        <Toaster position="bottom-center" />
        <Header />
        <div className="flex grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <SettingsSidebar page="Profile" />
            <div className="col-span-2 mx-4 mt-4">
                <h1 className="text-2xl font-bold">Settings</h1>
                <div className="bg-gray-700 px-4 py-1 rounded-md mt-4 pb-8">
                    <h1 className="text-2xl font-bold mt-4">Profile</h1>
                    <div id="profile" className="bg-gray-700 w-full my-4 pb-4 rounded-md">
                        <div className="bg-red-600 h-60 w-full rounded-t-md hover:cursor-pointer" onClick={(e) => ProfileHeaderUpload(e)}></div>
                        <input type="file" className="hidden" name="profileUpload" id="profileUpload" />
                        {/* <img src={chrisBanner} className="w-full h-60 rounded-t-md object-cover" /> */}
                        <div className="flex items-center h-full w-full px-4 py-4">
                            <span className="h-14 w-14 rounded-full bg-red-600 hover:cursor-pointer" onClick={(e) => ProfileHeaderUpload(e)}></span>
                            <input type="file" className="hidden" name="profilePicUpload" id="profilePicUpload" />
                            {/* <img src={ChrisLogo} className="h-14 w-14 rounded-full" alt="Chris Long Films" /> */}
                            <div className="ml-4">
                                {/* @ts-ignore */}
                                {/* <h3 className="text-white text-xl font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> Some Name</h3> */}
                                <h3 onClick={openNameHeader} className="text-white text-xl font-bold hover:cursor-pointer"> {newDisplayName} <i className="fa-regular fa-pencil text-gray-300 px-3 text-sm" title="Edit" /></h3>
                                {/* <p className="text-gray-300 text-xl font-bold">@ <input className="bg-gray-800 px-2 py-1 rounded-md" type="text" defaultValue={`Some Name`} /></p> */}
                                <p onClick={openNameHeader} className="text-gray-300 text-xl hover:cursor-pointer">@{newUsername}</p>
                            </div>
                            <div className="flex-grow"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition appear show={profileModal}>
                <Dialog as="div" className="relative z-10 focus:outline-none" onClose={closeDismiss}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-base/7 font-medium text-white">
                                        Set Display Name / Username
                                    </DialogTitle>
                                    <div className="flex grid grid-rows mt-4">
                                        {(() => {
                                            if (user) {
                                                return <>
                                                    {/* @ts-ignore */}
                                                    <input onChange={(e) => setNewDisplayName(e.target.value)} className="px-2 py-1 rounded-md bg-gray-900 mt-2 text-white" type="text" name="displayName" id="displayName" defaultValue={newDisplayName} placeholder="Display Name ..." />
                                                    {/* @ts-ignore */}
                                                    <input onChange={(e) => setNewUsername(e.target.value)} className="px-2 py-1 rounded-md bg-gray-900 mt-2 text-white" type="text" name="username" id="username" defaultValue={newUsername} placeholder="Username ..." /> 
                                                </>
                                            }
                                        })()}
                                    </div>
                                    {/* <p className="mt-2 text-sm/6 text-white/50">
                                        Your payment has been successfully submitted. We've sent you an email with all of the details of
                                        your order.
                                    </p> */}
                                    <div className="mt-6">
                                        <button
                                            className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                            onClick={closeNameHeader}
                                        >
                                            {(() => {
                                                if (uploading) {
                                                    return <span className="inline-flex"> <MoonLoader color="#ffffff" size={14} className="mt-[2px]" /> <span className="pl-2">Loading</span></span>;
                                                } else {
                                                    return "Save Changes";
                                                }
                                            })()}
                                            {/* Save Changes */}
                                        </button>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <div className="hidden lg:block xl:block 2xl:block">
                <Profile />
                <UpcomingLives />
            </div>
        </div>
        <Footer />
    </div>
}