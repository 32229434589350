import React from 'react';

type props = {
    page: string;
}

type state = {}

export default class SettingsSidebar extends React.PureComponent<props, state> {
    sections = [{
        name: 'User Settings',
        links: [
        {
            name: 'Account',
            isURL: false,
            path: `${process.env.REACT_APP_URL}settings`,
            section: 'User Settings'
        }]
    }, {
        name: 'App Preferences',
        links: [
        // {
        //     name: 'Voice & Audio',
        //     isURL: false,
        //     path: `${process.env.REACT_APP_URL}settings/voice`,
        //     section: 'App Preferences'
        // }, 
        {
            name: 'Notifications',
            isURL: false,
            path: `${process.env.REACT_APP_URL}settings/notifications`,
            section: 'App Preferences'
        }]
    }, {
        name: 'Support',
        links: [{
            name: 'Support',
            isURL: true,
            path: 'https://support.guardedcontent.com/', // url
            section: 'Support'
        }, {
            name: 'Changelog',
            isURL: true,
            path: 'https://guardedcontent.com/changelog', //url
            section: 'Support'
        }]
    }]

    render() {
        return <div className="mx-4 mr-48 mt-4">
            {this.sections.map((section, index) => {
                return <div className="mt-4">
                    <h1 className="text-lg font-bold text-gray-500 uppercase">{section.name}</h1>
                    <ul className="flex grid grid-rows gap-2 mt-2">
                        {section.links.map((link) => {
                            if (this.props.page === link.name) {
                                return <li className="font-bold bg-gray-800 rounded-md px-4 py-2">
                                    {link.isURL ? <a href={link.path} className="block text-gray-200">{link.name}</a> : <a href={link.path} className="block text-gray-200">{link.name}</a>}
                                </li>
                            } else {
                                return <li className="rounded-md px-4 py-2">
                                    {link.isURL ? <a href={link.path} className="block text-gray-200">{link.name}</a> : <a href={link.path} className="block text-gray-200">{link.name}</a>}
                                </li>
                            }
                        })}
                    </ul>
                    {index !== this.sections.length - 1 ? <hr className="mt-2" /> : null}
                </div>
            })}
            {/* <h1 className="text-lg font-bold text-gray-500 uppercase px-4">User Settings</h1>
            <ul className="flex grid grid-rows gap-2 mt-2">
                <li className="font-bold bg-gray-800 rounded-md px-4 py-2">
                    <a href={`${process.env.REACT_APP_URL}settings`} className="block text-gray-200">Profile</a>
                </li>
                <li className="rounded-md px-4 py-2">
                    <a href={`${process.env.REACT_APP_URL}settings/account`} className="block text-gray-200">Account</a>
                </li>
                <li className="rounded-md px-4 py-2">
                    <a href={`${process.env.REACT_APP_URL}settings/security`} className="block text-gray-200">Security & Privacy</a>
                </li>
            </ul>
            <hr className="mt-2" />
            <h1 className="text-lg font-bold text-gray-500 uppercase mt-4 px-4">Billing Settings</h1>
            <ul className="flex grid grid-rows gap-2 mt-2">
                <li className="rounded-md px-4 py-2">
                    <a href="./settings/billing" className="block text-gray-200">Billing</a>
                </li>
            </ul>
            <hr className="mt-2" />
            <h1 className="text-lg font-bold text-gray-500 uppercase mt-4 px-4">App Preferences</h1>
            <ul className="flex grid grid-rows gap-2 mt-2">
                <li className="rounded-md px-4 py-2">
                    <a href="./settings/voice" className="block text-gray-200">Voice & Audio</a>
                </li>
                <li className="rounded-md px-4 py-2">
                    <a href="./settings/notifications" className="block text-gray-200">Notifications</a>
                </li>
            </ul>
            <hr className="mt-2" />
            <ul className="flex grid grid-rows gap-2 mt-2">
                <li className="rounded-md px-4 py-2">
                    <a href="./settings/support" className="block text-gray-200">Support</a>
                </li>
                <li className="rounded-md px-4 py-2">
                    <a href="./settings/changelog" className="block text-gray-200">Changelog</a>
                </li>
            </ul> */}
        </div>
    }
}