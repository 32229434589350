import React from "react";
import ChrisLogo from '../chrislogo.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Comments from './Comments';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
// @ts-ignore
import ShakaPlayerComponent from "./ShakaPlayerComponent";
import ProfilePic from '../ProfilePicTemp.png';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import Cards from 'react-credit-cards-2';
import "react-credit-cards-2/dist/es/styles-compiled.css";
import clerk from '../utils/clerk';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
    isCardExpiryValid,
    isCardCVCValid,
    isCardNumberValid,
} from '../utils/cardUtils';

type state = {
    loading: boolean;
    items: any[];
    pageNum: number;
    hasMore: boolean;
    myName: string;
    purchaseShow: boolean;
    purchaseData: post | null;
    purchaseID: string;
    number: string;
    expiry: string;
    name: string;
    cardFocused: string;
    ccv: string;
    isPaymentActive: boolean;
    commentModal: boolean;
    activePostID: string | null;
    commentData: any;
    comments: any[];
    // followingUser: boolean;
}

type props = {};

type post = {
    access: boolean;
    createdAt: string;
    description: string;
    followers: number;
    isPostLiked: boolean;
    likes: number;
    postID: string;
    price: number;
    profilePic: {
        profilePic: string;
        _id: string;
    };
    status: string;
    title: string;
    username: string;
}

export default class Feed extends React.PureComponent<props, state> {
    player: any = React.createRef();
    private payBTN = React.createRef<HTMLButtonElement>();
    private closeModalBTN = React.createRef<HTMLButtonElement>();
    private commentInput = React.createRef<HTMLInputElement>();

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            pageNum: 0,
            hasMore: true,
            myName: '',
            purchaseShow: false,
            purchaseData: null,
            purchaseID: '',
            number: '4243 1111 1111 1111',
            expiry: '01/26',
            name: 'DemoName',
            cardFocused: '',
            ccv: '211',
            isPaymentActive: false,
            commentModal: false,
            activePostID: null,
            commentData: null,
            comments: [],
            // followingUser: false
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
        // this.toggleFollow = this.toggleFollow.bind(this);
        // this.fetchData = this.fetchData.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.loadStripeComponent = this.loadStripeComponent.bind(this);
        // this.player = this.player.bind(this);
    }

    async componentDidMount() {
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                const script = document.createElement('script');
                script.src = '/jsencrypt.min.js';
                script.async = true;
                document.body.appendChild(script);

                axios.get(`${process.env.REACT_APP_API_URL}api/v1/feed`, { headers: { 'x-auth-token': token } }).then((res) => {
                    // console.log(res.data);
                    this.setState({
                        items: res.data.posts,
                        loading: false,
                        pageNum: 1,
                        myName: res.data.self.displayName
                    });
                }).catch((err: any) => {
                    console.error(err.response.data.msg);
                    if (err.response.data.msg === 'Not Verified' || err.response.data.error === 'Missing Auth Token' || err.response.data.msg === 'Invalid token' || err.response.data.msg === 'Token has been invalidated') {
                        // Logout user and refresh
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    }
                });
            })
        } else {
            window.location.href = '../login';
        }
    }

    fetchMoreData() {

        var pageNum = this.state.pageNum;
        pageNum++;

        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/feed?page=${pageNum}`, { headers: { 'x-auth-token': token } }).then((res) => {
                if (res.data.posts.length !== 0) {
                    this.setState({ items: res.data.posts, pageNum: pageNum });
                } else {
                    this.setState({ hasMore: false });
                }
            }).catch(err => {
                if (err.response.data.msg === 'No posts found') {
                    this.setState({ hasMore: false });
                } else if (err.response.data.msg === 'Not Verified') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else if (err.response.data.error === 'Missing Auth Token') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            })
        })

        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        // console.log('Fetching more data ...');
        // setTimeout(() => {
        //     // this.setState({
        //     //     items: this.state.items.concat(Array.from({ length: 4 }))
        //     // });
        //     var token = localStorage.getItem('token');
        //     axios.get(`${process.env.REACT_APP_API_URL}api/v1/feed`, { headers: { 'x-auth-token': token } }).then((res) => {
        //         // console.log(res.data);
        //         // this.setState({
        //         //     items: res.data.posts
        //         // });
        //         this.setState({
        //             items: this.state.items.concat(res.data.posts)
        //         })
        //     }).catch((err: any) => {
        //         console.error(err);
        //     });
        // }, 1500);
        // return this.state.items;
    }

    toggleLike(e: any, postID: any) {
        // e.target.classList.toggle('text-red-500');
        // e.target.classList.toggle('fa-heart');

        clerk.session?.getToken().then(async (token) => {
            // Make API request 
            // var token = localStorage.getItem('token');
            axios.put(`${process.env.REACT_APP_API_URL}api/v1/post/${postID}/like`, null, { headers: { 'x-auth-token': token } }).then((res) => {
                // Update selected `post` in state to avoid updating all posts ...
                // this.setState({})
                var updatedItems = this.state.items.map((post) => {
                    if (post.postID === postID) {
                        post.likes = res.data.likes;
                        post.isPostLiked = !post.isPostLiked;
                    }
                    return post;
                });
                this.setState({ items: updatedItems });
            }).catch((err) => {
                console.error(err);
                if (err.response.data.msg === 'Not Verified') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else if (err.response.data.error === 'Missing Auth Token') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    // e.target.classList.toggle('text-red-500');
                    // e.target.classList.toggle('fa-heart');
                    // Toast like error to user
                    toast.error('An error has occurred!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }
            });

        })

    }

    responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    buy(postID: string, button: any, price: number, post: post) {
        button.innerHTML = `Purchasing ...`;
        var token = localStorage.getItem('token');

        this.setState({ purchaseShow: true, purchaseData: post, purchaseID: post.postID })

        // axios.post(`${process.env.REACT_APP_API_URL}api/v1/post/${postID}/buy`, null, { headers: { 'x-auth-token': token } }).then((res) => {
        //     toast.success('Post Purchased!', { style: {
        //         color: '#ffffff',
        //         backgroundColor: '#242C37',
        //     }});

        //     setTimeout(() => {
        //         button.innerHTML = `Purchased!`;
        //         window.location.reload();
        //     }, 2000)
        // }).catch(err => {
        //     toast.error(`An error has occurred! ${err.response.data.msg}`, { style: {
        //         color: '#ffffff',
        //         backgroundColor: '#242C37',
        //     }});
        // });
    }  

    inputChange(e: any) {
        // console.log(e);
        if (e.target.name === 'number') {
            this.setState({ number: formatCreditCardNumber(e.target.value) });
            e.target.value = formatCreditCardNumber(e.target.value);
            if (!isCardNumberValid(e.target.value)) {
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'expiry') {
            this.setState({ expiry: formatExpirationDate(e.target.value) });
            e.target.value = formatExpirationDate(e.target.value);
            if (!isCardExpiryValid(e.target.value)) {
                // e.target.style.color = 'red';
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'name') {
            this.setState({ name: e.target.value });
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'cvc') {
            // @ts-ignore
            this.setState({ ccv: formatCVC(e.target.value) });
            // @ts-ignore
            e.target.value = formatCVC(e.target.value);
            if (!isCardCVCValid(e.target.value)) {
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }

            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        }

    }

    closePaymentModal() {
        if (this.state.isPaymentActive === true) {
            return; // Do not close modal if payment is active
        } else {
            var paymentID = this.state.purchaseID;
            var elem = document.getElementById(`buy-${paymentID}`);
            if (elem) {
                // Gotta get payamount.
                if (this.state.purchaseData !== null) {
                    var amount = this.state.purchaseData?.price + (this.state.purchaseData?.price * 0.1);
                    elem.innerHTML = `Pay - $${amount?.toFixed(2)}`;
                }
            }
            // Close modal
            this.setState({ purchaseShow: false, purchaseID: '', cardFocused: '' });
        }
    }

    loadStripeComponent() {
        // @ts-ignore
        return loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }

    checkoutSession(postID: any) {
        // console.log(postID);
        var secret = '';
        const fetchSession = async () => {
            const session = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/stripe/checkoutSession/${postID}`, {}, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                // secret = res.data.session.client_secret;
                return res.data.session;
            }).catch(err => {
                console.log(err);
            });
            // console.log(session);
            return session.client_secret;
        }

        // console.log(fetchSession);
        return { clientSecret: secret, fetchClientSecret: fetchSession}
    }

    closeCommentModal() {
        this.setState({ commentModal: false, activePostID: null, commentData: null });
    }

    openCommentModal(postID: string) {
        // Fetch comments for post ... 
        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/comments/${postID}`, { headers: { 'x-auth-token': token } }).then((res) => {
                console.log(res.data);
                this.setState({ comments: res.data.comments });
            }).catch(err => {
                console.log(err);
            });
        });


        this.setState({ commentModal: true, activePostID: postID });
    }

    submitComment(e: any) {
        e.preventDefault();

        if (this.state.activePostID === null) {
            return;
        } else {
            var comment = this.state.commentData;
            if (comment === null) {
                // Toast Error
                toast.error('Comment cannot be empty!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                // Submit api request ...
                clerk.session?.getToken().then(async (token) => {
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/comments/${this.state.activePostID}`, { comment: comment }, { headers: { 'x-auth-token': token }}).then((res) => {
                        // Handle comment submission response ...
                        if (res.data.msg === 'Comment Added') {
                            if(this.commentInput.current !== null) {
                                this.commentInput.current.value = '';
                            }
                            this.setState(prevState => ({ comments: [res.data.comment, ...(prevState.comments || [])] }));
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                });
            }
        }
    }

    async submitPayment(postID: string) {
        // this.closeModalBTN.current.disabled = true;
        if (this.closeModalBTN.current !== null && this.payBTN.current !== null) {
            this.setState({isPaymentActive: true});
            this.closeModalBTN.current.disabled = true;
            this.payBTN.current.disabled = true;

            // Initialize cardDetails ...
            var cardDetails = {
                number: this.state.number,
                expiry: this.state.expiry,
                cvc: this.state.ccv,
                name: this.state.name,
                postID: this.state.purchaseID
            };

            // Should Encrypt Details ...
            var cert = `MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAyk0twsFAjAbfMjSlXnPSQVvInXzMQRUYdcAOB8Ua5YRMCt2Q9jnn6jCTzJZafF09HTsIM5T0GJBA1gZh7Dy/c6Zi1IJJbhWhFRoDrzNdirY3joO09ib4v0V+0hOdwDL4rAEXBA4Wpi1QoLcr/w1WvYFa/Y/tTJVDAe22/nTKQIsMT6M7e+s2Pw2Q/D5AbpkzBkwT7rl/mb2g2cAtHtZ3tiqLYaP9yLXoLRxNjcilcWEsXkm/2yK01x3Hl5V3JvPFfNGdYuORbD297DWbHrPadSJOLoiTBQwxZkrXMWptyt1wJw1s9d63uKXz5wFzcC8QYLo4A9kPrvdiNGhpnHrQNvUcX9XHBUrFTw8EnwqlxYh6xHGn+mUD4H1kaf2z5bIXgxXmeIcrJWozoHau1WtBVNP4p/YBZm44xq9XwD55p6jdfHcLmtbvAwMACzxavejKeomFlpcdeEKY3ve5gZkNTMAX/HHBlSGbaviLtwdd7CGZp37vzo8Dxup3Z1xQkBjlAgMBAAE=`;
            
            // console.log(cert);

            // @ts-ignore
            const jsEncrypt = new JSEncrypt();
            jsEncrypt.setPublicKey(cert);
            var encrypted = jsEncrypt.encrypt(JSON.stringify(cardDetails));
            console.log('submitting');
            // Submit axios request
            await axios.post(`${process.env.REACT_APP_API_URL}api/v1/payment/demo?postID=${postID}`, { data: encrypted }, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
                if (res.data.msg === 'Payment Successful') {
                    toast.success('Payment Successful!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                    setTimeout(() => {
                        // Reload window
                        window.location.reload();
                    }, 2000);
                    this.setState({isPaymentActive: false, number: '', expiry: '', ccv: '', name: '', purchaseShow: false});
                    if (this.closeModalBTN.current !== null && this.payBTN.current !== null) {
                        this.closeModalBTN.current.disabled = false;
                        this.payBTN.current.disabled = false;
                    }
                    // Close Modal
                    this.closePaymentModal();
                } else {
                    toast.error('An error has occurred!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                    this.setState({isPaymentActive: false, number: '', expiry: '', ccv: '', name: ''});
                    if (this.closeModalBTN.current !== null && this.payBTN.current !== null) {
                        this.closeModalBTN.current.disabled = false;
                        this.payBTN.current.disabled = false;
                    }
                }
                
                // Remove card details from memory ... then reload window ...
                // this.setState({ number: '', expiry: '', ccv: '', name: '' });
                // window.location.reload();
            }).catch(err => {
                this.setState({isPaymentActive: false})
                if (err.response.data.error === 'Card not accepted') {
                    toast.error('Card not accepted!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }
                // console.error(err);
                if (this.closeModalBTN.current !== null && this.payBTN.current !== null) {
                    this.closeModalBTN.current.disabled = false;
                    this.payBTN.current.disabled = false;
                }
            });
        } else {
            console.log('appearing null')
        }
    }

    render() {
        function formatFollowers(followers: number) {
            if (followers > 999 && followers < 1000000) {
                return (followers / 1000).toFixed(1) + 'K Followers';
            } else if (followers > 999999) {
                return (followers / 1000000).toFixed(1) + 'M Followers';
            } else if (followers === 1) {
                return followers + ' Follower';
            } else {
                return followers + ' Followers';
            }
        }

        function formatLikes(likes: number) {
            if (likes > 999 && likes < 1000000) {
                return (likes / 1000).toFixed(1) + 'K Likes';
            } else if (likes > 999999) {
                return (likes / 1000000).toFixed(1) + 'M Likes';
            } else if (likes === 1) {
                return likes + ' Like';
            } else if (likes === 0) {
                return 'Like';
            } else {
                return likes + ' Likes';
            }
        }

        function formatComments(comments: number | undefined) {
            if (comments === undefined) {
                return '0 Comments';
            } else {
                if (comments > 999 && comments < 1000000) {
                    return (comments / 1000).toFixed(1) + 'K Comments';
                } else if (comments > 999999) {
                    return (comments / 1000000).toFixed(1) + 'M Comments';
                } else if (comments === 1) {
                    return comments + ' Comment';
                } else if (comments === 0) {
                    return '0 Comments';
                } else {
                    return comments + ' Comments';
                }
            }
        }

        function commentDateFormat(date: string) {
            // Given date, format to 'mins ago' 'days ago' 'weeks ago' 'years ago'
            var currentDate = new Date();
            var commentDate = new Date(date);
            var diff = currentDate.getTime() - commentDate.getTime();
            var seconds = diff / 1000;
            var minutes = seconds / 60;
            var hours = minutes / 60;

            if (seconds < 60) {
                return 'Just Now';
            } else if (minutes < 60) {
                return Math.floor(minutes) + 'm ago';
            } else if (hours < 24) {
                return Math.floor(hours) + 'h ago';
            } else if (hours < 48) {
                return 'Yesterday';
            } else if (hours < 168) {
                return Math.floor(hours / 24) + 'd ago';
            } else if (hours < 8760) {
                return Math.floor(hours / 168) + 'w ago';
            } else {
                return Math.floor(hours / 8760) + 'y ago';
            }
        }

        // const target = document.querySelectorAll('#post');

        // const observer = new IntersectionObserver((entries) => {
        //     entries.forEach(entry => {
        //         if (entry.isIntersecting) {
        //             // @ts-ignore
        //             const postID = entry.target.getAttribute('x-postID');
        //             // @ts-ignore
        //             const url = new URL(window.location);
        //             // @ts-ignore
        //             url.searchParams.set('postID', postID);
        //             window.history.pushState({}, '', url);
        //         }
        //     })
        // });

        // target.forEach(t => {
        //     console.log('d')
        //     observer.observe(t);
        // })

        return <div className="col-span-2 px-4">
            <Toaster position="bottom-center" />
            {/* <h1 className="text-white text-2xl font-bold mb-3">Your feed</h1> */}
            {/* Live */}
            {/* <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex grid grid-cols-4">
                    <div className="flex items-center h-full px-4 py-4 col-span-3">
                        <i className="fa-solid fa-clock text-gray-100"></i>
                        <span className="px-4 text-gray-100 text-base"> Upcoming live stream with <a href="#" className="text-red-500">Chris Long Films</a></span>
                    </div>
                    <div className="flex justify-end px-4 py-4">
                        <span className="bg-slate-500 w-3 h-3 my-1.5 mx-2 rounded-full"></span>
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative flex h-3 w-3 my-2.5 mx-2">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                        </span>
                        <h1>4:30pm</h1>
                    </div>
                </div>
            </div>
            <hr /> */}

            {/* Posts */}


            {(() => {
                // console.log(this.state.items);
                if (this.state.loading === false && this.state.items === undefined) {
                    // Display different message if user isnt following a creator ...
                    return <div className="bg-gray-700 w-full my-3 px-4 py-4 rounded-md">
                        <h1 className="text-lg font-bold text-center">No posts available! Check back later when a creator makes a post!</h1>
                    </div>
                } else if (this.state.loading === false && this.state.items.length !== 0) {
                    // console.log(this.state.items.length)
                    return <InfiniteScroll
                        dataLength={this.state.items.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={
                            <div>
                                <div className="bg-gray-700 w-full my-3 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                            </div>
                    }
                        scrollableTarget="scrollableDiv"
                    >
                        {(() => {
                            if (this.state.items.length === 0) {
                                return <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                            } else if (Array.isArray(this.state.items)) {
                                return this.state.items.map((post, index) => (
                                    <div id="post" x-postID={post.postID} className="bg-gray-700 w-full my-4 rounded-md py-4 px-4">
                                        <div className="flex items-center h-full px-2">
                                            {(() => {
                                                if (post.profilePic === null || post.profilePic === undefined) {
                                                    // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                                    // return <img src={ProfilePic} className="h-14 w-14 rounded-full" />
                                                    return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                                    // return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${post.username}/profilePic`} className="h-14 w-14 rounded-full" />
                                                } else {
                                                    return <img src={post.profilePic} className="h-14 w-14 rounded-full" />
                                                }
                                            })()}
                                            <div className="ml-4">
                                                <a href={`./user/${post.username}`} className="text-white font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {post.displayName}</a>
                                                <p className="text-gray-300">{formatFollowers(post.followers)}</p>
                                            </div>
                                        </div>
                                        <div className="mt-4 px-2">
                                            <h1 className="text-2xl font-bold pb-4">{post.title}</h1>
                                            {/* <p className="pb-4">Juice & Cordae</p> */}
                                        </div>

                                        <div id="description">
                                            <p className="text-white text-lg px-2 pt-4">{post.description}</p>
                                        </div>
                    
                                        <div id="media" className="mt-4">
                                        {(() => {
                                                // console.log(post);
                                                if (post.access === true) {
                                                    if (post.content) {
                                                        // media exists for post.
                                                        if (post.content.length > 1) {
                                                            // media is a carosel
                                                            // return <div className="flex gap-4">
                                                            //     <Carousel responsive={this.responsive}>
                                                            //         {(() => {
                                                            //             console.log(post.content)
                                                            //             return post.content.map((media: any) => (
                                                            //                 <ShakaPlayerComponent manifestURI={`http://localhost:9002/files/${media.url}`} type={media.type} contentID={media.id} />
                                                            //             ))
                                                            //         })()}
                                                            //     </Carousel>
                                                            // </div>

                                                            // return post.content.map((media: any) => (
                                                            //     <ShakaPlayerComponent manifestURI={`http://localhost:9002/files/${media.url}`} type={media.type} contentID={media.id} />
                                                            // ))
                                                            return <div className="flex justify-center px-2">
                                                                <div className="flex gap-4">
                                                                    {/* <ShakaPlayerComponent name={this.state.myName} ogname={post.content[1].filename} manifestURI={`${process.env.REACT_APP_API_URL}files/${post.content[1].url}`} type={post.content[1].type} contentID={post.content[1].id} contentHeight={post.content[1].contentHeight} contentWidth={post.content[1].contentWidth} /> */}
                                                                    <ShakaPlayerComponent name={this.state.myName} wmanifestURI={post.content[0].widevineURL} fmanifestURI={post.content[0].fairplayURL} type={post.content[0].type} contentID={post.content[0].id} ogname={post.content[0].filename} contentWidth={post.content[0].contentWidth} contentHeight={post.content[0].contentHeight} />
                                                                </div>
                                                            </div>
                                                        } else {
                                                            // media is a single file
                                                            // return <img id="single" src={`http://localhost:9002/files/${post.content[0].url}`} alt="Post Media" className="w-1/2 object-cover" />
                                                            // return <ShakaPlayerComponent manifestURI={`http://localhost:9002/files/${post.content[0].url}`} type={post.content[0].type} contentID={post.content[0].id} />
                                                            return <div className="flex justify-center px-2">
                                                                <div className="flex gap-4">
                                                                    <ShakaPlayerComponent name={this.state.myName} wmanifestURI={post.content[0].widevineURL} fmanifestURI={post.content[0].fairplayURL} type={post.content[0].type} contentID={post.content[0].id} ogname={post.content[0].filename} contentWidth={post.content[0].contentWidth} contentHeight={post.content[0].contentHeight} />
                                                                </div>
                                                            </div>
                                                        }

                                                        // return <ShakaPlayerComponent manifestURI={`http://localhost:9002/files/${post.content[0].url}`} contentID={post.content[0].id} />
                                                    }
                                                } else {
                                                    return <div className="flex">
                                                        {/* Unlock content */}
                                                        <div className="flex grid grid-rows-6 bg-gray-800 w-full h-96 rounded-md flex items-center justify-center">
                                                            <div></div> 
                                                            <div className="text-white text-2xl row-span-4">Please purchase to unlock this post!</div>
                                                            <button onClick={(e) => this.buy(post.postID, e.target, post.price, post)} id={`buy-${post.postID}`} className="flex bg-gray-900 text-white rounded-md px-2 py-1 justify-center hover:shadow-md hover:-translate-y-1 transition ease-in-out">Pay - ${(post.price + (post.price * 0.1)).toFixed(2)} USD</button>
                                                        </div>
                                                    </div>
                                                }
                                            })()}
                                            {/* <div className="flex justify-center px-2">
                                                
                                            </div> */}
                                        </div>
                    
                                        <div id="postControls">
                                            <div className="flex gap-4 px-2 pt-4 text-lg">
                                                <span className="text-white">
                                                    {(() => {
                                                        if (post.access === true) {
                                                            if (post.isPostLiked) {
                                                                return <>
                                                                    <i className="fa-solid fa-heart text-red-500 hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatLikes(post.likes)}</span>
                                                                    
                                                                    <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                    <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                </span>
                                                                </>
                                                            } else {
                                                                return <>
                                                                    <i className="fa-regular fa-heart hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatLikes(post.likes)}</span>
                                                                    
                                                                    <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                    <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                </span>
                                                                </>
                                                            }
                                                        } else {
                                                            return <>
                                                                <i className="fa-regular fa-heart" onClick={(() =>
                                                                    toast.error('Purchase to like the post!', { 
                                                                            style: {
                                                                                color: '#ffffff',
                                                                                backgroundColor: '#242C37',
                                                                            }
                                                                        }                                                    
                                                                    ))}
                                                                ></i>
                                                                <span className="ml-2" onClick={(() => toast.error('Purchase to like the post!', { 
                                                                        style: {
                                                                            color: '#ffffff',
                                                                            backgroundColor: '#242C37',
                                                                        }
                                                                    }                                                    
                                                                ))}>{formatLikes(post.likes)}</span>
                                                                <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                    <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                </span>
                                                            </>
                                                        }
                                                    })()}
                                                    {/* <span className="ml-2">{formatLikes(post.likes)}</span> */}
                                                    {/* <i className="fa-regular fa-heart" onClick={(e) => this.toggleLike(e, post.postID)}></i> <span className="text-gray-300">1.3k likes</span> */}
                                                </span>
                                            </div>
                                        </div>
                    
                                        {/* {(() => {
                                            if (index === 1) {
                                                return <Comments enabled={true} commentData={[]} />
                                            }
                                        })()} */}
                                    </div>
                                ))
                            }
                        })()}
                    </InfiniteScroll>
                } else if (this.state.loading === false && this.state.items.length === 0) {
                    return <div className="bg-gray-700 w-full my-3 px-4 py-4 rounded-md">
                        <h1 className="text-lg font-bold text-center">No posts available! Check back later when a creator makes a post!</h1>
                    </div>
                } else {
                    return <div>
                    <div className="bg-gray-700 w-full my-3 rounded-md">
                        <div className="flex animate-pulse items-center h-full px-6 py-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                        <div className="animate-pulse px-4 py-4">
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                        </div>
                    </div>
                    <div className="bg-gray-700 w-full my-4 rounded-md">
                        <div className="flex animate-pulse items-center h-full px-6 py-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                        <div className="animate-pulse px-4 py-4">
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                        </div>
                    </div>
                    <div className="bg-gray-700 w-full my-4 rounded-md">
                        <div className="flex animate-pulse items-center h-full px-6 py-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                        <div className="animate-pulse px-4 py-4">
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                            <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                        </div>
                    </div>
                </div>
                }
            })()}

            {/* <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <img src={ChrisLogo} className="h-14 w-14 rounded-full ring ring-red-500 animate-pulse" />
                    <div className="ml-4">
                        <h3 className="text-white font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> Chris Long Films</h3>
                        <p className="text-gray-300">3.4k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    <h1 className="text-2xl font-bold pb-4">Juice & Cordae</h1>
                </div>

                <div id="media" className="px-4">
                    <div className="flex justify-center">
                        <img src="https://c10.patreonusercontent.com/4/patreon-media/p/post/96385612/0d2a6069b80748949944deed05ba28f4/eyJxIjoxMDAsIndlYnAiOjB9/1.jpg?token-time=1712707200&token-hash=cIy5l6qPSpNegCTCvjHOWA63tHjjlB8NhZ_gZX55O0Q%3D" alt="Post Media" className="w-1/2 object-cover" />
                        <img src="https://c10.patreonusercontent.com/4/patreon-media/p/post/96385612/8419f78276004ae4abd13019dbf4858d/eyJxIjoxMDAsIndlYnAiOjB9/1.jpg?token-time=1712707200&token-hash=XYRDVzXCW7JUQjf0IE5J41avgwgx_fPHFDm3cpxIO8I%3D" alt="Post Media" className="w-1/2 object-cover" />
                    </div>
                </div>

                <div id="postControls">
                    <div className="flex gap-4 px-5 pt-4 text-lg">
                        <span className="text-white"><i className="fa-regular fa-heart" onClick={(e) => toggleLike(e)}></i> <span className="text-gray-300">1.3k likes</span></span>
                    </div>
                </div>

                <div id="comments" className="pb-2">
                    <div className="flex items-center gap-4 px-4">
                        <input type="text" placeholder="Add a comment" className="w-full bg-gray-800 rounded-md px-4 py-2 my-4" />
                        <button className="bg-red-500 px-4 py-2 rounded-md">Post</button>
                    </div>
                    <div className="flex items-center gap-4 px-4 py-2">
                        <span className="min-h-10 min-w-10 rounded-full bg-red-500"></span>
                        <span className="text-white">John Doe</span>
                        <span className="text-gray-300">This is a comment</span>
                    </div>
                </div>
            </div> */}

            {/* <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <span className="h-10 w-10 rounded-full bg-red-500"></span>
                    <div className="ml-4">
                        <h3 className="text-white font-bold">John Doe</h3>
                        <p className="text-gray-300">1.3k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis nunc sit pulvinar ut tellus sit tincidunt faucibus sapien and now this is demo content to see if it all fits.
                </div>
                <div id="postControls" className="pb-4">
                    <div className="flex gap-4 px-5 pt-4 text-lg">
                        <span className="text-white"><i className="fa-regular fa-heart" onClick={(e) => toggleLike(e)}></i> <span className="text-gray-300">1.3k likes</span></span>
                    </div>
                </div>
            </div>

            <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <span className="h-10 w-10 rounded-full bg-red-500"></span>
                    <div className="ml-4">
                        <h3 className="text-white font-bold">John Doe</h3>
                        <p className="text-gray-300">1.3k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis nunc sit pulvinar ut tellus sit tincidunt faucibus sapien and now this is demo content to see if it all fits.
                </div>
            </div>

            <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <span className="h-10 w-10 rounded-full bg-red-500"></span>
                    <div className="ml-4">
                        <h3 className="text-white font-bold">John Doe</h3>
                        <p className="text-gray-300">1.3k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis nunc sit pulvinar ut tellus sit tincidunt faucibus sapien and now this is demo content to see if it all fits.
                </div>
            </div>

            <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <span className="h-10 w-10 rounded-full bg-red-500"></span>
                    <div className="ml-4">
                        <h3 className="text-white font-bold">John Doe</h3>
                        <p className="text-gray-300">1.3k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis nunc sit pulvinar ut tellus sit tincidunt faucibus sapien and now this is demo content to see if it all fits.
                </div>
            </div>

            <div className="bg-gray-700 w-full my-4 rounded-md">
                <div className="flex items-center h-full px-4 py-4">
                    <span className="h-10 w-10 rounded-full bg-red-500"></span>
                    <div className="ml-4">
                        <h3 className="text-white font-bold">John Doe</h3>
                        <p className="text-gray-300">1.3k followers</p>
                    </div>
                </div>
                <div className="px-4 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis nunc sit pulvinar ut tellus sit tincidunt faucibus sapien and now this is demo content to see if it all fits.
                </div>
            </div> */}
            {/* Purchase Modal */}
            <Transition appear show={this.state.purchaseShow}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closePaymentModal()}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Purchase Post
                                    </DialogTitle>

                                    {(() => {
                                        if (this.state.purchaseData !== null) {
                                            return <div className="mt-6 text-white">
                                                <div>
                                                    <h3 className="font-bold mt-4 mb-4 text-lg">Payment Summary</h3>

                                                    <EmbeddedCheckoutProvider
                                                        stripe={this.loadStripeComponent()}
                                                        options={this.checkoutSession(this.state.purchaseData?.postID)}
                                                        // options={this.checkoutSession(this.state.purchaseData?.postID)}
                                                    > 
                                                        {(() => {
                                                            setTimeout(() => {
                                                                var iframe = document.getElementsByName('embedded-checkout')[0];
                                                                if (iframe) {
                                                                    iframe.style.borderRadius = '0.375rem';
                                                                }
                                                            }, 1000);

                                                            if (this.state.isPaymentActive) {
                                                                return <div className="flex justify-center">
                                                                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
                                                                </div>
                                                            }
                                                        })()}  
                                                        <EmbeddedCheckout />
                                                    </EmbeddedCheckoutProvider>
                                                </div>

                                                <button ref={this.closeModalBTN} onClick={() => this.closePaymentModal()} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button>
                                            </div>
                                        }
                                    })()}
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            
            {/* Comments Modal */}
            <Transition appear show={this.state.commentModal}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeCommentModal()}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Comments
                                    </DialogTitle>
                                    <div className="flex grid grid-rows mt-6 text-white gap-2 overflow-y-auto max-h-96">
                                        {this.state.comments?.map((comment: any, index: any) => {
                                            return (
                                                <div className="flex items-start gap-4">
                                                    {(() => {
                                                        if (comment.profilePic === null || comment.profilePic === undefined) {
                                                            return (
                                                                <span className="min-h-10 min-w-10 rounded-full bg-red-600 inline-block"></span>
                                                            );
                                                        } else {
                                                            return (
                                                                <img src={comment.profilePic} className="h-10 w-10 rounded-full inline-block" />
                                                            );
                                                        }
                                                    })()}
                                                    <div className="w-full">
                                                        <div className="flex justify-between">
                                                            <h3 className="text-white font-bold">{(() => {
                                                                if (comment.isCreator) {
                                                                    return <i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" />
                                                                }
                                                            })()} {comment.displayName}</h3>
                                                            <span className="text-gray-400">{commentDateFormat(comment.createdAt)}</span>
                                                        </div>
                                                        <p className="text-white">{comment.comment}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        {/* Input */}
                                        <div className="mt-6">
                                            <form onSubmit={(e) => this.submitComment(e)}>
                                                <div className="flex grid grid-cols-4 gap-2">
                                                    <input ref={this.commentInput} onChange={(e) => this.setState({commentData: e.target.value})} className="px-2 py-1 col-span-3 rounded-md bg-gray-900 w-full text-white" type="text" placeholder="Comment ..." />
                                                    <button className="px-2 py-1 bg-gray-900 rounded-md text-white">Submit</button> {/* bg-gray-700 */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    }
}