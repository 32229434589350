import React from 'react';

type props = {

}

type state = {
    currentYear: String;
}

export default class Footer extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear().toString()
        }
    }

    async componentDidMount() {

    }

    render() {
        return <div className="fixed bottom-0 left-0 right-0 mt-22">
            {/* <footer className="bg-gray-800 w-full">
                <div className="flex justify-center py-4">
                    <span className="text-white text-sm">© {this.state.currentYear} Some Name. All rights reserved.</span>
                </div>
            </footer> */}
        </div>
    }
}