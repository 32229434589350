import React from 'react';
import axios from 'axios';
import AuthCode from 'react-auth-code-input';

type props = {};

type state = {
    isEnabled: boolean;
    qrimg: string;
    code: string;
    qrcodeGenerated: boolean;
};

export default class TwoStepComponent extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            isEnabled: false,
            qrimg: '',
            code: '',
            qrcodeGenerated: false
        }
        this.valueChanged = this.valueChanged.bind(this);
    }

    async componentDidMount() {
        await axios.get(`${process.env.REACT_APP_API_URL}api/v1/profile/2fa`, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            this.setState({
                isEnabled: res.data.isEnabled
            });
        }).catch(err => {
            console.error(err);
        });
    }

    async activate(e: any) {
        await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/2fa`, null, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            if (res.data.qrCode !== undefined) {
                this.setState({
                    qrimg: res.data.qrCode,
                    qrcodeGenerated: true
                });
                e.disabled = true;
            }
        });
    }

    valueChanged(val: any) {
        this.setState({
            code: val
        });
    }

    async submit() {
        await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/2fa/verify`, { code: this.state.code }, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            console.log(res.data);
        }).catch(err => {
            console.error(err);
        });
    }

    render() {
        return <>
            {(() => {
                if (this.state.isEnabled === false) {
                    return <button onClick={(e) => this.activate(e.target)} className="px-2 py-1 bg-gray-800 disabled:bg-gray-700 rounded-md mt-2">Enable 2FA</button>
                }
            })()}

            {(() => {
                if (this.state.qrimg !== '') {
                    return <img src={this.state.qrimg} alt="QR Code" className="w-1/2 h-1/2 mt-2" />
                }
            })()}

            {(() => {
                if (this.state.isEnabled === false) {
                    return <div className="flex items-center gap-4">
                        <AuthCode onChange={this.valueChanged} allowedCharacters='numeric' inputClassName="bg-gray-800 px-4 py-2 px-2 mr-2 rounded-md w-10 text-white" containerClassName="flex flex-inline mt-2" />
                        <button onClick={() => this.submit()} className="bg-red-600 px-4 py-2 mt-2 -ml-2 rounded-md">Submit</button>
                    </div>
                }
            })()}
        </>
    }
}