import React from 'react';
import CreatePost from './create/CreatePost';
import CreateLive from './create/CreateLive';
import CreateStage from './create/CreateStage';
import CreateGroupBuy from './create/CreateGroupBuy';
import axios from 'axios';
import clerk from '../utils/clerk';

type props = {}

type state = {
    contentType: string;
    loading: boolean;
    hasCompletedSetup: boolean;
}


export default class CreateComponent extends React.Component<props, state> {
    private tabBlock: React.RefObject<HTMLDivElement>;
    constructor(props: any) {
        super(props);
        this.state = {
            contentType: 'post',
            loading: true,
            hasCompletedSetup: false
        }
        this.tabBlock = React.createRef();
        this.switch = this.switch.bind(this);
    }

    componentDidMount(): void {
        // Make an API request to check for relevant permissions ....
        clerk.session?.getToken().then((token) => {
            axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, {type: 'create'}, { headers: { 'x-auth-token': token}}).then((res) => {
                console.log(res.data);
                this.setState({loading: false, hasCompletedSetup: res.data.hasCompletedSetup});
            }).catch (err => {
                console.error(err);
            });
        });
    }

    switch(option: string) {
        var tabs = document.getElementsByClassName('tabs');
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('active-tab');
        }

        if (option === 'post') {
            this.setState({contentType: 'post'})
            this.tabBlock.current?.classList.add('left-4/2');
            this.tabBlock.current?.classList.remove('left-1/2');
            this.tabBlock.current?.classList.remove('left-2/3');
            this.tabBlock.current?.classList.remove('left-3/3');
        } else if (option === 'live') {
            this.setState({contentType: 'live'})
            this.tabBlock.current?.classList.add('left-1/2');
            this.tabBlock.current?.classList.remove('left-2/3');
            this.tabBlock.current?.classList.remove('left-3/3');
            this.tabBlock.current?.classList.remove('left-4/2');
        } else if (option === 'stage') {
            this.setState({contentType: 'stage'})
            this.tabBlock.current?.classList.add('left-2/4');
            this.tabBlock.current?.classList.remove('left-1/4');
            this.tabBlock.current?.classList.remove('left-3/4');
            this.tabBlock.current?.classList.remove('left-4/4');
        } else if (option === 'groupBuy') {
            this.setState({contentType: 'groupBuy'})
            this.tabBlock.current?.classList.add('left-2/3');
            this.tabBlock.current?.classList.remove('left-1/3');
            this.tabBlock.current?.classList.remove('left-3/3');
            this.tabBlock.current?.classList.remove('left-4/3');
        }

        // var content = document.getElementById('content');
        // for (var i = 0; i < content?.children.length; i++) {
        //     content.children[i].classList.add('hidden');
        // }
        // document.getElementById('tab-block').classList.add('left-2');
        // document.getElementById('tab-block').classList.remove('lhalf');
        // document.getElementById(option).classList.add('active-tab');
        // document.getElementById(option + '-content').classList.remove('hidden');
    }

    render() {
        return <div className="flex grid grid-rows-2 pt-8 mb-4">
            {(() => {
                if (this.state.loading === true) {

                } else if (this.state.loading === false && this.state.hasCompletedSetup === false) {
                    // Display Setup Component
                } else if (this.state.loading === false && this.state.hasCompletedSetup === true) {
                    return <>
                        <div className="inline-flex w-full grid grid-cols-2 h-10 z-40 bg-slate-100/5 rounded-md">
                            <input type="hidden" defaultValue={`role`} name="contentType" />
                            <button onClick={() => this.switch('post')} className="font-semibold relative tabs active-tab z-999 hover:bg-slate-200/5 hover:rounded-md hover:transition-all hover:ease-in-out hover:duration-300" type="button">Post</button>
                            <button onClick={() => this.switch('live')} className="font-semibold relative tabs z-999 hover:bg-slate-200/5 hover:rounded-md hover:transition-all hover:ease-in-out hover:duration-300" type="button">Livestream</button>
                            {/* <button onClick={() => this.switch('stage')} className="font-semibold relative tabs z-999 hover:bg-slate-200/5 hover:rounded-md hover:transition-all hover:ease-in-out hover:duration-300" type="button">Stage</button> */}
                            {/* <button onClick={() => this.switch('groupBuy')} className="font-semibold relative tabs z-999 hover:bg-slate-200/5 hover:rounded-md hover:transition-all hover:ease-in-out hover:duration-300" type="button">Group Buy</button> */}
                        </div>
                        <div ref={this.tabBlock} className="relative z-0 w-1/2 left-0 bg-slate-600 rounded-md shadow-md h-10 -mt-10 transition-all ease-in-out duration-300"></div>
                    </>
                }
            })()}
            <div id="content" className="-mt-8">
                {/* Display appropriate content -- Componentized versions ... */}
                {(() => {
                    if (this.state.loading === true) {

                    } else if (this.state.loading === false && this.state.hasCompletedSetup === false) {
                        // Display Setup Component
                        window.location.href = './creator'
                        // return <>
                        //     <h1 className="text-2xl font-bold mt-2">Welcome To GuardedContent!</h1>
                        //     <p className="mt-2">Before you can start posting any media, we first need to setup our payment account, some basic account settings and upload any DRM certificates.</p>
                        // </>
                    } else if (this.state.loading === false && this.state.hasCompletedSetup === true) {
                        if (this.state.contentType === 'post') {
                            return <CreatePost />
                        } else if (this.state.contentType === 'live') {
                            // Have page display chat component instead of profile...
                            return <CreateLive />
                        } else if (this.state.contentType === 'stage') {
                            // return <CreateStage />
                        } else if (this.state.contentType === 'groupBuy') {
                            // return <CreateGroupBuy />
                        }
                    }
                })()}
            </div>
        </div>
    }
}