import React from "react";
import Links from "../../components/admin/Links";
import AdminHeader from "../../components/AdminHeader";
import axios from 'axios';

type state = {
    userID: string;
    user: any;
}

class ManagingComponent extends React.PureComponent<{}, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            userID: '',
            user: []
        }
    }

    async componentDidMount() {
        var userID = window.location.pathname.split('/')[3];
        // Fetch user details.
        await axios.get(`${process.env.REACT_APP_API_URL}api/v1/admin/user/${userID}`, { headers: { "x-auth-token": localStorage.getItem("token") }}).then((res) => {
            // this.setState({userID: res.data.userID});
            console.log(res);
            this.setState({user: res.data.user});
        }).catch(err => {
            if (err.response.data.msg === 'User not found') {
                // User not found
            } else {
                console.log(err);
            }
            // console.log(err);
        });
    }

    verifyUser() {
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/admin/verify/${this.state.user._id}`, {}, { headers: { "x-auth-token": localStorage.getItem("token") }}).then((res) => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return <>
            <h1 className="text-2xl font-bold">Managing User: {this.state.user.username} || "{this.state.user.displayName}"</h1>
            <h2 className="text-lg font-bold mt-2">User Stats</h2>
            <p>Username: {this.state.user.username}</p>
            <p>Display Name: {this.state.user.displayName}</p>
            <p>Email: {this.state.user.email}</p>
            <p>Posts: 0</p>
            <p>Followers: 0</p>
            <p>Following: 0</p>
            <p>Account Created: {this.state.user.createdAt}</p>
            <p>Account Type: {this.state.user.accountType}</p>
            <p>Verification Status: {this.state.user.verified ? 'true' : 'false'}</p>
            <h2 className="text-lg font-bold mt-2">Actions</h2>
            <div className="flex grid grid-cols gap-2">
                <button className="px-2 py-1 bg-gray-900 rounded-md">Suspend</button>
                <button className="px-2 py-1 bg-gray-900 rounded-md">Ban Permanently</button>
                <button onClick={() => this.verifyUser()} className="px-2 py-1 bg-gray-900 rounded-md">Enable Creator -- Verify user.</button>
            </div>
        </>
    }
}

export default function AdminUserPage() {

    return <div>
        <AdminHeader />
        <div className="flex grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <Links page="User Management" />
            <div className="col-span-2 mx-4 mt-4 bg-gray-700 px-4 py-4 rounded-md">
                <ManagingComponent />
                {/* <h1 className="text-2xl font-bold">Managing User: Admin</h1> */}
            </div>
            {/* <Outlet /> */}
            {/* <UserComponent postID={null} /> */}
            {/* <div className="hidden md:block lg:block xl:block 2xl:block">
                <Profile />
                <UpcomingLives />
            </div> */}
        </div>
        {/* <Footer /> */}
    </div>
}