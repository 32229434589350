import React from "react";
import ChrisLogo from '../chrislogo.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import Logo from '../GCLOGO.png';
import ProfilePic from '../ProfilePicTemp.png';
import { Transition, MenuButton, Menu, MenuItems, Dialog, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import { Toaster, toast } from 'react-hot-toast';
import clerk from '../utils/clerk';
import { CourierProvider } from "@trycourier/react-provider";
import { Inbox } from '@trycourier/react-inbox';

type props = {}

type state = {
    nav: boolean;
    notifications: boolean;
    result: boolean; // change
    results: any[];
    avatarDropdown: boolean;
    notificationsDropdown: boolean;
    loading: boolean;
    verified: boolean;
    profilePic: string;
    username: string;
    isCreator: boolean;
    reportModal: boolean;
    title: string | null;
    category: string | null;
    desc: string | null;
    notificationLoading: boolean;
    notToken: string | undefined;
}

export default class Header extends React.PureComponent<props, state> {
    private actionsMenu = React.createRef<HTMLDivElement>();
    private notificationsMenu = React.createRef<HTMLDivElement>();
    private timer: any;
    private reportSubmit = React.createRef<HTMLButtonElement>();

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            nav: false,
            notifications: false,
            result: false,
            avatarDropdown: false,
            notificationsDropdown: false,
            verified: false,
            profilePic: '',
            results: [],
            username: '',
            isCreator: false,
            reportModal: false,
            title: null,
            category: null,
            desc: null,
            notificationLoading: true,
            notToken: undefined
        }
        this.actionsMenu = React.createRef();
        this.notificationsMenu = React.createRef();
        this.logout = this.logout.bind(this);
        this.reportSubmit = React.createRef();
        this.submitReport = this.submitReport.bind(this);
    }

    async componentDidMount() {
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, {}, { headers: { 'x-auth-token': token } }).then((res) => {
                    this.setState({loading: false, verified: res.data.verified, profilePic: res.data.profilePic, username: res.data.username, isCreator: res.data.isCreator});
                }).catch (err => {
                    if (err.response.data.msg === 'Not Verified') { // token not verified
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    } else {
                        console.error(err);
                    }
                });

                // Get NotificationAuth
                await axios.get(`${process.env.REACT_APP_API_URL}api/v1/notificationAuth`, { headers: { 'x-auth-token': token } }).then((res) => {
                    // console.log(res);
                    this.setState({notificationLoading: false, notToken: res.data.token});
                })
            });
        } else {
            clerk.signOut({redirectUrl: '../login'});
        }
    }

    componentWillUnmount(): void {
        // Remove event listeners
        document.removeEventListener('click', this.toggleSearching);
    }

    async navToggle(e: any) {
        // var eventListner: any;
        if (this.state.nav === false) {
            // Open nav
            this.setState({nav: true});
            // eventListner = document.addEventListener('click', {
            //     handleEvent: (e: any) => {
            //         if (e.target !== this.actionsMenu.current) {
            //             console.log('click');
            //             this.actionsMenu.current?.classList.add('hidden');
            //             this.setState({nav: false});
            //             document.removeEventListener('click', eventListner);
            //         }
            //     }
            
            // });
            // Add Listener
            this.actionsMenu.current?.classList.remove('hidden');
        } else {
            // Close nav
            this.setState({nav: false});
            this.actionsMenu.current?.classList.add('hidden');
        }
    }

    async notificationsToggle() {
        // const notifications = document.getElementById('notifications');
        // if (notifications?.classList.contains('hidden')) {
        //     notifications?.classList.remove('hidden');
        // } else {
        //     notifications?.classList.add('hidden');
        // }

        if (this.state.nav === false) {
            // Open nav
            this.setState({nav: true});
            this.notificationsMenu.current?.classList.remove('hidden');
        } else {
            // Close nav
            this.setState({nav: false});
            this.notificationsMenu.current?.classList.add('hidden');
        }
    }

    async searching(query: string, e: any) {
        // Check if query is null
        // console.log(query);
        // console.log(e.keyCode);
        var codes = [18, 16, 17, 20, 93, 45, 36, 33 , 46, 35, 34, 44, 145];
        if (codes.includes(e.keyCode)) {
            return;
        }
        if (e.keyCode === 27) {
            // Close
            document.getElementById('results')?.classList.add('hidden');
            document.getElementById('search')?.classList.remove('rounded-t-md');
            document.getElementById('search')?.classList.add('rounded-md');
            document.getElementById('searchOverlay')?.classList.add('hidden');
            // Input toggle focus
            document.getElementById('search')?.blur();
            this.setState({result: false});
            return;
        }
        if (query === '') {
            // Hide results
            document.getElementById('results')?.classList.add('hidden');
            document.getElementById('search')?.classList.remove('rounded-t-md');
            document.getElementById('search')?.classList.add('rounded-md');
            document.getElementById('searchOverlay')?.classList.add('hidden');
            this.setState({result: false, results: []});
            return;
        } else {
            // Show results
            // console.log(e);
            document.getElementById('results')?.classList.remove('hidden');
            document.getElementById('search')?.classList.remove('rounded-md');
            document.getElementById('search')?.classList.add('rounded-t-md');
            document.getElementById('searchOverlay')?.classList.remove('hidden');

            // Search query -- implement after typing ...
            
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                axios.post(`${process.env.REACT_APP_API_URL}api/v1/search`, {query: query}, { headers: { 'x-auth-token': await clerk.session?.getToken()}}).then((res) => {
                    console.log(res.data);
                    this.setState({results: res.data.users});
                }).catch(err => {
                    console.error(err);
                })
    
                if (query === 'chris') {
                    this.setState({result: true});
                }
            }, 750)
        }
    }

    async toggleSearching() {
        // console.log('click')
        document.getElementById('results')?.classList.add('hidden');
        document.getElementById('search')?.classList.remove('rounded-t-md');
        document.getElementById('search')?.classList.add('rounded-md');
        document.getElementById('searchOverlay')?.classList.add('hidden');
    }

    async openSearchBar() {
        // document.getElementById('results')?.classList.remove('hidden');
        document.getElementById('search')?.classList.remove('rounded-md');
        document.getElementById('search')?.classList.add('rounded-t-md');
        document.getElementById('searchOverlay')?.classList.remove('hidden');
        document.getElementById('results')?.classList.remove('hidden');
    }

    logout() {
        clerk.signOut({redirectUrl: process.env.REACT_APP_URL + 'login'});
        // localStorage.removeItem('token');
        // window.location.href = '/login';
    }

    closeReportModal() {
        // Close the report modal
        this.setState({reportModal: false, title: null, category: null, desc: null});
    }

    openReportModal() {
        // Open the report modal
        this.setState({reportModal: true})
    }

    reportForm(value: string, type: string) {
        if (type === 'title') {
            this.setState({title: value});
            if (this.state.title !== null && this.state.category !== null && this.state.desc !== null) {
                if (this.reportSubmit.current !== null) {
                    this.reportSubmit.current.disabled = false;
                }
            }
        } else if (type === 'category') {
            this.setState({category: value});
            if (this.state.title !== null && this.state.category !== null && this.state.desc !== null) {
                if (this.reportSubmit.current !== null) {
                    this.reportSubmit.current.disabled = false;
                }
            }
        } else if (type === 'desc') {
            this.setState({desc: value});
            if (this.state.title !== null && this.state.category !== null && this.state.desc !== null) {
                if (this.reportSubmit.current !== null) {
                    this.reportSubmit.current.disabled = false;
                }
            }
        }
    }

    submitReport(e: any) {
        e.preventDefault();
        // Verify inputs...
        if (this.state.title === null || this.state.category === null || this.state.desc === null) {
            toast.error('Please fill in all fields');
            return;
        } else {
            console.log('Submitting report ...');
            console.log(this.state.desc)
            console.log(this.state.title, this.state.category, this.state.desc);

            clerk.session?.getToken().then(async (token) => {
                await axios.post(`${process.env.REACT_APP_API_URL}api/v1/report/general`, {title: this.state.title, category: this.state.category, desc: this.state.desc}, { headers: { 'x-auth-token': token }}).then((res) => {
                    console.log(res.data);
                    this.setState({reportModal: false, title: null, category: null, desc: null});
                    toast.success('Report submitted successfully', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }).catch(err => {
                    console.error(err);
                     
                    if (err.response.data === 'Too many requests, please try again later.') {
                        toast.error('Too many requests, try again later!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    } else {
                        toast.error('An error occured while submitting the report', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    }
                });
            });

        }


        // Submit the report
        // console.log(this.state.title, this.state.category, this.state.desc);
        // axios.post(`${process.env.REACT_APP_API_URL}api/v1/report`, {title: this.state.title, category: this.state.category, desc: this.state.desc}, { headers: { 'x-auth-token': localStorage.getItem('token') }}).then((res) => {
        //     console.log(res.data);
        //     this.setState({reportModal: false, title: null, category: null, desc: null});
        // }).catch(err => {
        //     console.error(err);
        // });
    }

    render() {
        var userID = clerk.session?.user.externalId;
        console.log(userID);

        const theme = {
            header: {
                background: '#151A21',
                border: 'none',
                // color: 'white'  
                div: {
                    color: 'white'
                }
            },
            menu: {
                background: '#151A21',
                div: {
                    color: '#fff',
                }
            },
            messageList: {
                container: {
                    background: '#151A21'
                }
            },
            tooltip: {
                div: {
                    color: '#fff',
                    background: '#151A21'
                }
            },
            message: {
                container: {
                    border: 'none',
                    background: '#5D7290',
                    div: {
                        color: 'white'
                    },
                    '&.read': {
                        background: '#151A21',
                    },
                },
                icon: {
                    display: 'none'
                },
                actionElement: {
                    div: {
                        color: 'white'
                    }
                }
            },
            icon: {
                open: '#dc2626'
            },
            emptyState: {
                color: 'white',
                div: {
                    background: '#151A21'
                }
            },
            root: {
                color: '#dc2626'
            }
        }

        return <div>
            <Toaster position="bottom-center" />
            <div className="flex grid grid-cols-5 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 px-4 py-4">
                <div className="hidden lg:block xl:block 2xl:block">
                    {/* <Link to={`${process.env.REACT_APP_URL}`} className="text-3xl font-bold">GuardedContent</Link> */}
                    <Link to={`${process.env.REACT_APP_URL}app/`} className="flex w-52"><img src={Logo} width={'200'} className="py-4" /></Link>
                </div>
                <div className="z-20 col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 w-full grid-cols-1">
                    <div className="input-wrapper">
                        <input onClick={() => this.openSearchBar()} autoComplete="off" onKeyUp={(e: any) => this.searching(e.target.value, e)} id="search" type="text" className="rounded-md p-2 w-full bg-gray-700 px-10 placeholder:text-slate-500" placeholder="Find Someone" />
                        <label htmlFor="search" className="fas fa-magnifying-glass input-icon px-2 -mt-1 text-slate-500"></label>
                    </div>
                    <div className="relative">
                        <div id="results" className="bg-gray-700 absolute rounded-b-md w-full max-w-full hidden shadow-lg">
                            <ul className="px-2 py-1">
                                {this.state.results.map((result: any, index: number) => {
                                    if (result.profilePic === null) {
                                        // TODO:: Fix to use custom img.
                                        if (result.verified) {
                                            return <li key={index} className="py-2 flex">
                                                {/* <span className="min-h-7 min-w-7 rounded-full bg-red-600" /> */}
                                                <img src={ProfilePic} className="h-7 w-7 rounded-full" />
                                                <i className="fa-solid fa-badge-check text-sky-500 ml-2" title="Verified Creator" />
                                                <a onClick={(() => this.toggleSearching())} href={`${process.env.REACT_APP_URL}app/user/${result.username}`}><span className="text-white px-2">{result.displayName}</span></a>
                                            </li>
                                        } else {
                                            return <li key={index} className="py-2 flex">
                                                {/* <span className="min-h-7 min-w-7 rounded-full bg-red-600" /> */}
                                                <img src={ProfilePic} className="h-7 w-7 rounded-full" />
                                                <a onClick={(() => this.toggleSearching())} href={`${process.env.REACT_APP_URL}app/user/${result.username}`}><span className="text-white px-2">{result.displayName}</span></a>
                                            </li>
                                        }
                                    } else {
                                        if (result.verified) {
                                            return <li key={index} className="py-2 flex">
                                                <img src={result.profilePic} className="h-7 w-7 rounded-full" />
                                                <i className="fa-solid fa-badge-check text-sky-500 ml-2" title="Verified Creator" />
                                                <a onClick={(() => this.toggleSearching())} href={`${process.env.REACT_APP_URL}app/user/${result.username}`}><span className="text-white px-2">{result.displayName}</span></a>
                                            </li>
                                        } else {
                                            return <li key={index} className="py-2 flex">
                                                <img src={result.profilePic} className="h-7 w-7 rounded-full" />
                                                <a onClick={(() => this.toggleSearching())} href={`${process.env.REACT_APP_URL}app/user/${result.username}`}><span className="text-white px-2">{result.displayName}</span></a>
                                            </li>
                                        }
                                    }
                                })}
                                {/* {this.state.result ? <li className="py-2 flex">
                                    <img src={ChrisLogo} className="h-7 w-7 rounded-full" />
                                    <span className="text-white px-3">Chris Long Films</span>
                                </li>: <></>}
                                <li className="py-2">
                                    <i className="fas fa-magnifying-glass px-1 text-slate-500"></i>
                                    <span className="px-4 text-slate-500">Result 'Chris Long Films'</span>
                                    <span className="text-red-500 float-right px-2 underline">Delete</span>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-end">
                        <Menu>
                            {/* <MenuButton> */}
                            <div className="px-4 py-2 text-white">
                                {(() => {
                                    if (this.state.notificationLoading === false) {
                                        return <CourierProvider
                                        // @ts-ignore
                                        userId={userID}
                                        authorization={this.state.notToken}
                                        brandId={'NQYY5NR77SM54FQ04MT411J720RK'}
                                    >
                                        <Inbox 
                                            views={[
                                                {id: "notifications", label: "Notifications"},
                                                // {id: "preferences", label: "Preferences"},
                                                {id: 'archived', label: 'Archived'}
                                            ]}
                                            theme={theme}
                                        />
                                    </CourierProvider>
                                    }
                                })()}
                            </div>
                            {(() => {
                                if (this.state.loading === true) {
                                    return <>
                                        {/* <Link to={`${process.env.REACT_APP_URL}create`} className="bg-red-500 text-white font-semibold rounded-md p-2 mr-4">Create</Link> */}
                                        <span className="h-8 min-w-16 mt-1 mr-4 bg-slate-700 animate-pulse rounded-md"></span>
                                        <span className="min-h-10 min-w-10 bg-slate-700 animate-pulse rounded-full hover:cursor-pointer" />
                                    </>
                                } else {
                                    if (this.state.verified === true) {
                                        return <>
                                            <Link to={`${process.env.REACT_APP_URL}app/create`} className="bg-red-600 h-8 mt-1 text-white font-semibold rounded-md px-2 py-1 mr-4 truncate hidden md:block lg:block xl:block 2xl:block">Create</Link>
                                            <MenuButton className="flex">
                                                {(() => {
                                                    if (this.state.profilePic === null) {
                                                        return <img src={ProfilePic} className="h-10 w-10 rounded-full hover:cursor-pointer" onClick={(e: any) => this.navToggle(e)} />
                                                    } else {
                                                        return <img src={this.state.profilePic} className="h-10 w-10 rounded-full hover:cursor-pointer" onClick={(e: any) => this.navToggle(e)} />
                                                    }
                                                })()}
                                            </MenuButton>
                                        </>
                                    } else {
                                        return <>
                                            <Link to={`${process.env.REACT_APP_URL}app/request`} className="bg-red-600 h-8 mt-1 text-white font-semibold rounded-md px-2 py-1 mr-4 truncate hidden md:block lg:block xl:block 2xl:block">Become a creator</Link>
                                            <MenuButton className="flex">
                                                {(() => {
                                                    if (this.state.profilePic === null) {
                                                        return <img src={ProfilePic} className="h-10 w-10 rounded-full hover:cursor-pointer" onClick={(e: any) => this.navToggle(e)} />
                                                        // return <span className="min-h-10 min-w-10 rounded-full hover:cursor-pointer bg-red-600" onClick={(e: any) => this.navToggle(e)} />
                                                    } else {
                                                        return <img src={this.state.profilePic} className="h-10 w-10 rounded-full hover:cursor-pointer" onClick={(e: any) => this.navToggle(e)} />
                                                        // return <img src={ChrisLogo} className="h-10 w-10 rounded-full hover:cursor-pointer" onClick={(e: any) => this.navToggle(e)} />
                                                    }
                                                })()}
                                            </MenuButton>
                                        </>
                                    }
                                }
                            })()}
                            {/* </MenuButton> */}
                            <div className="shadow-lg absolute mt-12 -mr-2 w-1/8">
                                <Transition
                                    enter="transition ease-out duration-75"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                    >
                                    <MenuItems>
                                        <div className="flex grid mr-2">
                                            {/* Gotta check for Creators and show relevant options here ... */}
                                            {(() => {
                                                if (this.state.verified === true) {
                                                    return <a href={`${process.env.REACT_APP_URL}app/user/${this.state.username}`} className="bg-gray-700 px-2 py-2 rounded-t-md font-semibold"><i className="fa-solid fa-user text-gray-200 pr-2"></i> Profile</a>
                                                } else {
                                                    return <a href={`${process.env.REACT_APP_URL}app/profile`} className="bg-gray-700 px-2 py-2 rounded-t-md font-semibold"><i className="fa-solid fa-user text-gray-200 pr-2"></i> Profile</a>
                                                }
                                            })()}
                                            {/* <a href="./profile" className="bg-gray-700 px-2 py-2 rounded-t-md font-semibold"><i className="fa-solid fa-user text-gray-200 pr-2"></i> Profile</a> */}
                                            <Link to={`${process.env.REACT_APP_URL}app/transactions`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-dollar-sign text-gray-200 pl-1 pr-2"></i> <span>Transactions</span></Link>
                                            {/* <Link to={`${process.env.REACT_APP_URL}manage/creator`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-user-tie text-gray-200 pr-2"></i> Creator Account</Link> */}
                                            {(() => {
                                                if (this.state.verified === true) {
                                                    return <Link to={`${process.env.REACT_APP_URL}app/creator`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-film text-gray-200 pr-2"></i> Creator Dashboard</Link>
                                                }
                                            })()}
                                            {/* {(() => {
                                                if (this.state.verified === true) {
                                                    return <Link to={`${process.env.REACT_APP_URL}app/dmca`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-gavel text-gray-200 pr-2"></i> DMCA Reports</Link>
                                                }
                                            })()} */}
                                            <Link to={`${process.env.REACT_APP_URL}app/settings`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-gear text-gray-200 pr-2"></i> Settings</Link>
                                            {/* <Link to={`${process.env.REACT_APP_URL}help`} className="bg-gray-700 px-2 py-2 font-semibold"><i className="fa-solid fa-circle-question text-gray-200 pr-2"></i> Help</Link> */}
                                            <span onClick={() => this.openReportModal()} className="bg-gray-700 px-2 py-2 font-semibold hover:cursor-pointer"><i className="fa-solid fa-flag text-gray-200 pr-2"></i> Report</span>
                                            <button onClick={() => this.logout()} className="text-center py-2 bg-gray-600 rounded-b-md font-semibold">Logout</button>
                                        </div>
                                    </MenuItems>
                                </Transition>
                            </div>
                        </Menu>
                    </div>
                </div>
            </div>
            <div id="searchOverlay" className="hidden z-10 absolute opacity-75 bg-gray-800 w-full h-full flex" style={{marginTop: '-5.7rem'}} onClick={() => this.toggleSearching()}></div>
            
            <Transition appear show={this.state.reportModal} >
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeReportModal()} >
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Report an issue
                                    </DialogTitle>
                                    <div className="mt-4">
                                        <form className="grid grid-cols-1 gap-4" onSubmit={(e) => this.submitReport(e)}>
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-white font-bold text-md">Title</label>
                                                <input onChange={(e) => this.reportForm(e.target.value, 'title')} required className="rounded-md p-2 bg-gray-900 text-white shadow-md" placeholder="Title ..." />
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-white font-bold text-md">Issue Category</label>
                                                <select onChange={(e) => this.reportForm(e.target.value, 'category')} required className="rounded-md p-2 bg-gray-900 text-white shadow-md">
                                                    <option value=""></option>
                                                    <option value="bug">Bug</option>
                                                    <option value="copyright">Copyright Infringement</option>
                                                    <option value="tos">Breach TOS</option>
                                                    <option value="support">Support</option>
                                                    <option value="content">Inappropriate Content</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-white font-bold text-md">Description</label>
                                                <textarea onChange={(e) => this.reportForm(e.target.value, 'desc')} required className="rounded-md p-2 bg-gray-900 text-white shadow-md" rows={4} placeholder="Issue Description, where it happened? (post title/page name), previous steps leading up till the issue ..."></textarea>
                                            </div>
                                            <div className="flex grid grid-cols-2 gap-4">
                                                <span onClick={() => this.closeReportModal()} className="bg-gray-900 text-white rounded-md p-2 mt-2 text-center hover:cursor-pointer shadow-md">Cancel</span>
                                                <button ref={this.reportSubmit} disabled type="submit" className="bg-red-600 disabled:bg-red-400 text-white rounded-md p-2 mt-2 shadow-md">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    }
}