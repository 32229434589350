import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ProfilePic from "../ProfilePicTemp.png";
import clerk from '../utils/clerk';

type state = {
    loading: boolean;
    subscriptions: any[];
    isAdmin: boolean;
}

type props = {};

export default class Subscriptions extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            subscriptions: [],
            isAdmin: false
        }
    }

    async componentDidMount() {
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/subscriptions`, { headers: { 'x-auth-token': token } }).then((res) => {
                    this.setState({
                        subscriptions: res.data.subscriptions,
                        loading: false,
                        isAdmin: res.data.isAdmin
                    });
                })
            });
        }
    }

    render() {
        return <div className="px-4 py-4 hidden lg:block xl:block 2xl:block">
            <h1 className="text-md font-bold text-slate-400 uppercase">Links</h1>
            <div className="flex mt-2">
                <ul className="w-5/6">
                    {(() => {
                        // Get URL params
                        var path = window.location.pathname;
                        console.log(window.location);
                        if (path !== '/app/explore') {
                            return <li onClick={() => window.location.href = `${process.env.REACT_APP_URL}app/`} className="flex mt-2 text-white hover:text-white bg-gray-800 px-2 py-1 rounded-md w-3/6 hover:cursor-pointer"><a href={`${process.env.REACT_APP_URL}app/`}>Home</a></li>
                        } else {
                            return <li onClick={() => window.location.href = `${process.env.REACT_APP_URL}app/`} className="mt-2 text-slate-400 hover:text-white px-2 py-1 rounded-md w-full hover:cursor-pointer"><a href={`${process.env.REACT_APP_URL}app/`}>Home</a></li>
                        }
                    })()}
                    {/* <a href={`${process.env.REACT_APP_URL}app/`}>Home</a> */}
                    {/* <a href={`${process.env.REACT_APP_URL}app/explore`} className="flex mt-2 text-slate-400 hover:text-white px-2 py-1 rounded-md w-3/6 hover:cursor-pointer">Explore</a> */}
                    {(() => {
                        // Get URL params
                        var path = window.location.pathname;
                        console.log(window.location);
                        if (path === '/app/explore') {
                            return <li onClick={() => window.location.href = `${process.env.REACT_APP_URL}app/explore`} className="mt-2 text-white hover:text-white bg-gray-800 px-2 py-1 rounded-md w-3/6 hover:cursor-pointer"><a href={`${process.env.REACT_APP_URL}app/explore`}>Explore</a></li>
                        } else {
                            return <li onClick={() => window.location.href = `${process.env.REACT_APP_URL}app/explore`} className="mt-2 text-slate-400 hover:text-white px-2 py-1 rounded-md w-3/6 hover:cursor-pointer"><a href={`${process.env.REACT_APP_URL}app/explore`}>Explore</a></li>
                        }
                    })()}
                    {/* <li className="mt-2 text-slate-400 hover:text-white px-2 py-1 rounded-md w-full hover:cursor-pointer">Music <span className="bg-gray-800 ml-4 px-2 py-1 rounded-md">Soon</span></li> */}
                    {/* <li className="mt-2 text-slate-400 hover:text-white px-2 py-1 rounded-md w-full hover:cursor-pointer">Documents <span className="bg-gray-800 ml-4 px-2 py-1 rounded-md">Soon</span></li> */}
                </ul>
            </div>
            <h1 className="text-md font-bold text-slate-400 uppercase mt-4">Subscriptions</h1>
            {(() => {
                if (this.state.loading === true) {
                    return <div className="mb-4">
                        <div className="animate-pulse flex items-center mt-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/2 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/4 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                        <div className="animate-pulse flex items-center mt-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/2 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/4 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                        <div className="animate-pulse flex items-center mt-4">
                            <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                            <div className="ml-4 w-full">
                                <div className="h-2 w-1/2 bg-slate-700 rounded"></div>
                                <div className="h-2 w-1/4 mt-2 bg-slate-700 rounded"></div>
                            </div>
                        </div>
                    </div>
                } else {
                    return <div className="mb-4">
                        {(() => {
                            if (this.state.subscriptions.length === 0) {
                                return <div className="mt-4">
                                    <h3 className="text-slate-300 font-bold">No subscriptions found</h3>
                                </div>
                            } else {
                                return this.state.subscriptions.map((sub) => {
                                    if (sub.profilePic === null) {
                                        return <div className="flex items-center mt-4">
                                            <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}>
                                                <img src={ProfilePic} className="h-10 w-10 rounded-full" />
                                            </a>
                                            <div className="ml-4">
                                                <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}><h3 className="text-slate-300 font-bold">{sub.displayName}</h3></a>
                                                <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}><p className="text-slate-400">@{sub.username}</p></a>
                                            </div>
                                        </div>
                                    } else {
                                        return <div className="flex items-center mt-4">
                                            <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}>
                                                <img src={sub.profilePic} className="h-10 w-10 rounded-full"></img>
                                            </a>
                                            <div className="ml-4">
                                                <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}><h3 className="text-slate-300 font-bold">{sub.displayName}</h3></a>
                                                <a href={`${process.env.REACT_APP_URL}app/user/${sub.username}`}><p className="text-slate-400">@{sub.username}</p></a>
                                            </div>
                                        </div>
                                    }
                                })
                            }
                        })()}
                    </div>
                }
            })()}
            <span className="text-slate-400">&copy; <a href="https://guardedcontent.com/" className="hover:text-white">GuardedContent</a>, 2024</span>
            
            <div className="flex grid grid-rows-2 mt-4">
                <a href="https://guardedcontent.com/terms" className="flex w-32 text-slate-400 hover:text-white mt-1">Terms of Service</a>
                <a href="https://guardedcontent.com/privacy" className="flex w-32 text-slate-400 hover:text-white mt-1">Privacy Policy</a>
                <a href="https://guardedcontent.com/refunds" className="flex w-32 text-slate-400 hover:text-white mt-1">Refund Policy</a>
            </div>
            <div className="flex grid grid-cols-12 mt-4">
                <a href="https://www.instagram.com/guardedcontent?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" className="text-slate-400 text-xl hover:text-white"><i className="fa-brands fa-instagram"></i></a>
                <a href="https://twitter.com/GuardedContent" target="_blank" className="text-slate-400 text-xl hover:text-white"><i className="fa-brands fa-twitter"></i></a>
                <a href="https://www.tiktok.com/@GuardedContent" target="_blank" className="text-slate-400 text-xl hover:text-white"><i className="fa-brands fa-tiktok"></i></a>
                <a href="https://discord.gg/XBpKCNjBXs" target="_blank" className="text-slate-400 text-xl hover:text-white"><i className="fa-brands fa-discord"></i></a>
            </div>
            <div className="mt-4 -ml-1 text-slate-400">
                <iframe src="https://status.guardedcontent.com/badge?theme=dark" width="250" height="30" frameBorder="0" scrolling="no" style={{color: '#94a3b8'}}></iframe>
            </div>

            {(() => {
                if (this.state.isAdmin === true) {
                    return <div className="mt-4 bg-gray-800 w-1/2 rounded-md text-center px-2 py-1">
                        <a href={`${process.env.REACT_APP_URL}app/admin`}>Admin Area</a>
                    </div>
                }
            })()}
        </div>
    }
}