import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import axios from 'axios';
import { ClerkProvider, SignedIn, SignedOut, SignOutButton } from '@clerk/clerk-react';
import { Clerk } from '@clerk/clerk-js';

// Pages
import Feed from './components/Feed';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Create from './pages/Create';
import Signup from './pages/Signup';
import Settings from './pages/Settings';
import Search from './pages/Search';
import PageComponent from './pages/PageComponent';
import User from './pages/User';
import Request from './pages/Request';
import ErrorPage from './pages/ErrorPage';
import MessagesPage from './pages/MessagesPage';
import UserPage from './pages/UserPage';
import SettingsAccount from './pages/settings/Account';
import SettingsSecurity from './pages/settings/Security';
import LandingPage from './pages/LandingPage';
import TransactionsPage from './pages/Transactions';
import LivePage from './pages/LivePage';
import AdminIndex from './pages/admin/AdminIndex';
import AdminUserPage from './pages/admin/AdminUserPage';
import Sandbox from './components/Sandbox';
import CreatorReq from './pages/admin/CreatorReq';
import UserManagement from './pages/admin/UserManagement';
import UserReports from './pages/admin/UserReports';
import DMCA from './pages/admin/DMCA';
import DMCACreator from './pages/DMCA';
// import Transactions from './pages/admin/Transactions';
import IOSNavbar from './components/IOSNavbar';
import CreatorPage from './pages/Creator';
import ExplorePage from './pages/Explore';
import { CourierProvider } from "@trycourier/react-provider";
// import { Logtail } from "@logtail/browser";
// const logtail = new Logtail("mgGWiuxjsNuyKtNhh9CtXaYm");

// var console=(function(oldCons){
//   return {
//       log: function(text: any){
//           oldCons.log(text);
//           // Your code
//           logtail.log(text)
//       },
//       info: function (text: any) {
//           oldCons.info(text);
//           // Your code
//           logtail.info(text)
//       },
//       warn: function (text: any) {
//           oldCons.warn(text);
//           // Your code
//           logtail.warn(text)
//       },
//       error: function (text: any) {
//           oldCons.error(text);
//           // Your code
//           logtail.error(text)
//       }
//   };
// }(window.console));

// //Then redefine the old console
// // @ts-ignore
// window.console = console;

// https://www.figma.com/file/CS01VVLR7ArQl0afYFkNj3/Web-App?type=design&node-id=0-1&mode=design&t=sUKVnPNFZRgiHyP8-0


// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   }, function (err) {
//     const originalRequest = err.config;

//     console.log(originalRequest);
    
//     if (err.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}api/v1/auth/refresh`) {
//       // Clear auth
//       // localStorage.clear();
//       return Promise.reject(err);
//     }

//     if (err.response.status === 401 && !originalRequest._retry) {
//       console.log('Refreshing token...');
//       originalRequest._retry = true;
//       const refresh = localStorage.getItem('refresh');
//       return axios.post(`${process.env.REACT_APP_API_URL}api/v1/auth/refresh`, { refresh }).then(res => {
//         if (res.status === 201) {
//           localStorage.setItem('token', res.data.token);
//           localStorage.setItem('refresh', res.data.refresh);
//           axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
//           return axios(originalRequest);
//         }
//       });
//     }
//   return Promise.reject(err);
// });

const CLERKPUB = process.env.REACT_APP_CLERK;

if (!CLERKPUB) {
  throw new Error('CLERK API KEY NOT FOUND!');
}

export default function App() {
  return <div className="bg-body relative min-h-screen max-h-full w-full text-white">
    {/* <Sandbox /> */}
    <ClerkProvider publishableKey={CLERKPUB || ''} afterSignOutUrl='/landing'>
      <SignedIn>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/app/" element={<PageComponent />} >
              <Route index element={<Feed />} />
              <Route path="/app/search" element={<Search />} />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/create" element={<Create />} />
              <Route path="/app/user/:username" element={<User />} />
              <Route path="/app/request" element={<Request />} />
              <Route path="/app/messages" element={<MessagesPage />} />
              <Route path="/app/transactions" element={<TransactionsPage />} />
              <Route path="/app/creator" element={<CreatorPage />} />
              <Route path="/app/explore" element={<ExplorePage />} />
              {/* <Route path="/app/dmca" element={<DMCACreator />} /> */}
            </Route>

            <Route path="/app/admin" element={<AdminIndex />} />
            <Route path="/app/admin/user/:username" element={<AdminUserPage />} />
            {/* <Route path="/app/admin/dmca" element={<DMCA />} /> */}
            <Route path="/app/admin/requests" element={<CreatorReq />} />
            <Route path="/app/admin/reports" element={<UserReports />} />
            <Route path="/app/admin/users" element={<UserManagement />} />
            {/* <Route path="/app/admin/transactions" element={<Transactions />} /> */}
            <Route path="/app/user/:username/live" element={<LivePage />} />
            <Route path="/app/settings" element={<SettingsAccount />} />
            <Route path="/app/settings/security" element={<SettingsSecurity />} />
            <Route path="/app/settings/billing" element={<Settings />} />
            <Route path="/app/settings/audio" element={<Settings />} />

            <Route path="/signout" element={<SignOutButton redirectUrl='./' />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/support" element={<Settings />} />
            <Route path="/changelog" element={<Settings />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </SignedIn>
      <SignedOut>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            {/* <Route path="/app/" element={<PageComponent />} >
              <Route index element={<Feed />} />
              <Route path="/app/search" element={<Search />} />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/create" element={<Create />} />
              <Route path="/app/user/:username" element={<User />} />
              <Route path="/app/request" element={<Request />} />
              <Route path="/app/messages" element={<MessagesPage />} />
              <Route path="/app/transactions" element={<TransactionsPage />} />
              <Route path="/app/creator" element={<CreatorPage />} />
              <Route path="/app/dmca" element={<DMCA />} />
            </Route> */}

            {/* <Route path="/app/admin" element={<AdminIndex />} />
            <Route path="/app/admin/user/:username" element={<AdminUserPage />} />
            <Route path="/app/admin/dmca" element={<DMCA />} />
            <Route path="/app/admin/requests" element={<CreatorReq />} />
            <Route path="/app/admin/reports" element={<UserReports />} />
            <Route path="/app/admin/users" element={<UserManagement />} /> */}
            {/* <Route path="/app/admin/transactions" element={<Transactions />} /> */}
            {/* <Route path="/app/user/:username/live" element={<LivePage />} />
            <Route path="/app/settings" element={<SettingsAccount />} />
            <Route path="/app/settings/security" element={<SettingsSecurity />} />
            <Route path="/app/settings/billing" element={<Settings />} />
            <Route path="/app/settings/audio" element={<Settings />} /> */}

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/support" element={<Settings />} />
            <Route path="/changelog" element={<Settings />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </SignedOut>
    </ClerkProvider>
    {/* <IOSNavbar /> */}
  </div>
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <App />
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
