import React from 'react';
import Dropzone from 'react-dropzone';
// import CurrencyInput from 'react-currency-input-field';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import clerk from '../../utils/clerk';

type props = {};
type state = {
    files: any[];
    acceptedFiles: any[];
    rejectedFiles: any[];
    value: number;
    submitted: boolean;
    title: string;
    description: string;
    disableComments: boolean;
    scheduleRelease: string;
};

export default class CreatePost extends React.Component<props, state> { 
    title: any;
    description: any;
    price: any;
    fileUpload: any;
    submitBTN: any;

    constructor(props: any) {
        super(props);
        this.state = {
            files: [],
            acceptedFiles: [],
            rejectedFiles: [],
            submitted: false,
            value: 0.00,
            title: '',
            description: '',
            disableComments: false,
            scheduleRelease: ''
        }
        this.onDrop = this.onDrop.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.title = React.createRef();
        this.description = React.createRef();
        this.price = React.createRef();
        this.fileUpload = React.createRef();
        this.submitBTN = React.createRef();
    }

    onDrop = async (files: any[]) => {
        this.setState({files})
    };

    costPrice = (value: any) => {
        if (value === '' || value === 0 || value === 0.0 || value === 0.00) {
            this.setState({value: 0.00});
        } else {
            this.setState({value: value.slice(1).replace(/,/g, '')});
        }
    }

    changeComments(e: any) {
        console.log(e.target.checked);
        this.setState({disableComments: e.target.checked});
    }

    submitForm(e: any) {
        e.preventDefault();

        if (this.state.title !== '' && this.state.description !== '') {
            this.title.current.disabled = true;
            this.description.current.disabled = true;
            this.price.current.disabled = true;
            this.fileUpload.current.disabled = true;
            this.submitBTN.current.classList.toggle('hidden');

            if (document.getElementById('price-input')) {
                var price = document.getElementById('price-input') as HTMLInputElement;
                price.disabled = true;
            }

            // console.log("Form Submitted");
            const formData = new FormData();
            this.state.files.forEach((file: any) => {
                formData.append('file', file);
            });
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);
            formData.append('price', this.state.value.toString());
            // this.state.files.forEach((file: any) => {
            //     // Update each status to uploading:
            //     this.setState((prevState) => {
            //         const updatedFiles = prevState.files.map((file) => {
            //             return { ...file, status: "Uploading" };
            //         });
            //         return { files: updatedFiles };
            //     });
            // });

            this.setState((prevState) => ({
                files: prevState.files.map(file => ({
                    ...file,
                    status: 'Uploading',
                    progress: 0,
                    estimatedTime: 'Calculating...',
                }))
            }));

            clerk.session?.getToken().then(async (token) => {
                const realToken = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/genUploadToken`, null, { headers: { 'x-auth-token': token }});

                await toast.promise(
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/create/post`, formData, {
                        // @ts-ignore
                        headers: {'Content-Type': 'multipart/form-data', 'x-auth-token': realToken.data.tempTok.jwt},
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            if (total) {
                                const progress = Math.round((loaded * 100) / total);
                                this.setState((prevState) => {
                                    const updatedFiles = prevState.files.map((file) => {
                                        // @ts-ignore
                                        if (file.name === progressEvent.filename) {
                                            // @ts-ignore
                                            const timeElapsed = (new Date() - file.uploadStartTime) / 1000; // Time in seconds
                                            const uploadSpeed = loaded / timeElapsed; // Bytes per second
                                            const estimatedTime = Math.round((total - loaded) / uploadSpeed); // Estimated time in seconds
                                            return {
                                                ...file,
                                                progress,
                                                estimatedTime: this.formatTime(estimatedTime),
                                            };
                                        }
                                        return file;
                                    });
                                    return { files: updatedFiles };
                                })
                            }
                        }
                    }).then((res) => {
                        console.log(res.data);
                        this.setState({acceptedFiles: res.data.files, rejectedFiles: res.data.rejected, submitted: true});
                        if (res.data.valid === true) {
                            var interval = setInterval(() => {
                                clerk.session?.getToken().then(async (nTok) => {
                                    axios.get(`${process.env.REACT_APP_API_URL}api/v1/create/post/${res.data.postID}`, {headers: {'x-auth-token': nTok}}).then((res) => {
                                        console.log(res.data);
                                        this.setState({ acceptedFiles: res.data.post.content });
                                        if (res.data.complete === true) {
                                            clearInterval(interval);
                                            window.onbeforeunload = null;
                                            window.location.href = `/app/user/${res.data.username}`;
                                        }
                                    }).catch(err => {
                                        console.log(err);
                                        clearInterval(interval);
                                        window.onbeforeunload = null;
                                    });
                                });
                            }, 5000);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.state.files.forEach((file: any) => {
                            // Update each status to uploading:
                            this.setState((prevState) => {
                                const updatedFiles = prevState.files.map((file) => {
                                    return { ...file, status: "Failed" };
                                });
                                return { files: updatedFiles };
                            });
                        });
                    }), {
                        loading: 'Uploading ...',
                        success: 'Post uploaded successfully!',
                        error: 'Failed to upload post!'
                    }, {
                        style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }
                    }
                )
            });
        } else {
            toast.error(`Cannot create an empty post!`, {
                style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }
            })
        }
    }

    formatTime(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    }

    render() {

        const currencyMask = createNumberMask({
            prefix: '$',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 7, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        });

        function formatFileSize(bytes: any, decimalPoint: any = 2) {
            if(bytes === 0) return '0 Bytes';
            var k = 1000,
                dm = decimalPoint || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        var types = {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'video/mp4': ['.mp4', '.mov', '.avi', '.mkv'],
            'video/quicktime': ['.mov'],
            'video/webm': ['.webm'],
        }

        return <div className="col-span-2">
            <Toaster position="bottom-center" />
            <form onSubmit={(e) => this.submitForm(e)}>
                <h1 className="text-xl font-bold pt-4">Title</h1>
                <input onChange={(e) => this.setState({title: e.target.value})} ref={this.title} type="text" placeholder="Title" className="w-full bg-gray-800 disabled:bg-gray-700 rounded-md px-4 py-2 my-4" />
                <h1 className="text-xl font-bold pt-2">Description</h1>
                <textarea onChange={(e) => this.setState({description: e.target.value})} ref={this.description} placeholder="Description" className="w-full bg-gray-800 disabled:bg-gray-700 rounded-md px-4 py-2 my-4"></textarea>
                <h1 className="text-xl font-bold">Price</h1>
                <MaskedInput id="price-input" ref={this.price} mask={currencyMask} onChange={(e) => this.costPrice(e.target.value)} className="bg-gray-800 disabled:bg-gray-700 mt-4 my-2 px-2 py-2 rounded-md text-right w-full" placeholder={`$0.00`} />
                <div id="fees" className="flex grid grid-rows float-right">
                    <span className="float-right">Platform Fees: <span className="text-gray-200 float-right px-4">-{(() => {
                        if (this.state.value === 0) {
                            return "";
                        } else {
                            var fees = this.state.value * 0.15;
                            var rounded = Math.round(fees * 100) / 100;
                            return `$${rounded}`;
                        }
                    })()}</span></span>
                    <span className="float-right">Total Earnings: <span className="text-gray-200 float-right px-4 font-bold">${(() => {
                        if (this.state.value === 0) {
                            return "0.00";
                        } else {
                            var total = this.state.value - (this.state.value * 0.15);
                            var rounded = Math.round(total * 100) / 100;
                            return rounded; // need to format this number somehow ...
                        }
                    })()}</span></span>
                </div>

                <h1 className="text-xl font-bold mt-10">Attach Media</h1>
                <Dropzone onDrop={this.onDrop} ref={this.fileUpload} accept={types}>
                    {({getRootProps, getInputProps}) => (
                        <section className="flex flex-col justify-center items-center mt-4">
                            <div {...getRootProps({className: 'dropzone'})} className="flex bg-gray-800 disabled:bg-gray-700 w-full text-center justify-center h-20 rounded-md items-center">
                                <input {...getInputProps()} />
                                <p className="m-0">Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                
                {/* <aside className="mt-4">
                    <h4 className="text-2xl font-bold">Files</h4>
                    <table className="table-fixed w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
                        <thead className="text-xs text-white uppercase bg-gray-900">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    File Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Size
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if (this.state.submitted === true) {
                                    return this.state.acceptedFiles.map((file: any) => (
                                        <tr key={file.path} className="bg-gray-800 border-b">
                                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate">{file.filename}</th>
                                            <th className="px-6 py-4">{formatFileSize(file.size)}</th>
                                            <th className="px-6 py-4">{file.status}</th>
                                        </tr>
                                    ))
                                } else {
                                    return this.state.files.map((file: any) => {
                                        console.log(file)
                                        return <tr key={file.path} className="bg-gray-800 border-b">
                                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate">{file.name}</th>
                                            <th className="px-6 py-4">{formatFileSize(file.size)}</th>
                                            <th className="px-6 py-4">Idle</th>
                                        </tr>
                                    })
                                }
                            })()}
                            {(() => {
                                if (this.state.submitted === true) {
                                    if (this.state.rejectedFiles.length > 0) {
                                        return <h1 className="text-2xl text-white font-bold mt-4 mb-2">Failed Files</h1>
                                    }
                                }
                            })()}
                            {(() => {
                                if (this.state.submitted === true) {
                                    if (this.state.rejectedFiles.length > 0) {
                                        return this.state.rejectedFiles.map((file: any) => (
                                            <tr key={file.path} className="bg-gray-800 border-b">
                                                <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{file}</th>
                                                <th className="px-6 py-4"></th>
                                                <th className="px-6 py-4">Failed - File type not allowed</th>
                                            </tr>
                                        ))
                                    }
                                }
                            })()}
                        </tbody>
                    </table>
                </aside> */}

                <aside className="mt-4">
                    <h4 className="text-2xl font-bold">Files</h4>
                    <table className="table-fixed w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
                        <thead className="text-xs text-white uppercase bg-gray-900">
                            <tr>
                                <th scope="col" className="px-6 py-3">File Name</th>
                                <th scope="col" className="px-6 py-3">Size</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3">Progress</th>
                                <th scope="col" className="px-6 py-3">Time Remaining</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.files.map((file: any) => (
                                <tr key={file.name} className="bg-gray-800 border-b">
                                    <th className="px-6 py-4">{file.name}</th>
                                    <th className="px-6 py-4">{formatFileSize(file.size)}</th>
                                    <th className="px-6 py-4">{file.status}</th>
                                    <th className="px-6 py-4">
                                        {/* Custom progress bar */}
                                        <div className="w-full bg-gray-300 rounded-full h-2">
                                            <div
                                                className={`h-2 rounded-full bg-red-600`}
                                                style={{ width: `${file.progress}%` }}
                                            ></div>
                                        </div>
                                        {file.progress}%
                                    </th>
                                    <th className="px-6 py-4">{file.estimatedTime}</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </aside>

                <button ref={this.submitBTN} type="submit" className="w-full bg-red-600 text-white rounded-md mt-8 h-8">Upload Post</button>
            </form>
        </div>
    }
}