import React from 'react';
import clerk from '../utils/clerk';
import { Logtail } from "@logtail/browser";
const logtail = new Logtail("mgGWiuxjsNuyKtNhh9CtXaYm");

type props = {
    wmanifestURI: string;
    fmanifestURI: string;
    contentID: string;
    type: string;
    contentWidth: number;
    contentHeight: number;
    ogname: string;
    name: string | null;
    prevAccessed?: boolean;
}

type state = {
    player: any;
    manifestURI: string;
    username: string;
    cWidth: number;
    cHeight: number;
    previouslyAccessed: boolean;
    watched: boolean;
}

export default class ShakaPlayerComponent extends React.PureComponent<props, state> {
    private player: any;
    private canvas: any;
    private videoDiv: any;

    constructor(props: any) {
        super(props);
        this.state = {
            player: null,
            manifestURI: '',
            username: 'demoUser ...',
            cWidth: 300,
            cHeight: 150,
            previouslyAccessed: false,
            watched: false
        }
        this.loadVideo = this.loadVideo.bind(this);
        this.canvas = React.createRef();
        this.player = React.createRef();
        this.videoDiv = React.createRef();
    }

    componentDidMount(): void {
        // Get props if not null
        // console.log(this.props.manifestURI, this.props.contentID, this.props.type);
        if (this.player && ((this.props.wmanifestURI !== null || this.props.fmanifestURI !== null) || (this.props.wmanifestURI !== undefined || this.props.fmanifestURI !== undefined) || (this.props.wmanifestURI !== '' || this.props.fmanifestURI !== '')) && (this.props.contentID !== null || this.props.contentID !== undefined || this.props.contentID !== '')) {
            this.loadVideo(this.player, this.props.wmanifestURI, this.props.fmanifestURI, this.props.contentID);
            if (this.props.prevAccessed) {
                this.setState({previouslyAccessed: true});
            }
        }
    }

    async loadVideo(player: any, widevine: string, fairplay: string, contentID: string) {
        // console.log(player);
        // console.log(manifestUri);

        // @ts-ignore
        shaka.polyfill.installAll();

        // @ts-ignore
        if (shaka.Player.isBrowserSupported()) {
            if (process.env.REACT_APP_MODE === 'dev') {
                if (window.navigator.appVersion.indexOf('Win') !== -1) {
                    // console.log('windows');
                    // WIDEVINE ...
                    const url = widevine;
                    // @ts-ignore
                    var playerInstance = new shaka.Player();
                    await playerInstance.attach(player.current);

                    console.log({os: 'windows', widevine: widevine, fairplay: fairplay, contentID: contentID});

                    playerInstance.configure({
                        drm: {
                            servers: {
                                'com.widevine.alpha': `${process.env.REACT_APP_API_URL}api/v1/widevine?contentID=${contentID}`
                            },
                            // advanced: {
                            //     'com.widevine.alpha': {
                            //         'videoRobustness': 'SW_SECURE_CRYPTO',
                            //         'audioRobustness': 'SW_SECURE_CRYPTO'
                            //     }
                            // }
                        },
                        // streaming: {
                        //     requestFilter: function(request: any) {
                        //         clerk.session?.getToken().then((token) => {
                        //             request.headers['x-auth-token'] = token;
                        //         });
                        //     }
                        // }
                        requestFilter: (request: any) => {
                            // Await the token fetching before modifying request
                            const token = clerk.session?.getToken();
                            if (token) {
                                request.headers['x-auth-token'] = token;
                            }
                            // return request; // Ensure the modified request is returned
                        }
                    });

                    // @ts-ignore
                    const ui = new shaka.ui.Overlay(playerInstance, this.videoDiv.current, this.player.current);
                    const controls = ui.getControls();

                    ui.configure({
                        'overflowMenuButtons' : ['quality', 'loop'],
                        // 'controlPanelElements': ['loop']
                    })

                    playerInstance.getNetworkingEngine().registerRequestFilter(function (type: any, request: any) {
                        clerk.session?.getToken().then((token) => {
                            request.headers['x-auth-token'] = token;
                        });
                    })

                    player.current.addEventListener('playing', () => {
                        // console.log('Now Playing Content');
                        if (this.state.watched === false) {
                            if (!this.props.prevAccessed) {
                            //     // Assume user has accessed content ...
                                console.log('Not previously accessed ...');
                            }
                            this.setState({watched: true});
                        }
                    });

                    playerInstance.addEventListener('error', (event: any) => {
                        // Extract the shaka.util.Error object from the event.
                        console.error('Error code', event.detail.code, 'object', event.detail);
                    });

                    playerInstance.addEventListener('loaded', () => {
                        const variantTracks = playerInstance.getVariantTracks();
                        var resolutions = variantTracks.map((track: any) => track.height ? `${track.height}p` : 'Unknown Resolution');

                        // Assuming you have a UI element to display the resolutions, like a dropdown or a list
                        console.log('Available resolutions:', resolutions);

                        
                        // Example: Add the resolutions to a dropdown menu
                        // const resolutionSelect = document.getElementById('resolution-select'); // Assuming this is a select element
                        // resolutions.forEach(res => {
                        //     const option = document.createElement('option');
                        //     option.value = res;
                        //     option.textContent = res;
                        //     resolutionSelect.appendChild(option);
                        // });
                    })

                    try {

                        playerInstance.load(url).then(() => {
                            // setTimeout(() => {
                            //     this.setState({cWidth: this.player.current.getBoundingClientRect().width - (16*2), cHeight: this.player.current.getBoundingClientRect().height - (16*4)});
                            //     // console.log(`Updating res... ${this.player.current.getBoundingClientRect().width} ${this.player.current.getBoundingClientRect().height}`);
    
                            //     setTimeout(() => {
                            //         var canvas = this.canvas.current;
                            //         if (canvas) {
                            //             // console.log(canvas)
            
                            //             // @ts-ignore
                            //             var ctx = canvas.getContext('2d');
            
                            //             var width = this.state.cWidth;
                            //             var height = this.state.cHeight;
            
                            //             // console.log(width, height);
            
                            //             var initialHeight = Math.floor(Math.random() * (height - 0) + 0);
                            //             var initialWidth = Math.floor(Math.random() * (width - 0) + 0);
            
                            //             ctx.fillStyle = '#fff';
                            //             ctx.fillText(`${this.props.name}`, initialWidth, initialHeight);
            
                            //             setInterval(() => {
                            //                 ctx.clearRect(0, 0, canvas.width, canvas.height);
                            //                 var firstRandom = Math.floor(Math.random() * (height - 0) + 0);
                            //                 var second = Math.floor(Math.random() * (width - 0) + 0);
                            //                 ctx.fillText(`${this.props.name}`, second, firstRandom);
                            //             }, 2000);
            
                            //             // ctx.fillText('192.168.1.37', 120, 120);
                            //         }
                            //     }, 200)
                            // }, 200);
                        });
                    } catch (error) {
                        console.error('Error code ', error);
                    }

                } else {
                    // @ts-ignore
                    shaka.polyfill.PatchedMediaKeysApple?.install()

                    // @ts-ignore
                    // shaka.log.setLevel(shaka.log.Level.V2);

                    // Fairplay ...
                    const url = fairplay;
                    // @ts-ignore
                    var playerInstance = new shaka.Player();
                    await playerInstance.attach(player.current);
                    var cert = await fetch(`${process.env.REACT_APP_API_URL}api/v1/fairplay/certificate`);
                    const C = await cert.arrayBuffer();

                    playerInstance.configure({
                        drm: {
                            servers: {
                                'com.apple.fps.1_0': `${process.env.REACT_APP_API_URL}api/v1/fairplay?contentID=${contentID}`
                            },
                            advanced: {
                                'com.apple.fps.1_0': {
                                    serverCertificate: new Uint8Array(C)
                                }
                            },
                        },
                    });

                    // @ts-ignore
                    const ui = new shaka.ui.Overlay(playerInstance, this.videoDiv.current, this.player.current);
                    const controls = ui.getControls();

                    ui.configure({
                        'overflowMenuButtons' : ['quality', 'loop'],
                        // 'controlPanelElements': ['loop']
                    })

                    // clerk.session?.getToken().then((token) => {
                    playerInstance.getNetworkingEngine().registerRequestFilter(function (type: any, request: any) {
                        clerk.session?.getToken().then((token) => {
                            request.headers['x-auth-token'] = token;
                            // @ts-ignore
                            if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
                                return;
                            }

                            const originalPayload = new Uint8Array(request.body);
                            // @ts-ignore
                            const base64Payload = shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
                            const params = 'spc=' + base64Payload;
                            request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                            // @ts-ignore
                            request.body = shaka.util.StringUtils.toUTF8(encodeURIComponent(params));
                            request.uris = [`${process.env.REACT_APP_API_URL}api/v1/fairplay?contentID=${contentID}&token=${token}&spc=${encodeURIComponent(base64Payload)}`];
                        });
                    });

                    playerInstance.getNetworkingEngine().registerResponseFilter(function (type: any, response: any) {
                        // clerk.session?.getToken().then((token) => {
                        // @ts-ignore
                        if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            // @ts-ignore
                            const responseText = shaka.util.StringUtils.fromUTF8(response.data).trim();
                            var newArr = JSON.parse(responseText);
                            // @ts-ignore
                            response.data = shaka.util.Uint8ArrayUtils.fromBase64(newArr.data).buffer;
                        }
                        // });
                    });
                    // });

                    player.current.addEventListener('playing', () => {
                        // console.log('Now Playing Content');
                        if (this.state.watched === false) {
                            if (!this.props.prevAccessed) {
                            //     // Assume user has accessed content ...
                                console.log('Not previously accessed ...');
                            }
                            this.setState({watched: true});
                        }
                    });

                    playerInstance.addEventListener('error', (event: any) => {
                        // Extract the shaka.util.Error object from the event.
                        console.error('Error code', event.detail.code, 'object', event.detail);
                        logtail.info(`Player`, {error: event});
                    });

                    try {
                        playerInstance.load(url).then(() => {
                        });
                    } catch (error) {
                        console.error('Error code ', error);
                        logtail.info(`Player`, {error: error});
                    }
                }



                // console.log('dev mode active...');
                // var url = this.props.manifestURI;
                // var newurl = url.replace('\\output\\master.mpd', ''); // dev
                // var newurl = url.replace('/output/master.mpd', '');
                // console.log({url, newurl});
                // console.log(url);
                // var extension = this.props.ogname.split('.').pop();
                // var newfile = `${newurl}.${extension}`;
                // @ts-ignore
                // var playerInstance = new shaka.Player();
                // await playerInstance.attach(player.current);

                // player.current.addEventListener('playing', () => {
                //     // console.log('Now Playing Content');
                //     if (this.state.watched === false) {
                //         if (!this.props.prevAccessed) {
                //         //     // Assume user has accessed content ...
                //             console.log('Not previously accessed ...');
                //         }
                //         this.setState({watched: true});
                //     }
                // });

                // playerInstance.addEventListener('error', (event: any) => {
                //     // Extract the shaka.util.Error object from the event.
                //     console.error('Error code', event.detail.code, 'object', event.detail);
                // });
            } else {
                // @ts-ignore
                // var playerInstance = new shaka.Player();
                // await playerInstance.attach(player.current);

                // playerInstance.configure({
                //     drm: {
                //         servers: {
                //             'com.widevine.alpha': `${process.env.REACT_APP_API_URL}api/v1/widevine?contentID=${contentID}`
                //         },
                //         // advanced: {
                //         //     'com.widevine.alpha': {
                //         //         'videoRobustness': 'SW_SECURE_CRYPTO',
                //         //         'audioRobustness': 'SW_SECURE_CRYPTO'
                //         //     }
                //         // }
                //     }
                // });

                // playerInstance.getNetworkingEngine().registerRequestFilter(function (type: any, request: any) {
                //     // Only add headers to license requests:
                //     // @ts-ignore
                //     if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                //         // This is the specific header name and value the server wants:
                //         request.headers['x-auth-token'] = localStorage.getItem('token');
                //     }
                // })

                // playerInstance.addEventListener('error', (event: any) => {
                //     // Extract the shaka.util.Error object from the event.
                //     console.error('Error code', event.detail.code, 'object', event.detail);
                // });
                // try {

                //     playerInstance.load(manifestUri).then(() => {
                //         console.log('The video has now been loaded!');

                //         // this.player.current.addEventListener('resize', () => {
                //         //     console.log('Resizing ...');
                //         //     this.reSize();
                //         // });
                //         // Gotta add canvas support ...
                //         // setTimeout(() => {
                //         //     this.setState({cWidth: this.player.current.getBoundingClientRect().width - (16*2), cHeight: this.player.current.getBoundingClientRect().height - (16*4)});
                //         //     console.log(`Updating res... ${this.player.current.getBoundingClientRect().width} ${this.player.current.getBoundingClientRect().height}`);

                //         //     setTimeout(() => {
                //         //         var canvas = this.canvas.current;
                //         //         if (canvas) {
                //         //             // console.log(canvas)
        
                //         //             // @ts-ignore
                //         //             var ctx = canvas.getContext('2d');
        
                //         //             var width = this.state.cWidth;
                //         //             var height = this.state.cHeight;
        
                //         //             console.log(width, height);
        
                //         //             var initialHeight = Math.floor(Math.random() * (height - 0) + 0);
                //         //             var initialWidth = Math.floor(Math.random() * (width - 0) + 0);
        
                //         //             ctx.fillStyle = '#fff';
                //         //             ctx.fillText(`${this.state.username}`, initialWidth, initialHeight);
        
                //         //             setInterval(() => {
                //         //                 ctx.clearRect(0, 0, canvas.width, canvas.height);
                //         //                 var firstRandom = Math.floor(Math.random() * (height - 0) + 0);
                //         //                 var second = Math.floor(Math.random() * (width - 0) + 0);
                //         //                 ctx.fillText(`${this.state.username}`, second, firstRandom);
                //         //             }, 2000);
        
                //         //             // ctx.fillText('192.168.1.37', 120, 120);
                //         //         }
                //         //     }, 200)
                //         // }, 200);
                //     }).catch((error: any) => {
                //         console.error('Error code', error.code, 'object', error);
                //     })
                // } catch (error) {
                //     console.error('Error code ', error);
                // }
            }
        }
    }

    // async reSize() {
    //     this.setState({cWidth: this.player.current.getBoundingClientRect().width - (16*2), cHeight: this.player.current.getBoundingClientRect().height - (16*4)});
    //     console.log(`Updating res... ${this.player.current.getBoundingClientRect().width} ${this.player.current.getBoundingClientRect().height}`);

    //     setTimeout(() => {
    //         var canvas = this.canvas.current;
    //         if (canvas) {
    //             // console.log(canvas)

    //             // @ts-ignore
    //             var ctx = canvas.getContext('2d');
    
    //             var width = this.state.cWidth;
    //             var height = this.state.cHeight;

    //             console.log(width, height);

    //             var initialHeight = Math.floor(Math.random() * (height - 0) + 0);
    //             var initialWidth = Math.floor(Math.random() * (width - 0) + 0);

    //             ctx.fillStyle = '#fff';
    //             ctx.fillText(`${this.state.username}`, initialWidth, initialHeight);

    //             setInterval(() => {
    //                 ctx.clearRect(0, 0, canvas.width, canvas.height);
    //                 var firstRandom = Math.floor(Math.random() * (height - 0) + 0);
    //                 var second = Math.floor(Math.random() * (width - 0) + 0);
    //                 ctx.fillText(`${this.state.username}`, second, firstRandom);
    //             }, 2000);

    //             // ctx.fillText('')
    //         }
    //     }, 200);
    // }

    render() {
        // console.log(this.props.type)
        // if (this.props.type === 'image') {
        //     return <video id="image" ref={this.player} controls={false} className="w-full h-full"></video>
        // } else {
        //     return <video id="video" ref={this.player} controls className="w-full h-full"></video>
        // }
        return <div className="flex relative">
            <div ref={this.videoDiv}>
                <video id="video" ref={this.player} className="w-full" />
            </div>
            <canvas ref={this.canvas} width={this.state.cWidth} height={this.state.cHeight} id="ctest" style={{pointerEvents: 'none', position: 'absolute', zIndex: 9}} className="ml-2" />
        </div>
    }
}