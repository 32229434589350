import React from 'react';
import axios from 'axios';
import '@mux/mux-active-viewer-count';
import toast, { Toaster } from 'react-hot-toast';
import clerk from '../../utils/clerk';

type props = {};
type state = {
    isLive: boolean;
    viewers: number;
    viewToken: string;
    isHidden: boolean;
    streamTitle: string;
};

type cprop = {
    pollInterval: number;
};
type cstate = {
    viewToken: string;
    data: any;
    loading: boolean;
    status: string;
};

export class ViewCounter extends React.Component<cprop, cstate> {
    private interval: any;

    constructor(props: any) {
        super(props);
        this.state = {
            viewToken: '',
            data: [],
            loading: true,
            status: ''
        }
    }

    async componentDidMount() {
        clerk.session?.getToken().then(async (token) => {
            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/livestream/my`, { headers: { 'x-auth-token': token } }).then((res) => {
                // console.log(res.data);
                if (res.data.token !== null) {
                    this.setState({viewToken: res.data.token});
                    // console.log({v: this.state.viewToken, t: res.data.token});

                    // send initial request
                    this.getData(res.data.token);

                    // Set interval to get data
                    this.interval = setInterval(() => {
                        this.getData(res.data.token);
                    }, this.props.pollInterval * 1000);
                }
            }).catch(err => {
                console.log(err);
            });
        });
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    async getData(signedTok: string) {
        // Get data from mux
        clerk.session?.getToken().then(async (token) => {
            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/livestream/my/viewers?token=${signedTok}`, { headers: { 'x-auth-token': token } }).then((res) => {
                // console.log(res.data);
                this.setState({data: res.data.viewers[0], loading: false, status: res.data.status});
            }).catch(err => {
                console.log(err);
            });
        });
    }

    render() {
        return <div className="flex grid grid-cols-2 gap-4">
            <div className="flex justify-center bg-gray-800 mb-4 rounded-md h-20">
                {/* {(() => {
                    if (this.state.isLive) {
                        return <span className="text-white text-xl font-bold h-20 pt-6">Status: Live</span>
                    } else {
                        return <span className="text-white text-xl font-bold h-20 pt-6">Status: Offline</span>
                    }
                })()} */}
                {(() => {
                    if (this.state.loading === false) {
                        if (this.state.status === '' || this.state.status === 'idle') {
                            return <span className="text-white text-xl font-bold h-20 pt-6">Status: Offline</span>
                        } else {
                            return <span className="text-white text-xl font-bold h-20 pt-6">Status: {this.state.status}</span>
                        }
                    } else {
                        return <span className="animate-pulse w-48 h-2 bg-gray-600 rounded-md mt-8"></span>
                    }
                })()}
            </div>
            <div className="flex justify-center bg-gray-800 mb-4 rounded-md h-20">
                {/* <span className="text-white text-xl font-bold h-20 pt-6"> */}
                    {(() => {
                        if (this.state.loading === false) {
                            if (this.state.data !== undefined) {
                                return <span className="text-white text-xl font-bold h-20 pt-6">{this.state.data.viewers} Viewers</span>
                            } else {
                                return <span className="text-white text-xl font-bold h-20 pt-6">0 Viewers</span>
                            }
                        } else {
                            return <span className="animate-pulse w-48 h-2 bg-gray-600 rounded-md mt-8"></span>
                        }
                    })()}
                {/* </span> */}
            </div>
        </div>
    }
}

export default class CreateLive extends React.Component<props, state> {
    private streamKey: any = React.createRef();
    private streamTitleInput: any = React.createRef();
    // isHidden: boolean = true;

    constructor(props: any) {
        super(props);
        this.state = {
            isLive: false,
            viewers: 0,
            viewToken: '',
            isHidden: true,
            streamTitle: ''
        }
        this.streamKey = React.createRef();
        this.streamTitleInput = React.createRef();
    }

    async componentDidMount() {
        // Check if user is live
        await axios.get(`${process.env.REACT_APP_API_URL}api/v1/mystream`, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
            this.setState({streamTitle: res.data.title});
        }).catch(err => {
            console.log(err);
        });
    }

    openChat() {
        // Open chat in new window
        window.open('http://localhost:3000/app/chat', 'newwindow', 'width=400,height=750');
    }

    copyURL() {
        // Copy URL to clipboard
        navigator.clipboard.writeText('rtmp://live.guardedcontent.com:5222/app');
        // toast.success('URL copied to clipboard');
        toast.success('Copied stream url to clipboard!', { style: {
            color: '#ffffff',
            backgroundColor: '#242C37',
        }});
    }

    async updateTitle(e: any) {
        e.preventDefault();
        // Update stream title
        clerk.session?.getToken().then(async (token) => {
            toast.promise(axios.post(`${process.env.REACT_APP_API_URL}api/v1/mystream`, {title: this.streamTitleInput.current.value}, { headers: { 'x-auth-token': token } }).then((res) => {
                this.setState({streamTitle: res.data.title});
                return res.data;
            }).catch(err => {
                console.log(err);
            }), {
                loading: 'Updating stream title...',
                success: 'Stream title updated!',
                error: 'An error has occurred!',
            }, { 
                style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }
            })
        });
    }

    async revealKey() {
        if (this.state.isHidden === true) {
            // this.state.isHidden = false;
            this.setState({isHidden: false});

            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/livestream/my/streamkey`, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                // console.log(res.data);
                this.streamKey.current.value = res.data.streamKey;
                toast.success('Stream key revealed!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }).catch(err => {
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            })


            // this.streamKey.current.value = '********-****-****-****-***********3';
            // toast.success('Stream key revealed!');
            // toast.success('Stream key revealed!', { style: {
            //     color: '#ffffff',
            //     backgroundColor: '#242C37',
            // }});
        } else {
            // this.state.isHidden = true;
            this.setState({isHidden: true});
            this.streamKey.current.value = '********-****-****-****-************';
            // toast.success('Stream key hidden!');
            toast.success('Stream key hidden!', { style: {
                color: '#ffffff',
                backgroundColor: '#242C37',
            }});
        }
        // this.isHidden = !this.isHidden;
        // this.streamKey.current.value = '********-****-****-****-***********3';
        // // toast.success('Stream key revealed!');
        // toast.success('Stream key revealed!', { style: {
        //     color: '#ffffff',
        //     backgroundColor: '#242C37',
        // }});
    }

    async revealURL() {
        if (this.state.isHidden === true) {
            // this.setState({isHidden: false});

            // await axios.get(`${process.env.REACT_APP_API_URL}api/v1/livestream/my/streamkey`, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
            //     this.streamKey.current.value = res.data.streamKey;
            //     toast.success('Stream key revealed!', { style: {
            //         color: '#ffffff',
            //         backgroundColor: '#242C37',
            //     }});
            // }).catch(err => {
            //     toast.error('An error has occurred!', { style: {
            //         color: '#ffffff',
            //         backgroundColor: '#242C37',
            //     }});
            // })
        } else {
            // this.setState({isHidden: true});
            // this.streamKey.current.value = '********-****-****-****-************';
            // toast.success('Stream key hidden!', { style: {
            //     color: '#ffffff',
            //     backgroundColor: '#242C37',
            // }});
        }
    }

    async resetStreamKey() {
        // Reset stream key

        // Send request to reset stream key -- then update streamKey field.
        await axios.post(`${process.env.REACT_APP_API_URL}api/v1/livestream/my/resetKey`, {}, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            if (res.data.msg === 'done') {
                this.setState({isHidden: true});
                this.streamKey.current.value = '********-****-****-****-************';
                toast.success('Stream key reset!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        }).catch(err => {
            console.log(err);
            toast.error('An error has occurred!', { style: {
                color: '#ffffff',
                backgroundColor: '#242C37',
            }});
        });
    }

    copyKey() {
        // Copy stream key to clipboard
        if (this.state.isHidden === false) {
            navigator.clipboard.writeText(this.streamKey.current.value);
            toast.success('Copied stream key to clipboard!', { style: {
                color: '#ffffff',
                backgroundColor: '#242C37',
            }});
        }
    }
    
    render() {
        return <div className="col-span-2 mt-2">
            <Toaster position="bottom-center" />
            {/* <h1 className="text-2xl font-bold">Go Live</h1> */}

            <ViewCounter pollInterval={20} />

            <div className="bg-gray-800 px-4 py-1 rounded-md pb-8 mb-4">
                <h1 className="text-xl font-bold pt-2">Stream Title</h1>
                <form onSubmit={(e) => this.updateTitle(e)}>
                    <input ref={this.streamTitleInput} type="text" placeholder="Stream Title" className="w-full bg-gray-700 rounded-md px-4 py-2 my-4" defaultValue={this.state.streamTitle} />
                    <button className="px-2 py-1 bg-gray-700 rounded-md w-full">Save Changes</button>
                </form>
            </div>

            <div className="bg-gray-800 px-4 py-1 rounded-md pb-8">
                {/* Display Streaming info i.e. rtmp server url and private key -- if mobile stream directly */}
                {/* <h1 className="text-xl font-bold pt-2">Streaming Info <i className="fa-regular fa-circle-info text-red-500"></i></h1> */}
                <h1 className="text-xl font-bold pt-2">Streaming Info</h1>
                <div className="flex grid grid-cols-6 mr-4">
                    {/* srt://live.guardedcontent.com:6001 */}
                    <textarea rows={2} placeholder="RTMP Server URL" defaultValue="srt://live.guardedcontent.com:6001?stream_id=********-****-****-****-************&passphrase=********************************" disabled className="col-span-5 w-full bg-gray-700 rounded-md px-4 py-2 my-4 hover:cursor-pointer" />
                    {/* <input type="text" placeholder="RTMP Server URL" defaultValue="rtmp://live.guardedcontent.com:5222/app" disabled className="col-span-5 w-full bg-gray-700 rounded-md px-4 py-2 my-4" /> */}
                    <button onClick={() => this.revealURL()} className="px-2 bg-gray-700 rounded-md col-span-1 mx-4 my-4 disabled:bg-gray-600 w-full"><i className="fa-solid fa-copy text-gray-300"></i> <span className="ml-1">Show Stream URL</span></button>
                </div>
                {/* <div className="flex grid grid-cols-6 mr-4">
                    <input ref={this.streamKey} onClick={() => this.copyKey()} type="text" placeholder="Stream Key" defaultValue="********-****-****-****-************" className="hover:cursor-pointer col-span-5 w-full bg-gray-700 rounded-md px-4 py-2 my-4" />
                    {(() => {
                        if (this.state.isHidden === true) {
                            return <button onClick={() => this.revealKey()} className="px-2 bg-gray-700 rounded-md col-span-1 mx-4 my-4 disabled:bg-gray-600 w-full"><i className="fa-solid fa-eye text-gray-300"></i> <span className="ml-1">Show Key</span></button>
                        } else {
                            return <button onClick={() => this.revealKey()} className="px-2 bg-gray-700 rounded-md col-span-1 mx-4 my-4 disabled:bg-gray-600 w-full"><i className="fa-solid fa-eye-slash text-gray-300"></i> <span className="ml-1">Hide Key</span></button>
                        }
                    })()}
                </div> */}
                {/* <div className="flex grid grid-cols-5">
                    <button className="px-2 py-3 bg-gray-700 rounded-md col-span-1"><i className="fa-solid fa-rotate-left text-gray-300"></i> Reset Stream Key</button>
                    <div className="col-span-3"></div>
                    <button onClick={() => this.openChat()} className="px-2 py-3 bg-gray-700 rounded-md col-span-1"><i className="fa-regular fa-comments text-gray-300 mr-2"></i> Open Chat</button>
                </div> */}
                <div className="flex grid grid-cols-6">
                    <button onClick={() => this.resetStreamKey()} className="px-2 py-3 bg-gray-700 rounded-md col-span-2"><i className="fa-solid fa-rotate-left text-gray-300"></i> Reset Stream Key</button>
                    <div className="col-span-3"></div>
                    <button onClick={() => this.openChat()} className="px-2 py-3 bg-gray-700 rounded-md col-span-1"><i className="fa-regular fa-comments text-gray-300 mr-2"></i> Open Chat</button>
                </div>
            </div>
        </div>
    }
}