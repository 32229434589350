import Footer from "../../components/Footer";
import AdminHeader from "../../components/AdminHeader";
import DashboardComponent from "../../components/admin/DashboardComponent";
import Links from "../../components/admin/Links";
import { useEffect } from "react";
import axios from "axios";

export default function AdminIndex() {

    useEffect(() => {
        // document.title = "Admin Dashboard";
        // Check for admin perms using API
        // axios.get("/api/admin/check").then((res) => {
        //     if (!res.data.isAdmin) {
        //         window.location.href = "/admin/login";
        //     }
        // }).catch((err) => {
        //     console.log(err);
        //     // window.location.href = "/admin/login";
        // });
    }, []);

    return <div>
        <AdminHeader />
        <div className="flex grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            {/* <Subscriptions /> */}
            <Links page="Dashboard" />
            <div className="col-span-2 mx-4 mt-4 bg-gray-700 px-4 py-4 rounded-md">
                <h1 className="text-2xl font-bold">Dashboard</h1>
                <DashboardComponent />
            </div>
            {/* <div className="px-2 py-2 mx-4 mt-4 bg-gray-700 rounded-md">
                <h1 className="text-2xl font-bold">ADMIN</h1>
            </div> */}
        </div>
        <Footer />
    </div>
}