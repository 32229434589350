import Header from '../components/Header';
import Subscriptions from "../components/Subscriptions";
import UpcomingLives from "../components/UpcomingLives";
import Profile from "../components/Profile";
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import CreateComponent from '../components/CreateComponent';

export default function Create() {



    return <div className="col-span-2 mx-4 mt-4">
        <div className="bg-gray-700 px-4 py-1 rounded-md mt-4">
            {/* Form we gotta first question what content type the creator wants to post i.e. make a post, go live (instant), schedule a live, group buy */}
            {/* REDO try and think of a way to make this more appealing ... */}

            <CreateComponent />


            {/* <div className="flex grid grid-rows-4 mt-4">
                <Link to={`${process.env.REACT_APP_URL}create/post`}>
                    <div className="flex justify-center bg-gray-900 my-4 rounded-md">
                        <span className="text-white text-xl font-bold h-20 py-6">Make a post</span>
                    </div>
                </Link>
                <Link to={`${process.env.REACT_APP_URL}create/live`}>
                    <div className="flex justify-center bg-gray-900 my-4 rounded-md">
                        <span className="text-white text-xl font-bold h-20 py-6">Go live</span>
                    </div>
                </Link>
                <Link to={`${process.env.REACT_APP_URL}create/stage`}>
                    <div className="flex justify-center bg-gray-900 my-4 rounded-md">
                        <span className="text-white text-xl font-bold h-20 py-6">Create a stage</span>
                    </div>
                </Link>
                <Link to={`${process.env.REACT_APP_URL}create/groupBuy`}>
                    <div className="flex justify-center bg-gray-900 my-4 rounded-md">
                        <span className="text-white text-xl font-bold h-20 py-6">Create a Group buy</span>
                    </div>
                </Link>
            </div> */}
        </div>
    </div>
}