import React from 'react';
import axios from 'axios';
import clerk from '../../utils/clerk';

type props = {};
type state = {

};

export default class ReportsComponent extends React.PureComponent<props, state> {
    componentDidMount() {
        // Make request to API to fetch reports... then on click of a report, we show a modal of the report, which we can then change status, add notes, see details (email, report description, report title)
        
    }

    render() {
        return <>
            {/* Table */}
            <table className="w-full">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Report Title</td>
                        <td>Report Category</td>
                        <td>Report Status</td>
                        <td>
                            <button className="bg-green-500 text-white p-2 rounded-md">View</button>
                            <button className="bg-red-500 text-white p-2 rounded-md">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    }
}