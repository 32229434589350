import React from 'react';
import logo from '../GCLOGO.png';
import { Link } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { SignedIn, SignedOut } from '@clerk/clerk-react';

type props = {};
type state = {
    loading: boolean;
    showingSolutions: boolean;
}

export default class LandingPage extends React.Component<props, state> {
    private textDiv: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            showingSolutions: false
        }
        this.setShowing = this.setShowing.bind(this);
    }

    async componentDidMount() {
        
    }

    setShowing(showing: boolean) {
        this.setState({showingSolutions: showing});
    }

    render() {
        return <div className="w-full min-h-screen">
            <header className="flex grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 px-4 py-4">
                <div className="hidden lg:block xl:block 2xl:block">
                    <Link to={`${process.env.REACT_APP_URL}`}><img src={logo} width={'200'} className="py-4" /></Link>
                </div>
                <div id="links" className="flex py-2 gap-8 justify-center">
                    <Link to={`${process.env.REACT_APP_URL}`} className="text-xl font-bold">Home</Link>
                    <Popover
                        onMouseEnter={() => this.setShowing(true)}
                        onMouseLeave={() => this.setShowing(false)}
                    >
                        <PopoverButton className="text-xl font-bold">Solutions</PopoverButton>
                        <Transition
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                            show={this.state.showingSolutions}
                        >
                            <PopoverPanel
                            anchor="bottom"
                            className="divide-y divide-white/5 rounded-xl bg-white/5 text-sm/6 [--anchor-gap:var(--spacing-5)] mt-2"
                            >
                            <div className="p-3">
                                <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                    <p className="font-semibold text-white">General Media</p>
                                    <p className="text-white/50">Protect your content using DRM</p>
                                </a>
                                {/* <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                    <p className="font-semibold text-white">Music</p>
                                    <p className="text-white/50">Protect your music using DRM</p>
                                </a> */}
                                {/* <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                    <p className="font-semibold text-white">Corporate Documents</p>
                                    <p className="text-white/50">Protect your documents using DRM</p>
                                </a> */}
                            </div>
                            {/* <div className="p-3">
                                <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                <p className="font-semibold text-white">Documentation</p>
                                <p className="text-white/50">Start integrating products and tools</p>
                                </a>
                            </div> */}
                            </PopoverPanel>
                        </Transition>
                    </Popover>
                    {/* <Link to={`${process.env.REACT_APP_URL}pricing`} className="text-xl font-bold">Pricing</Link> */}
                    {/* <Link to={`${process.env.REACT_APP_URL}faq`} className="text-xl font-bold">FAQ</Link> */}
                </div>
                <div>
                    <SignedIn>
                        <Link to={`${process.env.REACT_APP_URL}app/`} className="text-white bg-red-600 px-3 py-1 rounded-md float-right font-bold">Open App</Link>
                    </SignedIn>
                    <SignedOut>
                        <div>
                            <Link to={`${process.env.REACT_APP_URL}signup`} className="text-white bg-red-600 px-3 py-1 rounded-md float-right font-bold">Sign Up</Link>
                            <Link to={`${process.env.REACT_APP_URL}login`} className="text-white px-3 py-1 rounded-md float-right font-bold">Login</Link>
                        </div>
                    </SignedOut>
                </div>
            </header> 

            <main className="w-full h-full">
                <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
                    <div className="container px-4 md:px-6">
                        <div className="grid gap-6 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_550px]">
                            <div className="flex flex-col justify-center space-y-4">
                                <div className="space-y-2">
                                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                                    Doing what should be considered the BARE MINIMUM for content creators
                                </h1>
                                <p className="max-w-[600px] text-gray-400 md:text-xl">
                                    Join the next generation of content creators! We provide access to the tools to help protect your content and monetize it.
                                </p>
                                </div>
                                <div className="flex flex-col gap-2 min-[400px]:flex-row">
                                <SignedOut>
                                    <button className="bg-red-600 text-white hover:bg-red-700 px-2 py-1 rounded-md">Get Started</button>
                                </SignedOut>
                                <SignedIn>
                                    <Link to={`${process.env.REACT_APP_URL}app/`} className="bg-red-600 text-white hover:bg-red-700 px-2 py-1 rounded-md">Open App</Link>
                                </SignedIn>
                                <button className="text-red-400 border-red-400 hover:underline hover:cursor-pointer">Learn More</button>
                                </div>
                            </div>
                            {/* <div className="flex items-center justify-center">
                                <div className="relative w-full aspect-video rounded-xl overflow-hidden">
                                <Image
                                    src="/placeholder.svg"
                                    alt="Platform preview"
                                    layout="fill"
                                    objectFit="cover"
                                    className="bg-gray-800"
                                />
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <button className="w-16 h-16 rounded-full bg-gray-900/50 text-white hover:bg-gray-900/75">
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                            />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span className="sr-only">Play video</span>
                                    </button>
                                </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* Lets change the game. */}
                {/* <h1>Next Generation</h1> */}
            </main>
        </div>
    }
}