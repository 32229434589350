import React from 'react';

type props = {}

type state = {
    loading: boolean;
}

export default class UpcomingLives extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }


    render() {
        // return <div className="bg-gray-800 px-4 py-4 rounded-md my-4 mx-2 mr-4">
        //     <h1 className="text-white text-2xl font-bold">Upcoming Livestreams</h1>
        //     {(() => {
        //         if (this.state.loading) {
        //             return <div className="flex grid grid-cols-2">
        //                 <div className="flex items-center mt-4">
        //                     <span className="flex min-h-10 min-w-10 rounded-full bg-slate-700 animate-pulse"></span>
        //                     <div className="ml-4 flex grid grid-rows-2">
        //                         <span className="h-2 w-20 bg-slate-700 rounded animate-pulse"></span>
        //                         <span className="h-2 w-24 mt-1 bg-slate-700 rounded animate-pulse"></span>
        //                     </div> 
        //                 </div> 
        //                 <div className="flex justify-end">
        //                     <span className="h-4 w-20 mt-5 bg-slate-700 rounded animate-pulse"></span>
        //                 </div>  
        //             </div>
        //         } else {
        //             return <div className="flex grid grid-cols-2">
        //             <div className="flex items-center mt-4">
        //                 <span className="min-h-10 min-w-10 rounded-full bg-red-600"></span>
        //                 <div className="ml-4">
        //                     <h3 className="text-white font-bold">John Doe</h3>
        //                     <p className="text-gray-300">@Username</p>
        //                 </div>
        //             </div>
        //             <div className="flex justify-end">
        //                 <span className="px-4 py-4">3:30pm</span>
        //             </div>
        //         </div>
        //         }
        //     })()}
        // </div>
        return <></>
    }
}