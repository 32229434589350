import React from "react";
import MuxPlayer from "@mux/mux-player-react";
import Subscriptions from "../Subscriptions";
import axios from "axios";
import clerk from '../../utils/clerk';
import io from "socket.io-client";
import profilePicTemp from '../../ProfilePicTemp.png'

type props = {};
type state = {
    messages: string[];
    message: string;
    playbackID: string | null;
    title: string | null;
    connectionState: string;
};

export default class ChatComponent extends React.Component<props, state> {
    socket: any;
    inputMessage: any;
    messagesEndRef: any;
    msgwin: any;

    constructor(props: any) {
        super(props);
        this.state = {
            messages: [],
            message: "",
            playbackID: null,
            title: null,
            connectionState: 'connecting',
        };
        this.sendMessage = this.sendMessage.bind(this);
        this.inputMessage = React.createRef();
        this.messagesEndRef = React.createRef();
        this.msgwin = React.createRef();
    }

    componentDidMount() {
        const username = window.location.pathname.split("/")[3];
        console.log(username);

        clerk.session?.getToken().then((token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}/playbackID`, { headers: { 'x-auth-token': token } })
                .then((res: any) => {
                    this.setState({ playbackID: res.data.playbackID, title: res.data.title });
                })
                .catch(err => {
                    console.log(err);
                });
        });

        this.socket = io("https://desktop.tailnet-28e3.ts.net:442");
        this.socket.on("connect", () => {
            this.setState({ connectionState: 'connected' });
        });
        this.socket.on("message", (message: any) => {
            this.setState((prevState) => ({
                messages: [...prevState.messages, message],
            }), () => {
                // Scroll to the bottom after state has been updated
                if (this.messagesEndRef.current) {
                    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
                }
            });
        });

        this.socket.on("disconnect", () => {
            this.setState({ connectionState: 'disconnected' });
        });
    }

    sendMessage = (e: any) => {
        e.preventDefault();
        if (this.state.message.length > 0) {
            clerk.session?.getToken().then((token) => {
                this.socket.emit("message", {msg: this.state.message, tok: token});
                this.setState({ message: "" });
                this.inputMessage.current.value = "";
            });
        }
    };

    render() {
        return (
            <>
                <div className="flex grid grid-cols-1 sm:grid-cols-1 sm:w-full md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
                    <Subscriptions />
                    <div className="col-span-2 mx-4 mt-4">
                        <div className="bg-gray-700 w-full h-full my-4 rounded-md">
                            <MuxPlayer
                                className="w-full h-full"
                                playbackId={this.state.playbackID || ""}
                                autoPlay={false}
                                streamType="on-demand"
                                maxResolution="1080p"
                                minResolution="720p"
                                accentColor="#dc2626"
                            />
                        </div>
                        <div className="bg-gray-700 rounded-md px-4 py-4">
                            {/* Profile Picture ... */}
                            <h1 className="text-2xl font-bold">{this.state.title}</h1>
                        </div>
                    </div>
                    <div className="mt-24 mx-4 md:mr-4 lg:mr-4 xl:mr-4 2xl:mr-4 md:mt-4 lg:mt-4 xl:mt-4 2xl:mt-4">
                        <div className="bg-gray-700 w-full my-4 px-4 py-4 rounded-md h-full flex flex-col">
                            <h1 className="text-2xl font-bold mb-4">Live Chat
                                {(() => {
                                    if (this.state.connectionState === 'disconnected') {
                                        return <span className="text-red-500"> - Disconnected</span>;
                                    } else if (this.state.connectionState === 'connecting') {
                                        return <span className="text-slate-500 animate-pulse"> - Connecting</span>;
                                    } else if (this.state.connectionState === 'connected') {
                                        return <span className="text-green-500"> - Connected</span>;
                                    }
                                })()}
                            </h1>
                            <div className="flex flex-col flex-grow h-full">
                                <div className="flex-grow bg-gray-800 py-4 px-4 rounded-md">
                                    <div ref={this.msgwin} className="max-h-[40vh] overflow-y-auto">
                                        {(() => {
                                            if (this.state.connectionState === 'disconnected') {
                                                return (
                                                    <div className="flex flex-row w-full mb-2">
                                                        <div className="flex p-2 w-full rounded-md">
                                                            <span className="flex justify-center text-center">Connecting to socket ...</span>
                                                        </div>
                                                    </div>
                                                );
                                            } else if (this.state.connectionState === 'connected') {
                                                return this.state.messages.map((message, index) => (
                                                    <div key={index} className="flex flex-row mb-2">
                                                        <div className="w-12 h-12 mr-2">  {/* Adjust size of profile picture */}
                                                            <img src={profilePicTemp} alt="Profile" className="rounded-full w-full h-full my-2 object-cover" />
                                                        </div>
                                                        <div className="bg-gray-900 w-full p-2 rounded-md overflow-auto"> {/* Message takes priority, can scroll if too big */}
                                                            <span className="font-bold">Username</span>
                                                            <p>{message}</p>
                                                        </div>
                                                    </div>
                                                ));
                                            }
                                        })()}
                                        <div ref={this.messagesEndRef} />
                                    </div>
                                </div>
                                <div className="flex-none mt-4">
                                    <form onSubmit={this.sendMessage} className="flex w-full space-x-2">
                                        <input
                                            type="text"
                                            ref={this.inputMessage}
                                            onChange={(e) => this.setState({ message: e.target.value })}
                                            className="bg-gray-800 p-2 rounded-md flex-grow focus:outline-none"
                                            placeholder="Message ..."
                                        />
                                        <button className="px-2 py-1 bg-gray-900 rounded-md text-white">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
