import React from 'react';
import axios from 'axios';
import profilePicTemp from '../ProfilePicTemp.png';

type props = {};

type state = {
    contacts: Array<any>,
    messages: Array<any>
};

export default class Messages extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            contacts: [],
            messages: []
        }
    }

    async componentDidMount() {
        // Get initial Messages/contacts ...
        await axios.get(`${process.env.REACT_APP_API_URL}api/v1/messages`, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
            console.log(res.data);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    render() {
        return <>

        </>
    }
}