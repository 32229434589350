import React from 'react';
import clerk from '../../utils/clerk';
import axios from 'axios';

export default class AllUsers extends React.PureComponent<{}, {}> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        clerk.session?.getToken().then((token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/admin/users`, { headers: { 'x-auth-token': token } }).then((response) => {
                console.log(response.data);
            }).catch((error: any) => {
                console.error(error);
            });
        });
    }

    render() {
        return <div>
            {/* <h1>All Users</h1> */}
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="text-left">Display name  /  Username</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Created At</th>
                        <th>Actions</th> {/* Suspend, Ban, view more modal button */ }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>John Doe</td>
                        <td>johnDoe@email.com</td>
                        <td>2021-08-01</td>
                        <td>
                            <button className="px-2 py-1">Suspend</button>
                            <button className="px-2 py-1">Ban</button>
                            <button className="px-2 py-1">View More</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}