import Invoices from '../components/Invoices';
import axios from 'axios';
import clerk from '../utils/clerk';

export default function Earnings() {

    function openStripe() {
        // Open stripe dashboard
        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/stripe/customerPortal`, { headers: { 'x-auth-token': token } }).then(res => {
                window.open(res.data.url, '_blank');
            });
        });


        // window.open('https://dashboard.stripe.com/test/dashboard', '_blank');
    }

    return (
        <div className="col-span-2 mx-4 mt-4">
            <div className="flex w-full justify-between items-center">
                <h1 className="text-2xl font-bold">Transactions</h1>
                <button onClick={() => openStripe()} className="px-2 py-1 rounded-md text-black bg-white shadow-md">Manage</button>
            </div>
            {/* Display relevant earnings details below... maybe graphs??
                export revenue slips... csv files.
            */}
            <Invoices />
        </div>
    )
}