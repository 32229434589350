import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Subscriptions from "../components/Subscriptions";
import UpcomingLives from "../components/UpcomingLives";
import Profile from "../components/Profile";
import Footer from '../components/Footer';
import axios from 'axios';
import clerk from '../utils/clerk';

export default function PageComponent() {
    const [user, setUser] = useState();

    useEffect(() => {
        // console.log(clerk.user?.getSessions());
        console.log(clerk.session);

        if (clerk.user) {
            // Check User Token
            clerk.session?.getToken().then((token) => {
                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, {}, { headers: { 'x-auth-token': token, 'sessionID': clerk.session?.id } }).then((res) => {
                    console.log(res.data);
                    setUser(res.data);
                }).catch(err => {
                    if (err.response.data.msg === 'Not Verified') {
                        clerk.signOut({redirectUrl: '../login'});
                    }
                    console.error(err);
                });
            });
        } else {
            clerk.signOut({redirectUrl: '../login'});
        }
    }, []);

    return <div>
        <Header />
        <div className="flex grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <Subscriptions />
            <Outlet />
            <div className="hidden md:block lg:block xl:block 2xl:block">
                <Profile />
                <UpcomingLives />
            </div>
        </div>
        <Footer />
    </div>
}