import { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { SignUp } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';

export default function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dob, setDob] = useState('');

    const signup = (e: any) => {
        e.preventDefault();
        // Axios request to backend ...

        if (dob === '') {
            toast.error('Please enter a valid date of birth.');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/signup`, { email, password, dob }).then((res) => {
            console.log(res.data);
            if (res.data.valid === true) {
                // Redirect to main page
                localStorage.setItem('token', res.data.token);
                window.location.href = '/';
            } else {
                // Invalid
                console.error('Invalid Signup');
                console.log(res.data);
                // toast.error('User Exists');
            }
        }).catch(err => {
            console.error(err);
            // err.response.data.error === 'User Exists
            if (err.response.data.error === 'User Exists') {
                toast.error('User Exists', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                toast.error('An unknown error has occured. Please try again later.');
            }
        });
    }

    const validateBirthday = (date: string) => {
        // Validate birthday
        // Check if user is over 18
        // If not, show error message

        var today = new Date();
        var birthdate = new Date(date);
        var age = today.getFullYear() - birthdate.getFullYear();
        var m = today.getMonth() - birthdate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }

        console.log(birthdate.getFullYear(), Number.isNaN(birthdate.getFullYear()));

        // if (Number.isNaN(birthdate.getFullYear()) === true) {
        //     const btn = document.getElementById('submit');
        //     // @ts-ignore
        //     btn.setAttribute('disabled', 'true');
        // }

        if (age < 18) {
            setDob(date);
            // Show error message
            toast.error('You must be over 18 to sign up.');

            const tos = document.getElementById('tos');
            const btn = document.getElementById('submit');
            // @ts-ignore
            if (tos !== null && btn !== null && tos.checked === true) {
                btn.setAttribute('disabled', 'true');
            }
        } else if (Number.isNaN(birthdate.getFullYear()) === true || birthdate.getFullYear() < 1900) {
            setDob(date);
            const btn = document.getElementById('submit');
            // @ts-ignore
            btn.setAttribute('disabled', 'true');
        } else if (age >= 18) {
            // Set date of birth
            setDob(date);
            const tos = document.getElementById('tos');
            const btn = document.getElementById('submit');
            // @ts-ignore
            // console.log(tos.checked, btn);
            // @ts-ignore
            if (tos !== null && btn !== null && tos.checked === true) {
                btn.removeAttribute('disabled');
            }
        }

        return age;
    }

    const verifySubmission = (e: any) => {
        console.log(e.target.checked, validateBirthday(dob));
        if (e.target.checked === true && validateBirthday(dob) >= 18) {
            // Enable submit button
            const btn = e.target.parentElement.querySelector('button');
            btn.removeAttribute('disabled');
            console.log(btn);
        } else {
            // Disable submit button
            const btn = e.target.parentElement.querySelector('button');
            btn.setAttribute('disabled', 'true');
            // e.target.parentElement.querySelector('button').classList.add('disabled');
        }
    }

    return <div className="flex justify-center items-center h-screen">
        <Toaster position="bottom-center" />
        <SignUp 
            appearance={{
                baseTheme: dark,
                elements: {
                    backgroundColor: '#374151'
                }
            }}
            forceRedirectUrl='/app/'
        />
        {/* <div className="bg-gray-700 p-6 w-1/4 rounded-md"> */}
            {/* <form onSubmit={(e) => signup(e)}>
                <h1 className="text-2xl font-bold text-center">Create an account</h1>
                <h3 className="mt-8 text-xl font-bold">Email Address</h3>
                <input onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Email Address ..." className="w-full mt-2 px-4 text-gray-100 text-base bg-gray-800 rounded-md h-8" required />
                <h3 className="mt-8 text-xl font-bold">Password</h3>
                <input onChange={(e) => setPassword(e.target.value)} name="password" type="password" placeholder="Password ..." className="w-full mt-2 px-4 text-gray-100 text-base bg-gray-800 rounded-md h-8" required />
                <h3 className="mt-8 text-xl font-bold">Date of Birth</h3>
                <input onChange={(e) => validateBirthday(e.target.value)} name="dob" type="date" placeholder="Date of Birth ..." className="w-full mt-2 px-4 text-gray-100 text-base bg-gray-800 rounded-md h-8" required />
                <div className="mt-4">
                    <a href="./login" className="text-red-500 text-xs font-semibold mt-2">Already got an account?</a>
                </div>
                <input onChange={(e) => verifySubmission(e)} name="tos" id="tos" type="checkbox" className="mt-4" required />
                <label htmlFor="tos" className="text-xs text-white-500 pl-2">I agree to the <a href="https://guardedcontent.com/terms" target="_blank" className="text-red-500 underline">Terms and Conditions</a> and <a href="https://guardedcontent.com/privacy" target="_blank" className="text-red-500 underline">Privacy Statement</a></label>
                <button id="submit" type="submit" disabled className="disabled:opacity-50 w-full bg-red-500 text-white rounded-md mt-8 h-8">Sign up</button>
            </form> */}
        {/* </div> */}
    </div>
}