import React from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
// import { Link } from 'react-router-dom';
// import ProfileFeed from './profile/ProfileFeed';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShakaPlayerComponent from './ShakaPlayerComponent';
import ProfilePic from '../ProfilePicTemp.png';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import MoonLoader from 'react-spinners/MoonLoader';
// import Cards from 'react-credit-cards-2';
// import "react-credit-cards-2/dist/es/styles-compiled.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import { CreditCard, PaymentForm, CashAppPay, GooglePay } from 'react-square-web-payments-sdk';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import clerk from '../utils/clerk';


type props = {};

type state = {
    loading: boolean;
    subscriptions: any[];
    user: any[];
    username: string;
    following: boolean;
    isSelf: boolean;
    items: any[];
    pageNum: number;
    hasMore: boolean;
    followingUser: boolean;
    bannerURL: string;
    verified: boolean;
    profileModalShown: boolean;
    profileUploading: boolean;
    purchaseShow: boolean;
    purchaseData: post | null;
    isPaymentActive: boolean;
    myName: string;
    purchaseID: string;
    email: string;
    comments: any[];
    commentModal: boolean;
    commentVal: string;
}

type post = {
    access: boolean;
    createdAt: string;
    description: string;
    followers: number;
    isPostLiked: boolean;
    likes: number;
    postID: string;
    price: number;
    profilePic: {
        profilePic: string;
        _id: string;
    };
    status: string;
    title: string;
    username: string;
}

export default class UserComponent extends React.PureComponent<props, state> {
    private usernameInput = React.createRef<HTMLInputElement>();
    private displayNameInput = React.createRef<HTMLInputElement>();
    private closeModalBTN = React.createRef<HTMLButtonElement>();
    private payBTN = React.createRef<HTMLButtonElement>();
    
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            subscriptions: [],
            user: [],
            username: '',
            following: false,
            isSelf: false,
            items: [],
            pageNum: 0,
            hasMore: true,
            followingUser: false,
            verified: false,
            bannerURL: '',
            profileModalShown: false,
            profileUploading: false,
            purchaseShow: false,
            purchaseData: null,
            isPaymentActive: false,
            purchaseID: '',
            myName: '',
            email: '',
            comments: [],
            commentModal: false,
            commentVal: ''
        }
        // this.toggleFollow = this.toggleFollow.bind(this);
        this.fetchData = this.fetchData.bind(this);
        // this.inputChange = this.inputChange.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);
        this.openCommentModal = this.openCommentModal.bind(this);
        this.postComment = this.postComment.bind(this);
        // this.checkoutSession = this.checkoutSession.bind(this);
        // this.loadStripeComponent = this.loadStripeComponent.bind(this);
    }

    componentDidMount(): void {
        // var username = window.location.pathname.split('/')[2];
        var displayNamePrompt = window.location.search;
        console.log(displayNamePrompt)

        // console.log(displayNamePrompt.split('=')[1]);

        if (displayNamePrompt.split('=')[1] === 'true') {
            // Prompt user to enter display name
            this.setState({profileModalShown: true});
        }

        const script = document.createElement('script');
        script.src = '/jsencrypt.min.js';
        script.async = true;
        document.body.appendChild(script);

        // this.setState({username: username});
        // var token = localStorage.getItem('token');
        if (clerk.user) {
            // Axios request to backend ...

            clerk.session?.getToken().then(async (token) => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/myPurchased`, { headers: { 'x-auth-token': token } }).then((res) => {
                    if (res.data.user === null) {
                        // Redirect to 404
                        window.location.href = '/404';
                    } else {
                        this.setState({ items: res.data.posts, pageNum: 1, myName: res.data.displayName, user: res.data.user, username: res.data.user._id, loading: false, following: res.data.following, isSelf: res.data.isSelf, bannerURL: res.data.bannerPic });
                    }
                }).catch(err => {
                    // const data = err.response.data;
                    this.setState({ items: err.response.data.posts, pageNum: 1, myName: err.response.data.displayName, user: err.response.data.user, username: err.response.data.user._id, loading: false, following: err.response.data.following, isSelf: err.response.data.isSelf, bannerURL: err.response.data.bannerPic });
                    console.error(err.response.data)
                });

            // axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}`, { headers: { 'x-auth-token': token } }).then((res: any) => {
            //     // console.log(res.data.user);
            //     if (res.data.user === null) {
            //         // Redirect to 404
            //         window.location.href = '/404';
            //     } else {
            //         this.setState({ myName: res.data.self.displayName, user: res.data.user, username: username, loading: false, following: res.data.following, isSelf: res.data.isUser, bannerURL: res.data.bannerPic });
            //     }
            // }).catch(err => {
            //     if (err.response.data.msg === 'Not Verified') {
            //         // Redirect to login
            //         localStorage.removeItem('token');
            //         window.location.href = '/login';
            //     } else if (err.response.data.msg === 'User not found') {
            //         window.location.href = '/404';
            //     }
            //     console.error(err);
            // });

            // axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}/feed`, { headers: { 'x-auth-token': token} }).then((res) => {
            //     console.log(res.data);
            //     this.setState({ items: res.data.posts, pageNum: 1, followingUser: true });
            // }).catch((err) => {
            //     console.log(err);
            //     if (err.response.data.msg === 'User not following') {
            //         this.setState({followingUser: false})
            //     } else if (err.response.data.msg === 'No posts found') {
            //         this.setState({ hasMore: false, followingUser: true });
            //     } else if (err.response.data.msg === 'Not Verified') {
            //         localStorage.removeItem('token');
            //         window.location.href = '/login';
            //     } else if (err.response.data.error === 'Missing Auth Token') {
            //         localStorage.removeItem('token');
            //         window.location.href = '/login';
            //     }
            // });

                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/email`, null, { headers: { 'x-auth-token': token } }).then((res) => {
                    this.setState({ email: res.data.email });
                }).catch(err => {
                    console.log(err);
                })
            });
        } else {
            // Redirect to login
            window.location.href = '/login';
        }
    }

    async fetchData() {
        // console.log(this.state.pageNum);
        var pageNum = this.state.pageNum;
        pageNum++;
        // console.log(pageNum);
        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/myPurchased?page=${pageNum}`, { headers: { 'x-auth-token': token} }).then((res) => {
                // console.log(res.data);
                var stateItems = this.state.items;
                // Add to stateItems
                res.data.posts.forEach((post: any) => {
                    stateItems.push(post);
                });

                this.setState({ items: stateItems, pageNum: pageNum, followingUser: true, verified: res.data.verified });
            }).catch((err) => {
                console.log(err);
                if (err.response.data.msg === 'User not following') {
                    this.setState({followingUser: false})
                } else if (err.response.data.msg === 'No Purchases Found') {
                    this.setState({ hasMore: false });
                } else if (err.response.data.msg === 'Not Verified') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else if (err.response.data.error === 'Missing Auth Token') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            });
        });
    }

    async toggleLike(e: any, postID: any) {
        // e.target.classList.toggle('text-red-500');
        // e.target.classList.toggle('fa-heart');

        // Make API request 
        // var token = localStorage.getItem('token');
        axios.put(`${process.env.REACT_APP_API_URL}api/v1/post/${postID}/like`, null, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
            // Update selected `post` in state to avoid updating all posts ...
            // this.setState({})
            var updatedItems = this.state.items.map((post) => {
                if (post.postID === postID) {
                    post.likes = res.data.likes;
                    post.isPostLiked = !post.isPostLiked;
                }
                return post;
            });
            this.setState({ items: updatedItems });
        }).catch((err) => {
            console.error(err);
            if (err.response.data.msg === 'Not Verified') {
                localStorage.removeItem('token');
                window.location.href = '/login';
            } else if (err.response.data.error === 'Missing Auth Token') {
                localStorage.removeItem('token');
                window.location.href = '/login';
            } else {
                // e.target.classList.toggle('text-red-500');
                // e.target.classList.toggle('fa-heart');
                // Toast like error to user
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        });

    }

    responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    async uploadBanner() {
        var fileInput = document.getElementById('profileBanner');
        if (fileInput) {
            fileInput.click();
            fileInput.addEventListener('change', async (e: any) => {
                // console.log(e.target.files[0]);
                var file = e.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/banner`, formData, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                    // console.log(res.data);
                    if (res.data.msg === 'Banner Updated') {
                        // this.setState({ user: res.data.user });
                        window.location.reload();
                    } else {
                        toast.error('An error has occurred!', { style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }});
                    }
                }).catch(err => {
                    console.error(err);
                });
            });
        }
    }

    async uploadProfilePic() {
        var fileInput = document.getElementById('profilePicture');
        console.log(fileInput)
        if (fileInput) {
            fileInput.click();
            fileInput.addEventListener('change', async (e: any) => {
                // console.log(e.target.files[0]);
                var file = e.target.files[0];
                var formData = new FormData();
                formData.append('file', file);

                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profilepic`, formData, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                    // console.log(res.data);
                    // this.setState({ user: res.data.user });
                    window.location.reload();
                }).catch(err => {
                    console.error(err);
                });
            });
        }
    }

    closeProfileModal() {
        this.setState({ profileModalShown: false });
    }

    openEditModal() {
        this.setState({ profileModalShown: true });
    }

    async updateProfile() {
        // var token = localStorage.getItem('token');
        var displayName = this.displayNameInput.current?.value;
        var username = this.usernameInput.current?.value;
        await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, { displayName: displayName }, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
            // console.log(res.data);
            this.setState({ user: res.data.user });
            this.closeProfileModal();
            toast.success('Profile Updated!', { style: {
                color: '#ffffff',
                backgroundColor: '#242C37',
            }});
            setTimeout(() => {
                // @ts-ignore
                if (this.state.verified === true) {
                    // @ts-ignore
                    if (this.state.user.username !== username) {
                        // Got to new Profile
                        window.location.href = `/app/user/${username}`;
                    } else {
                        window.location.reload();
                    }
                } else {
                    window.location.href = `/app/profile`
                    // window.location.reload();
                }
            }, 200);
        }).catch(err => {
            console.error(err);
            if (err.response.data.msg === 'Not Verified') {
                localStorage.removeItem('token');
                window.location.href = '/login';
            } else if (err.response.data.msg === 'Display Name or Username already exists') {
                toast.error('Display Name already exists!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        });
    }

    closeCommentModal() {
        this.setState({ commentModal: false });
    }

    openCommentModal(comments: any[]) {
        this.setState({ commentModal: true, comments: comments });
    }
    
    postComment(e: any) {
        e.preventDefault();
        // Get comment value
        var comment = this.state.commentVal;

        console.log(comment);

        var newComment = {
            comment: comment
        }
    }

    render() {
        function formatFollowers(followers: number) {
            if (followers > 999 && followers < 1000000) {
                return (followers / 1000).toFixed(1) + 'K Followers';
            } else if (followers > 999999) {
                return (followers / 1000000).toFixed(1) + 'M Followers';
            } else if (followers === 1) {
                return followers + ' Follower';
            } else {
                return followers + ' Followers';
            }
        }

        function formatLikes(likes: number) {
            if (likes > 999 && likes < 1000000) {
                return (likes / 1000).toFixed(1) + 'K Likes';
            } else if (likes > 999999) {
                return (likes / 1000000).toFixed(1) + 'M Likes';
            } else if (likes === 1) {
                return likes + ' Like';
            } else if (likes === 0) {
                return 'Like';
            } else {
                return likes + ' Likes';
            }
        }

        function formatComments(comments: any[] | undefined) {
            // console.log(comments.length)
            if (comments !== undefined) {
                if (comments.length > 999 && comments.length < 1000000) {
                    return (comments.length / 1000).toFixed(1) + 'K Comments';
                } else if (comments.length > 999999) {
                    return (comments.length / 1000000).toFixed(1) + 'M Comments';
                } else if (comments.length === 1) {
                    return comments.length + ' Comment';
                } else if (comments.length === 0) {
                    return '0 Comments';
                } else {
                    return comments.length + ' Comments';
                }
            } else {
                return '0 Comments';
            }
        }

        return <div className="col-span-2 mx-4">
            <Toaster position="bottom-center" />
            {/* Mock profile page ... */}
            <div id="profile" className="bg-gray-700 w-full my-3 pb-4 rounded-md">
                {(() => {
                    if (this.state.isSelf) {
                        // console.log(this.state.user)
                        // @ts-ignore
                        if (this.state.user.bannerPic === null || !this.state.user.bannerPic || this.state.user.bannerPic === '') {
                            return <>
                                <input type="file" className="hidden" name="profileBanner" id="profileBanner" accept="image/png, image/jpeg" />
                                <div onClick={() => this.uploadBanner()} className="bg-red-600 h-60 w-full rounded-t-md cursor-pointer"></div>
                            </>
                        } else {
                            return <>
                                <input type="file" className="hidden" name="profileBanner" id="profileBanner" accept="image/png, image/jpeg" />
                                {/* @ts-ignore */}
                                <img alt="Upload a banner" title="Upload a banner" src={this.state.user.bannerPic} onClick={() => this.uploadBanner()} className="w-full h-60 rounded-t-md object-cover hover:cursor-pointer" />
                            </>
                        }
                    }
                })()}

                {/* <div className="bg-red-600 h-60 w-full rounded-t-md"></div> */}
                <div className="flex items-center h-full w-full px-4 pt-4">
                    {(() => {
                        if (this.state.isSelf) {
                            // @ts-ignore
                            if (this.state.user.profilePic === null || this.state.user.profilePic === undefined || !this.state.user.profilePic) {
                                return <>
                                    <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                    <img alt="Upload a banner" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={ProfilePic} className="h-14 w-14 rounded-full cursor-pointer" />
                                    {/* <div onClick={() => this.uploadProfilePic()} className="bg-red-600 h-14 w-14 rounded-full cursor-pointer"></div> */}
                                </>
                                // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                            } else {
                                return <>
                                    <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                    {/* @ts-ignore */}
                                    <img alt="Upload a profile picture" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={this.state.user.profilePic} className="h-14 w-14 rounded-full cursor-pointer" />
                                </>
                            }
                        } else {
                            // @ts-ignore
                            if (this.state.user.profilePic === null || this.state.user.profilePic === undefined || !this.state.user.profilePic) {
                                // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                return <img src={ProfilePic} className="5-14 w-14 rounded-full" />
                            } else {
                                // @ts-ignore
                                // return <img src={this.state.user.profilePic} className="h-14 w-14 rounded-full" />
                                return <img src={this.state.user.profilePic} className="h-14 w-14 rounded-full" />
                            }
                        }
                    })()}
                    {/* <span className="h-14 w-14 rounded-full bg-red-600"></span> */}
                    {(() => {
                            if (this.state.isSelf) {
                                // @ts-ignore
                                return <div className="ml-4">
                                    {/* @ts-ignore */}
                                    <h3 onClick={(() => this.openEditModal())} className="text-white text-xl font-bold hover:cursor-pointer"> {this.state.user.displayName} <i className="fa-regular fa-pencil text-gray-300 px-1 text-sm" title="Edit" /></h3>
                                </div>
                            }
                        })()}
                    {/* <div className="ml-4">                        
                        <h3 className="text-white text-xl font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {this.state.user.displayName}</h3>
                        <p className="text-gray-300 text-xl">@{this.state.username}</p>
                    </div> */}
                    <div className="flex-grow"></div>
                    {/* <div className="flex items-end">
                        {(() => {
                            if (this.state.isSelf) {
                                // return <Link to={`${process.env.REACT_APP_URL}manage/creator`} className="bg-white text-black text-base font-bold rounded-md px-4 py-1">Manage Creator Account</Link>
                                return <span onClick={(() => this.openEditModal())} className="bg-white text-black text-base font-bold rounded-md px-4 py-1 hover:cursor-pointer">Manage</span>
                            } else {
                                if (!this.state.following) {
                                    return <button onClick={() => this.toggleFollow()} className="bg-red-600 text-white text-base font-bold rounded-md px-4 py-1">Follow</button>
                                } else {
                                    return <button onClick={() => this.toggleFollow()} className="bg-white text-black text-base font-bold rounded-md px-4 py-1">Unfollow</button>
                                }
                            }
                        })()}
                    </div> */}
                </div>
            </div>
            {/* Display Profile Feed */}
            {(() => {
                // console.log(this.state.items);
                if (this.state.loading === false && this.state.items === undefined) {
                    return <div className="bg-gray-700 w-full my-4 px-4 py-4 rounded-md">
                        <h1 className="text-lg font-bold text-center">Any purchased content will appear here!</h1>
                    </div>
                } else if (this.state.loading === false && this.state.items.length !== 0) {
                    return <InfiniteScroll 
                        dataLength={this.state.items.length}
                        next={this.fetchData}
                        hasMore={this.state.hasMore}
                        loader={
                            <div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        scrollableTarget="scrollableDiv"
                        >
                            {(() => {
                                if (Array.isArray(this.state.items)) {
                                    return this.state.items.map((post, index) => (
                                        <div className="bg-gray-700 w-full my-4 rounded-md py-4 px-4">
                                            <div className="flex items-center h-full w-full">
                                                {(() => {
                                                    if (post.profilePic === null || post.profilePic === undefined) {
                                                        return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                                    } else {
                                                        // return <img src={post.profilePic} className="h-14 w-14 rounded-full" />
                                                        return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${post.username}/profilePic`} className="h-14 w-14 rounded-full" />
                                                    }
                                                })()}
                                                <div className="ml-4">
                                                    {/* @ts-ignore */}
                                                    <h3 className="text-white font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {post.displayName}</h3>
                                                    <p className="text-gray-300">{formatFollowers(post.followers)}</p>
                                                </div>
                                                {/* <div className="float-right absolute flex">
                                                    <div className="flex-grow"></div>
                                                    <div className="flex items-end">
                                                        <button className="bg-red-600 text-white text-base font-bold rounded-md px-4 py-1">Follow</button>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="mt-4 px-2">
                                                <h1 className="text-2xl font-bold pb-4">{post.title}</h1>
                                                {/* <p className="pb-4">Juice & Cordae</p> */}
                                            </div>

                                            <div id="description">
                                                <p className="text-white text-lg px-2">{post.description}</p>
                                            </div>
                        
                                            <div id="media" className="mt-4">
                                                {(() => {
                                                    if (post.access === true) {
                                                        return <div className="flex justify-center px-2">
                                                            <div className="flex gap-4">
                                                                {(() => {
                                                                    if (post.content) {
                                                                        if (post.content.length > 1) {
                                                                            // return <></>
                                                                            return <Carousel>
                                                                                {/* {post.content.map((content: any, index: any) => (
                                                                                    <div className="pb-10"><ShakaPlayerComponent name={this.state.myName} manifestURI={`${process.env.REACT_APP_API_URL}files/${content.url}`} type={content.type} contentID={content.id} ogname={content.filename} contentWidth={content.contentWidth} contentHeight={content.contentHeight} /></div>
                                                                                ))} */}
                                                                            </Carousel>
                                                                        } else {
                                                                            // return <ShakaPlayerComponent name={this.state.myName} manifestURI={`${process.env.REACT_APP_API_URL}files/${post.content[0].url}`} type={post.content[0].type} contentID={post.content[0].id} ogname={post.content[0].filename} contentWidth={post.content[0].contentWidth} contentHeight={post.content[0].contentHeight} />
                                                                        }
                                                                    }   
                                                                })()}
                                                            </div>
                                                        </div>
                                                    } else {
                                                        return <div className="flex">
                                                            {/* Unlock content */}
                                                            <div className="flex grid grid-rows-6 bg-gray-800 w-full h-96 rounded-md flex items-center justify-center">
                                                                <div></div> 
                                                                <div className="text-white text-2xl row-span-4">Please purchase to unlock this post!</div>
                                                                {/* <button onClick={(e) => this.buy(post.postID, e.target, post.price, post)} id={`buy-${post.postID}`} className="flex bg-gray-900 text-white rounded-md px-2 py-1 justify-center hover:shadow-md hover:-translate-y-1 transition ease-in-out">Pay - ${(post.price + (post.price * 0.15)).toFixed(2)} USD</button> */}
                                                            </div>
                                                        </div>
                                                    }
                                                })()}
                                            </div>
                        
                                            <div id="postControls">
                                                <div className="flex gap-4 px-2 pt-4 text-lg">
                                                    <span className="text-white">
                                                        {(() => {
                                                            if (post.access === true) {
                                                                if (post.isPostLiked) {
                                                                    return <>
                                                                        <i className="fa-solid fa-heart text-red-500 hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                        <span className="ml-2">{formatLikes(post.likes)}</span>
                                                                        <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.comments)}>
                                                                            <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                            <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                        </span>
                                                                    </>
                                                                } else {
                                                                    return <>
                                                                        <i className="fa-regular fa-heart hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                        <span className="ml-2">{formatLikes(post.likes)}</span>
                                                                        <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.comments)}>
                                                                            <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                            <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments.length)}</span>
                                                                        </span>
                                                                    </>
                                                                }
                                                            } else {
                                                                return <span className="text-white hover:cursor-pointer" onClick={() => this.openCommentModal(post.comments)}>
                                                                    <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                    <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                </span>
                                                            }
                                                        })()}
                                                    </span>
                                                    {/* <span className="text-white hover:cursor-pointer" onClick={() => this.openCommentModal()}>
                                                        <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                        <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                    </span> */}
                                                </div>
                                            </div>

                                            {/* {(() => {
                                                if (post.isCommentsEnabled === true) {
                                                    return <Comments enabled={true} commentData={[]} />
                                                }
                                            })()} */}
                        
                                            {/* {(() => {
                                                if (index === 1) {
                                                    return <Comments enabled={true} commentData={[]} />
                                                }
                                            })()} */}
                                        </div>
                                    ))
                                }
                            })()}
                    </InfiniteScroll>
                }
            })()}
            {(() => {
                if (this.state.isSelf) {
                    return <Transition appear show={this.state.profileModalShown}>
                        <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeProfileModal()}>
                            <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                            <DialogTitle as="h3" className="text-base/7 font-bold text-white">
                                                Set Display Name / Username
                                            </DialogTitle>
                                            <div className="flex grid grid-rows mt-4">
                                                {(() => {
                                                    if (this.state.user) {
                                                        return <>
                                                            <h1 className="text-white text-sm/6 font-bold">Display Name</h1>
                                                            {/* @ts-ignore */}
                                                            <input ref={this.displayNameInput} className="px-2 py-1 rounded-md bg-gray-900 mt-2 text-white" type="text" name="displayName" id="displayName" defaultValue={this.state.user.displayName} placeholder="Display Name ..." />
                                                        </>
                                                    }
                                                })()}
                                            </div>
                                            {/* <p className="mt-2 text-sm/6 text-white/50">
                                                Your payment has been successfully submitted. We've sent you an email with all of the details of
                                                your order.
                                            </p> */}
                                            <div className="mt-6">
                                                <div className="flex grid grid-cols-2 gap-2">
                                                    <button className="px-2 py-1 bg-gray-700 rounded-md text-white" onClick={() => this.closeProfileModal()}>Cancel</button>
                                                    <button className="px-2 py-1 bg-red-600 rounded-md text-white"
                                                        onClick={() => this.updateProfile()}
                                                    >
                                                        {(() => {
                                                            if (this.state.profileUploading) {
                                                                return <span className="inline-flex"> <MoonLoader color="#ffffff" size={14} className="mt-[2px]" /> <span className="pl-2">Loading</span></span>;
                                                            } else {
                                                                return "Save Changes";
                                                            }
                                                        })()}
                                                    </button>
                                                    {/* <button
                                                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                                        onClick={() => this.updateProfile()}
                                                    >
                                                        {(() => {
                                                            if (this.state.profileUploading) {
                                                                return <span className="inline-flex"> <MoonLoader color="#ffffff" size={14} className="mt-[2px]" /> <span className="pl-2">Loading</span></span>;
                                                            } else {
                                                                return "Save Changes";
                                                            }
                                                        })()}
                                                    </button> */}
                                                </div>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                }
            })()}

            {/* Comment Modal... */}
            {/* <Transition appear show={true}> */}
            <Transition appear show={this.state.commentModal}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeCommentModal()}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Comments
                                    </DialogTitle>
                                    <div className="flex grid grid-rows mt-6 text-white gap-2 max-h-96 overflow-auto">
                                        {(() => {
                                            return this.state.comments.map((comment, index) => (
                                                <div className="flex items-center gap-4" key={index}>
                                                    {(() => {
                                                        if (comment.profilePic === null || comment.profilePic === undefined) {
                                                            return <span className="min-h-10 min-w-10 rounded-full bg-red-600"></span>
                                                        } else {
                                                            return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${comment.username}/profilePic`} className="min-h-10 min-w-10 rounded-full" />
                                                        }
                                                    })()}
                                                    <div>
                                                        <h3 className="text-white font-bold">{comment.displayName}</h3>
                                                        <p className="text-white">{comment.comment}</p>
                                                    </div>
                                                </div>
                                            ))
                                        })()}
                                        {/* <div className="flex items-center gap-4">
                                            <span className="min-h-10 min-w-10 rounded-full bg-red-600"></span>
                                            <div>
                                                <h3 className="text-white font-bold">John Doe</h3>
                                                <p className="text-white">This is a comment</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <span className="min-h-10 min-w-10 rounded-full bg-red-600"></span>
                                            <div>
                                                <h3 className="text-white font-bold">John Doe</h3>
                                                <p className="text-white">This is a comment</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <span className="min-h-10 min-w-10 rounded-full bg-red-600"></span>
                                            <div>
                                                <h3 className="text-white font-bold">John Doe</h3>
                                                <p className="text-white">This is a comment</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div>
                                        <form onSubmit={((e) => this.postComment(e))} className="flex grid grid-cols-4 gap-2">
                                            <input onChange={(e) => this.setState({ commentVal: e.target.value})} type="text" className="col-span-3 w-full bg-gray-700 rounded-md px-2 py-1 mt-4 text-white" placeholder="Write a comment ..." />
                                            <button className="px-2 py-1 text-white bg-red-500 rounded-md mt-4">Send</button>
                                        </form>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    }
}