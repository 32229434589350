import Header from '../../components/Header';
import Profile from "../../components/Profile";
import UpcomingLives from "../../components/UpcomingLives";
import Footer from '../../components/Footer';
import SettingsSidebar from '../../components/SettingsSidebar';
import React, { useEffect } from 'react';
import axios from 'axios';
import { confirm } from 'dropzone';
import clerk from '../../utils/clerk';
import { UserProfile, UserButton  } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';

export default function Account() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [user, setUser] = React.useState({});
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const changeVal = (val: string, type: string) => {
        if (type === 'password') {
            setPassword(val);
            
            var msg = document.getElementById('nomatch');
            if (confirmPassword !== val) {
                msg?.classList.remove('hidden');
            } else {
                msg?.classList.add('hidden');
            }
        } else if (type === 'confirm') {
            setConfirmPassword(val);

            var msg = document.getElementById('nomatch');
            console.log({password, confirmPassword});
            if (password !== val) {
                msg?.classList.remove('hidden');
            } else {
                msg?.classList.add('hidden');
            }
        }
    }

    // useEffect(() => {
    //     // Check User Token
    //     var token = localStorage.getItem('token');
    //     if (token) {
    //         // Axios request to backend ...
    //         axios.get(`${process.env.REACT_APP_API_URL}api/v1/user`, { headers: { 'x-auth-token': token } }).then((res: any) => {
    //             console.log(res.data);
    //             setUser(res.data);
    //         }).catch((err: any) => {
    //             // if (err.response.data.msg === 'Not Verified') {
    //             //     // Redirect to login
    //             //     localStorage.removeItem('token');
    //             //     window.location.href = '/login';
    //             // }
    //             console.error(err);
    //             localStorage.removeItem('token');
    //             window.location.href = '/login';
    //         });
    //     } else {
    //         // Redirect to login
    //         window.location.href = '/login';
    //     }
    // }, []);

    // Constantly check to see if profile option exists
    setInterval(() => {
        var p = document.getElementsByClassName('cl-profileSection__profile')[0];

        if (p) {
            p.classList.add('hidden');
        }
    }, 200);

    setTimeout(() => {
        var clRootBox = document.getElementsByClassName('cl-rootBox')[0];
        var clcardBox = document.getElementsByClassName('cl-cardBox')[0];

        if (clRootBox && clcardBox) {
            clRootBox.setAttribute('style', 'width: 100% !important');
            clcardBox.setAttribute('style', 'width: 100% !important');
        }
    }, 200)

    return <div>
        <Header />
        <div className="flex grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <div className="mt-4 col-span-3 ml-4 pr-6 w-full">
                <UserProfile
                        appearance={{
                            baseTheme: dark
                        }}
                    >
                    {/* <UserButton.UserProfilePage label="Notifications" labelIcon={<i className="fa-solid fa-bell"></i>} url="notifications">
                        <div>
                            <div className="mb-4">
                                <h1 className="text-md font-bold">Notifications</h1>
                            </div>
                            <div className="py-5 text-sm" style={{borderTop: '1px solid rgba(255, 255, 255, 0.07)'}}>
                                <div className="mt-2">
                                    <input type="checkbox" className="mr-2" id="posts" defaultChecked />
                                    <label htmlFor="posts">Receive post notifications</label>
                                </div>
                                <div className="mt-2">
                                    <input type="checkbox" className="mr-2" id="story" defaultChecked />
                                    <label htmlFor="story">Receive message notifications</label>
                                </div>
                                <div className="mt-2">
                                    <input type="checkbox" className="mr-2" id="live" defaultChecked />
                                    <label htmlFor="live">Receive livestream notifications</label>
                                </div>
                                <div className="mt-4">
                                    <button className="px-2 py-1 rounded-md w-full bg-red-600">Save</button>
                                </div>
                            </div>
                        </div>
                    </UserButton.UserProfilePage> */}
                </UserProfile>
            </div>
            {/* <SettingsSidebar page="Account" />
            <div className="col-span-2 mt-4">
                <h1 className="text-2xl font-bold">Settings</h1>
                <div className="px-4 py-1 rounded-md mt-4">
                    <UserProfile
                            appearance={{
                                baseTheme: dark
                            }}
                        >
                        <UserButton.UserProfilePage label="Notifications" labelIcon={<DotIcon />} url="notifications">
                            <div>
                                <div className="mb-4">
                                    <h1 className="text-md font-bold">Notifications</h1>
                                </div>
                                <div className="my-1" style={{borderTop: '1px solid rgba(255, 255, 255, 0.07)'}}>
                                    <p>Custom Notifications page</p>
                                </div>
                            </div>
                        </UserButton.UserProfilePage>
                    </UserProfile>
                </div>
            </div> */}
            <div className="hidden lg:block xl:block 2xl:block">
                <Profile />
                <UpcomingLives />
            </div>
        </div>
        <Footer />
    </div>
}