import Header from '../../components/Header';
import Profile from "../../components/Profile";
import UpcomingLives from "../../components/UpcomingLives";
import Footer from '../../components/Footer';
import SettingsSidebar from '../../components/SettingsSidebar';
import TwoStepComponent from '../../components/profile/TwoStepComponent';

export default function Security() {
    return <div>
        <Header />
        <div className="flex grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <SettingsSidebar page="Security & Privacy" />
            <div className="col-span-2 mx-4 mt-4">
                <h1 className="text-2xl font-bold">Security & Privacy</h1>
                <div className="bg-gray-700 px-4 py-2 rounded-md mt-4 pb-8">
                    <h1 className="text-xl font-bold pt-4">2 Factor Authentication</h1>
                    <TwoStepComponent />
                </div>
            </div>
            <div className="hidden lg:block xl:block 2xl:block">
                <Profile />
                <UpcomingLives />
            </div>
        </div>
        <Footer />
    </div>
}