import React from 'react';
import axios from 'axios';
import clerk from '../utils/clerk';
import ProfilePic from '../ProfilePicTemp.png';

interface creator {
    username: string;
    displayName: string;
    avatar: string;
}

type props = {};
type state = {
    creators: creator[];
};

class ExploreComponent extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            creators: []
        }
    }

    componentDidMount() {
        // Make request to API to fetch creators
        clerk.session?.getToken().then(async (token: any) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/explore`, { headers: { 'x-auth-token': token } }).then((res: any) => {
                console.log(res.data);
                this.setState({ creators: res.data.creators});
            })
        });
    }

    render() {
        return (
            <div className="bg-gray-800 flex w-full h-full my-2 p-2 rounded-md">
                {this.state.creators.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
                        {this.state.creators.map((creator: any) => (
                            <div key={creator.username} className="bg-gray-700 p-4 rounded-md shadow-md hover:shadow-lg transition-all">
                                {(() => {
                                    if (creator.pic !== "") {
                                        return <img
                                            src={creator.pic}
                                            alt={creator.creatorDetails.displayName}
                                            className="min-h-16 min-w-16 object-cover rounded-full mb-4"
                                        />
                                    } else {
                                        return <img
                                            src={ProfilePic}
                                            alt={creator.creatorDetails.displayName}
                                            className="min-h-16 min-w-16 object-cover rounded-full mb-4"
                                        />
                                    }
                                })()}
                                <h3 className="text-xl font-semibold text-white">{creator.creatorDetails.displayName}</h3>
                                <button className="bg-red-600 text-white px-4 py-2 rounded-md mt-4 font-bold w-full">View Profile</button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex justify-center text-center text-gray-400 w-full">
                        <p>No creators found.</p>
                    </div>
                )}
            </div>
        );
    }
}

export default function Explore() {
    return <div className="col-span-2 mx-4 mt-4">
        <h1 className="text-2xl font-bold">Explore Creators</h1>
        <ExploreComponent />
    </div>
}