import { Clerk } from '@clerk/clerk-js';

const CLERKPUB = process.env.REACT_APP_CLERK;
const clerk = new Clerk(CLERKPUB || '');
clerk.load();

async function getExternalID() {
    var userID = await clerk.session?.user.externalId;
    return userID;
}

export default clerk;