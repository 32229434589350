import React from 'react';

type props = {
    page: string;
}

type state = {}

export default class Links extends React.PureComponent<props, state> {
    sections = [{
        name: 'Admin Section',
        links: [{
            name: 'Dashboard',
            isURL: false,
            path: `${process.env.REACT_APP_URL}app/admin`,
            section: 'Admin Section'
        }, {
            name: 'Creator Requests',
            isURL: false,
            path: `${process.env.REACT_APP_URL}app/admin/requests`,
            section: 'Admin Section'
        }, {
            name: 'User Reports',
            isURL: false,
            path: `${process.env.REACT_APP_URL}app/admin/reports`,
            section: 'Admin Section'
        }, {
            name: 'User Management',
            isURL: false,
            path: `${process.env.REACT_APP_URL}app/admin/users`,
            section: 'Admin Section'
        }]
    }, 
    // {
    //     name: 'Billing Settings',
    //     links: [{
    //         name: 'Billing',
    //         isURL: false,
    //         path: `${process.env.REACT_APP_URL}settings/billing`,
    //         section: 'Billing Settings'
    //     }]
    // }, {
    //     name: 'App Preferences',
    //     links: [{
    //         name: 'Voice & Audio',
    //         isURL: false,
    //         path: `${process.env.REACT_APP_URL}settings/voice`,
    //         section: 'App Preferences'
    //     }, {
    //         name: 'Notifications',
    //         isURL: false,
    //         path: `${process.env.REACT_APP_URL}settings/notifications`,
    //         section: 'App Preferences'
    //     }]
    // }, {
    //     name: 'Support',
    //     links: [{
    //         name: 'Support',
    //         isURL: true,
    //         path: 'https://support.guardedcontent.com/', // url
    //         section: 'Support'
    //     }, {
    //         name: 'Changelog',
    //         isURL: true,
    //         path: 'https://guardedcontent.com/changelog', //url
    //         section: 'Support'
    //     }]
    // }
    ]

    render() {
        return <div className="mx-4 mr-48 mt-4">
            {this.sections.map((section, index) => {
                return <div className="mt-4">
                    <h1 className="text-lg font-bold text-gray-500 uppercase px-4">{section.name}</h1>
                    <ul className="flex grid grid-rows gap-2 mt-2">
                        {section.links.map((link) => {
                            if (this.props.page === link.name) {
                                return <li className="font-bold bg-gray-800 rounded-md px-4 py-2">
                                    {link.isURL ? <a href={link.path} className="block text-gray-200">{link.name}</a> : <a href={link.path} className="block text-gray-200">{link.name}</a>}
                                </li>
                            } else {
                                return <li className="rounded-md px-4 py-2">
                                    {link.isURL ? <a href={link.path} className="block text-gray-200">{link.name}</a> : <a href={link.path} className="block text-gray-200">{link.name}</a>}
                                </li>
                            }
                        })}
                    </ul>
                    {index !== this.sections.length - 1 ? <hr className="mt-2" /> : null}
                </div>
            })}
        </div>
    }
}