import React from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ProfileFeed from './profile/ProfileFeed';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShakaPlayerComponent from './ShakaPlayerComponent';
import ProfilePic from '../ProfilePicTemp.png';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import MoonLoader from 'react-spinners/MoonLoader';
import Cards from 'react-credit-cards-2';
import "react-credit-cards-2/dist/es/styles-compiled.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import { CreditCard, PaymentForm, CashAppPay, GooglePay } from 'react-square-web-payments-sdk';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import clerk from '../utils/clerk';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
    isCardExpiryValid,
    isCardCVCValid,
    isCardNumberValid,
} from '../utils/cardUtils';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';

type props = {
    postID: string | null;
};

type state = {
    loading: boolean;
    subscriptions: any[];
    user: any[];
    username: string;
    following: boolean;
    followers: number;
    isSelf: boolean;
    items: any[];
    pageNum: number;
    hasMore: boolean;
    followingUser: boolean;
    bannerURL: string;
    verified: boolean;
    profileModalShown: boolean;
    profileUploading: boolean;
    purchaseShow: boolean;
    purchaseData: post | null;
    number: string;
    expiry: string;
    name: string;
    cardFocused: string;
    ccv: string;
    isPaymentActive: boolean;
    purchaseID: string;
    myName: string;
    fname: string;
    lname: string;
    email: string;
    commentModal: boolean;
    comments: comment[] | null;
    activePostID: string | null;
    commentData: string | null;
    isLive: boolean;
    fromPostID: string | null;
    creatorSubPrice: number;
    followModal: boolean;
    planID: string;
    twoFacSuccess: boolean;
    totpEnabled: boolean;
    userSuspended: boolean;
}

type comment = {
    data: string;
    createdAt: string;
    username: string;
    profilePic: string;
    postID: string;
}

type post = {
    access: boolean;
    createdAt: string;
    description: string;
    followers: number;
    isPostLiked: boolean;
    likes: number;
    postID: string;
    price: number;
    profilePic: {
        profilePic: string;
        _id: string;
    };
    status: string;
    title: string;
    username: string;
}

export default class UserComponent extends React.PureComponent<props, state> {
    private usernameInput = React.createRef<HTMLInputElement>();
    private displayNameInput = React.createRef<HTMLInputElement>();
    private closeModalBTN = React.createRef<HTMLButtonElement>();
    private payBTN = React.createRef<HTMLButtonElement>();
    private commentInput = React.createRef<HTMLInputElement>();
    private AuthInputRef = React.createRef<AuthCodeRef>();
    
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            subscriptions: [],
            user: [],
            username: '',
            following: false,
            followers: 0,
            isSelf: false,
            items: [],
            pageNum: 0,
            hasMore: true,
            followingUser: false,
            verified: false,
            bannerURL: '',
            profileModalShown: false,
            profileUploading: false,
            purchaseShow: false,
            purchaseData: null,
            number: '4243 1111 1111 1111',
            expiry: '01/26',
            ccv: '221',
            name: 'Demo Name',
            cardFocused: '',
            isPaymentActive: false,
            purchaseID: '',
            myName: '',
            fname: '',
            lname: '',
            email: '',
            commentModal: false,
            comments: null,
            activePostID: null,
            commentData: null,
            isLive: false,
            fromPostID: null,
            creatorSubPrice: 0.00,
            followModal: false,
            planID: '',
            twoFacSuccess: false,
            totpEnabled: false,
            userSuspended: false
        }
        this.toggleFollow = this.toggleFollow.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);
        this.openCommentModal = this.openCommentModal.bind(this);
        this.checkoutSession = this.checkoutSession.bind(this);
        this.loadStripeComponent = this.loadStripeComponent.bind(this);
        this.authCodeInput = this.authCodeInput.bind(this);
        this.AuthInputRef = React.createRef();
        // this.commentInput = this.commentInput.bind(this);
    }

    componentDidMount(): void {
        var username = window.location.pathname.split('/')[3];

        const script = document.createElement('script');
        script.src = '/jsencrypt.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Get PostID from query params in URL
        var postID = new URLSearchParams(window.location.search).get('postID');
        console.log(postID);

        // @ts-ignore
        // console.log(navigator.connection.type);

        // this.setState({username: username});
        // var token = localStorage.getItem('token');
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                // Axios request to backend ...
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}`, { headers: { 'x-auth-token': token } }).then((res: any) => {
                    // console.log(res.data.user);
                    if (res.data.user === null) {
                        // Redirect to 404
                        window.location.href = '/404';
                    } else {
                        console.log(res.data.creatorSubPrice);
                        if (res.data.creatorSubPrice.length === 0) {
                            this.setState({ myName: res.data.self.displayName, user: res.data.user, username: username, loading: false, following: res.data.following, isSelf: res.data.isUser, bannerURL: res.data.bannerPic, followers: res.data.followers, isLive: res.data.user.isLive, creatorSubPrice: 0.00 });
                        } else {
                            this.setState({ myName: res.data.self.displayName, user: res.data.user, username: username, loading: false, following: res.data.following, isSelf: res.data.isUser, bannerURL: res.data.bannerPic, followers: res.data.followers, isLive: res.data.user.isLive, creatorSubPrice: res.data.creatorSubPrice[0].price, planID: res.data.creatorSubPrice[0]._id });
                        }
                    }
                }).catch(err => {
                    if (err.response.data.msg === 'Not Verified') {
                        // Redirect to login
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    } else if (err.response.data.msg === 'User not found') {
                        window.location.href = '/404';
                    }
                    console.error(err);
                });

                if (postID !== null) {
                    this.setState({ fromPostID: postID });

                    axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}/feed?fromPost=${postID}`, { headers: { 'x-auth-token': token} }).then((res) => {
                        // console.log(res.data);
                        this.setState({ items: res.data.posts, pageNum: 1, followingUser: true });
                    }).catch((err) => {
                        console.log(err);
                        if (err.response.data.msg === 'User not following') {
                            this.setState({followingUser: false})
                        } else if (err.response.data.msg === 'No posts found') {
                            this.setState({ hasMore: false, followingUser: true });
                        } else if (err.response.data.msg === 'Not Verified') {
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        } else if (err.response.data.error === 'Missing Auth Token') {
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        }
                    });
                } else {
                    axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${username}/feed`, { headers: { 'x-auth-token': token} }).then((res) => {
                        // console.log(res.data);
                        this.setState({ items: res.data.posts, pageNum: 1, followingUser: true });
                    }).catch((err) => {
                        console.log(err);
                        if (err.response.data.msg === 'User not following') {
                            this.setState({followingUser: false})
                        } else if (err.response.data.msg === 'No posts found') {
                            this.setState({ hasMore: false, followingUser: true });
                        } else if (err.response.data.msg === 'Not Verified') {
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        } else if (err.response.data.error === 'Missing Auth Token') {
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        }
                    });
                }

                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/email`, null, { headers: { 'x-auth-token': token } }).then((res) => {
                    this.setState({ email: res.data.email });
                }).catch(err => {
                    console.log(err);
                })
            });
        } else {
            // Redirect to login
            window.location.href = '/login';
        }
    }

    async authCodeInput(e: any) {
        // console.log(e.length);
        if (e.length === 6) {
            // Submit API request to verify code to CLERK
            clerk.session?.getToken().then(async (token) => {
                await toast.promise(
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/2fa`, { code: e }, { headers: { 'x-auth-token': token } }).then((res) => {
                        if (res.data.msg === 'Code Verified') {
                            this.setState({ twoFacSuccess: true });
                        }
                    }), {
                        loading: 'Verifying ...',
                        success: 'Code Verified!',
                        error: 'Invalid Code!',
                    }, {
                        style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }
                    }
                )
            });
        }
    }

    toggleFollow() {
        // var token = localStorage.getItem('token');
        clerk.session?.getToken().then(async (token) => {
            axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/follow`, { username: this.state.username }, { headers: { 'x-auth-token': token } }).then((res) => {
                // console.log(res.data);
                if (res.data.msg === 'Followed') {
                    // Should somehow update subscriptions list ... --- Sockets???
                    // Update Feed by executing Fetch Request...
                    this.setState({ following: true });
                } else {
                    // Should somehow update subscriptions list ... --- Sockets???
                    this.setState({ following: false });
                }
            }).catch(err => {
                // Use Toast Error System
                // console.error(err);
                if (err.response.data.msg === 'Not Verified') {
                    // Redirect to login
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    toast.error('An error has occurred!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }
            });
        });
    }

    followUser() {
        // this.setState({ followModal: true });

        // Get checkout session ...
        clerk.session?.getToken().then(async (token) => {
            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/totp`, { headers: { 'x-auth-token': token }}).then((res) => {
                console.log(res.data);
                this.setState({ followModal: true, totpEnabled: res.data.totp, twoFacSuccess: res.data.twoFacSuccess, userSuspended: res.data.userSuspended });
            })
        });

    }

    unfollowUser() {
        // Open Cancel Session
        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/stripe/customerPortal`, { headers: { 'x-auth-token': token } }).then(res => {
                window.open(res.data.url, '_blank');
            });
        });
    }

    async fetchData() {
        // console.log(this.state.pageNum);
        var pageNum = this.state.pageNum;
        pageNum++;
        // var token = localStorage.getItem('token');
        // console.log(pageNum);
        clerk.session?.getToken().then(async (token) => {
            if (this.state.fromPostID !== null) {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.username}/feed?page=${pageNum}&fromPost=${this.state.fromPostID}`, { headers: { 'x-auth-token': token} }).then((res) => {
                    // console.log(res.data);
                    var stateItems = this.state.items;
                    // Add to stateItems
                    res.data.posts.forEach((post: any) => {
                        stateItems.push(post);
                    });
    
                    this.setState({ items: stateItems, pageNum: pageNum, followingUser: true, verified: res.data.verified });
                }).catch((err) => {
                    console.log(err);
                    if (err.response.data.msg === 'User not following') {
                        this.setState({followingUser: false})
                    } else if (err.response.data.msg === 'No posts found') {
                        this.setState({ hasMore: false });
                    } else if (err.response.data.msg === 'Not Verified') {
                        localStorage.removeItem('token');
                        window.location.href = '../login';
                    } else if (err.response.data.error === 'Missing Auth Token') {
                        localStorage.removeItem('token');
                        window.location.href = '../login';
                    }
                });
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.username}/feed?page=${pageNum}`, { headers: { 'x-auth-token': token} }).then((res) => {
                    // console.log(res.data);
                    var stateItems = this.state.items;
                    // Add to stateItems
                    res.data.posts.forEach((post: any) => {
                        stateItems.push(post);
                    });
    
                    this.setState({ items: stateItems, pageNum: pageNum, followingUser: true, verified: res.data.verified });
                }).catch((err) => {
                    console.log(err);
                    if (err.response.data.msg === 'User not following') {
                        this.setState({followingUser: false})
                    } else if (err.response.data.msg === 'No posts found') {
                        this.setState({ hasMore: false });
                    } else if (err.response.data.msg === 'Not Verified') {
                        localStorage.removeItem('token');
                        window.location.href = '../login';
                    } else if (err.response.data.error === 'Missing Auth Token') {
                        localStorage.removeItem('token');
                        window.location.href = '../login';
                    }
                });
            }
        });
    }

    toggleLike(e: any, postID: any) {
        // e.target.classList.toggle('text-red-500');
        // e.target.classList.toggle('fa-heart');

        // Make API request 
        // var token = localStorage.getItem('token');
        clerk.session?.getToken().then(async (token) => {
            axios.put(`${process.env.REACT_APP_API_URL}api/v1/post/${postID}/like`, null, { headers: { 'x-auth-token': token } }).then((res) => {
                // Update selected `post` in state to avoid updating all posts ...
                // this.setState({})
                var updatedItems = this.state.items.map((post) => {
                    if (post.postID === postID) {
                        post.likes = res.data.likes;
                        post.isPostLiked = !post.isPostLiked;
                    }
                    return post;
                });
                this.setState({ items: updatedItems });
            }).catch((err) => {
                console.error(err);
                if (err.response.data.msg === 'Not Verified') {
                    localStorage.removeItem('token');
                    window.location.href = '../login';
                } else if (err.response.data.error === 'Missing Auth Token') {
                    localStorage.removeItem('token');
                    window.location.href = '../login';
                } else {
                    // e.target.classList.toggle('text-red-500');
                    // e.target.classList.toggle('fa-heart');
                    // Toast like error to user
                    toast.error('An error has occurred!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }
            });
        });
    }

    responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    uploadBanner() {
        var fileInput = document.getElementById('profileBanner');
        if (fileInput) {
            fileInput.click();
            fileInput.addEventListener('change', (e: any) => {
                // console.log(e.target.files[0]);
                var file = e.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                // var token = localStorage.getItem('token');
                clerk.session?.getToken().then(async (token) => {
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/banner`, formData, { headers: { 'x-auth-token': token } }).then((res) => {
                        // console.log(res.data);
                        if (res.data.msg === 'Banner Updated') {
                            // this.setState({ user: res.data.user });
                            window.location.reload();
                        } else {
                            toast.error('An error has occurred!', { style: {
                                color: '#ffffff',
                                backgroundColor: '#242C37',
                            }});
                        }
                    }).catch(err => {
                        console.error(err);
                    });
                });
            });
        }
    }

    uploadProfilePic() {
        var fileInput = document.getElementById('profilePicture');
        console.log(fileInput)
        if (fileInput) {
            fileInput.click();
            fileInput.addEventListener('change', (e: any) => {
                // console.log(e.target.files[0]);
                var file = e.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                // var token = localStorage.getItem('token');
                clerk.session?.getToken().then(async (token) => {
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profilepic`, formData, { headers: { 'x-auth-token': token } }).then((res) => {
                        // console.log(res.data);
                        // this.setState({ user: res.data.user });
                        window.location.reload();
                    }).catch(err => {
                        console.error(err);
                    });
                });
            });
        }
    }

    closeProfileModal() {
        this.setState({ profileModalShown: false });
    }

    openEditModal() {
        this.setState({ profileModalShown: true });
    }

    async updateProfile() {
        // var token = localStorage.getItem('token');
        var displayName = this.displayNameInput.current?.value;
        var username = this.usernameInput.current?.value;

        clerk.session?.getToken().then(async (token) => {
            await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/profile`, { displayName: displayName, username: username }, { headers: { 'x-auth-token': token } }).then((res) => {
                // console.log(res.data);
                this.setState({ user: res.data.user });
                this.closeProfileModal();
                toast.success('Profile Updated!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
                window.location.href = `/app/user/${username}`;
            }).catch(err => {
                console.error(err);
                if (err.response.data.msg === 'Not Verified') {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else if (err.response.data.msg === 'No changes made') {
                    toast.error('No changes made!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                } else {
                    toast.error('An error has occurred!', { style: {
                        color: '#ffffff',
                        backgroundColor: '#242C37',
                    }});
                }
            });
        });
    }

    buy(postID: string, button: any, price: number, post: post) {
        button.innerHTML = `Purchasing ...`;

        clerk.session?.getToken().then(async (token) => {
            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/totp`, { headers: { 'x-auth-token': token }}).then((res) => {
                console.log(res.data);
                this.setState({ purchaseShow: true, purchaseData: post, purchaseID: post.postID, totpEnabled: res.data.totp, twoFacSuccess: res.data.twoFacSuccess, userSuspended: res.data.userSuspended });
            })
        });

        // this.setState({ purchaseShow: true, purchaseData: post, purchaseID: post.postID });

        // console.log(post);

        // Trigger Modal Open ... 


        // axios.post(`${process.env.REACT_APP_API_URL}api/v1/post/${postID}/buy`, null, { headers: { 'x-auth-token': token } }).then((res) => {
        //     toast.success('Post Purchased!', { style: {
        //         color: '#ffffff',
        //         backgroundColor: '#242C37',
        //     }});

        //     setTimeout(() => {
        //         button.innerHTML = `Purchased!`;
        //         window.location.reload();
        //     }, 2000)
        // }).catch(err => {
        //     toast.error(`An error has occurred! ${err.response.data.msg}`, { style: {
        //         color: '#ffffff',
        //         backgroundColor: '#242C37',
        //     }});
        // });
    }   

    inputChange(e: any) {
        // console.log(e);
        if (e.target.name === 'number') {
            this.setState({ number: formatCreditCardNumber(e.target.value) });
            e.target.value = formatCreditCardNumber(e.target.value);
            if (!isCardNumberValid(e.target.value)) {
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'expiry') {
            this.setState({ expiry: formatExpirationDate(e.target.value) });
            e.target.value = formatExpirationDate(e.target.value);
            if (!isCardExpiryValid(e.target.value)) {
                // e.target.style.color = 'red';
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'name') {
            this.setState({ name: e.target.value });
            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        } else if (e.target.name === 'cvc') {
            // @ts-ignore
            this.setState({ ccv: formatCVC(e.target.value) });
            // @ts-ignore
            e.target.value = formatCVC(e.target.value);
            if (!isCardCVCValid(e.target.value)) {
                e.target.classList.add('ring-2');
                e.target.classList.add('ring-red-500');
            } else {
                e.target.classList.remove('ring-2');
                e.target.classList.remove('ring-red-500');
            }

            if (this.state.number === null || this.state.expiry === null || this.state.name === null || this.state.ccv === null) {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = true;
                }
            } else {
                if (this.payBTN.current !== null) {
                    this.payBTN.current.disabled = false;
                }
            }
        }

    }

    closePaymentModal() {
        if (this.state.isPaymentActive === true) {
            return; // Do not close modal if payment is active
        } else {
            var paymentID = this.state.purchaseID;
            var elem = document.getElementById(`buy-${paymentID}`);
            if (elem) {
                // Gotta get payamount.
                if (this.state.purchaseData !== null) {
                    var amount = this.state.purchaseData?.price + (this.state.purchaseData?.price * 0.15);
                    elem.innerHTML = `Pay - $${amount?.toFixed(2)} USD`;
                }
            }
            // Close modal
            this.setState({ purchaseShow: false, purchaseID: '', cardFocused: '' });
        }
    }

    closeCommentModal() {
        this.setState({ commentModal: false, activePostID: null, commentData: null });
    }

    openCommentModal(postID: string) {
        // Fetch comments for post ... 
        clerk.session?.getToken().then(async (token) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/v1/comments/${postID}`, { headers: { 'x-auth-token': token } }).then((res) => {
                console.log(res.data);
                this.setState({ comments: res.data.comments });
            }).catch(err => {
                console.log(err);
            });
        });


        this.setState({ commentModal: true, activePostID: postID });
    }

    // setNewDisplayName(value: any) {
    //     // @ts-ignore
    //     this.setState({ user: { displayName: value } });
    // } 

    // setNewUsername(value: any) {
    //     // @ts-ignore
    //     this.setState({ user: { username: value } });
    // }

    loadStripeComponent() {
        // @ts-ignore
        return loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }

    checkoutSession(postID: any) {
        // console.log(postID);
        var secret = '';
        const fetchSession = async () => {
            const session = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/stripe/checkoutSession/${postID}`, {}, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                return res.data.session;
            }).catch(err => {
                console.log(err);
            });
            return session.client_secret;
        }

        return { clientSecret: secret, fetchClientSecret: fetchSession }
    }

    subscriptionSession(planID: any) {
        var secret = '';
        const fetchSession = async () => {
            const session = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/stripe/subscriptionCheckout/${planID}`, {}, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                return res.data.session;
            }).catch(err => {
                console.log(err);
            });
            return session.client_secret;
        }

        return { clientSecret: secret, fetchClientSecret: fetchSession }
    }

    submitComment(e: any) {
        e.preventDefault();

        if (this.state.activePostID === null) {
            return;
        } else {
            var comment = this.state.commentData;
            if (comment === null) {
                // Toast Error
                toast.error('Comment cannot be empty!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                // Submit api request ...
                clerk.session?.getToken().then(async (token) => {
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/comments/${this.state.activePostID}`, { comment: comment }, { headers: { 'x-auth-token': token }}).then((res) => {
                        // Handle comment submission response ...
                        if (res.data.msg === 'Comment Added') {
                            if(this.commentInput.current !== null) {
                                this.commentInput.current.value = '';
                            }
                            this.setState(prevState => ({ comments: [res.data.comment, ...(prevState.comments || [])] }));
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                });
            }
        }
    }

    render() {
        function formatFollowers(followers: number) {
            if (followers > 999 && followers < 1000000) {
                return (followers / 1000).toFixed(1) + 'K Followers';
            } else if (followers > 999999) {
                return (followers / 1000000).toFixed(1) + 'M Followers';
            } else if (followers === 1) {
                return followers + ' Follower';
            } else {
                return followers + ' Followers';
            }
        }

        function formatLikes(likes: number) {
            if (likes > 999 && likes < 1000000) {
                return (likes / 1000).toFixed(1) + 'K Likes';
            } else if (likes > 999999) {
                return (likes / 1000000).toFixed(1) + 'M Likes';
            } else if (likes === 1) {
                return likes + ' Like';
            } else if (likes === 0) {
                return 'Like';
            } else {
                return likes + ' Likes';
            }
        }

        function formatComments(comments: number | undefined) {
            if (comments === undefined) {
                return '0 Comments';
            } else {
                if (comments > 999 && comments < 1000000) {
                    return (comments / 1000).toFixed(1) + 'K Comments';
                } else if (comments > 999999) {
                    return (comments / 1000000).toFixed(1) + 'M Comments';
                } else if (comments === 1) {
                    return comments + ' Comment';
                } else if (comments === 0) {
                    return '0 Comments';
                } else {
                    return comments + ' Comments';
                }
            }
        }

        function commentDateFormat(date: string) {
            // Given date, format to 'mins ago' 'days ago' 'weeks ago' 'years ago'
            var currentDate = new Date();
            var commentDate = new Date(date);
            var diff = currentDate.getTime() - commentDate.getTime();
            var seconds = diff / 1000;
            var minutes = seconds / 60;
            var hours = minutes / 60;

            if (seconds < 60) {
                return 'Just Now';
            } else if (minutes < 60) {
                return Math.floor(minutes) + 'm ago';
            } else if (hours < 24) {
                return Math.floor(hours) + 'h ago';
            } else if (hours < 48) {
                return 'Yesterday';
            } else if (hours < 168) {
                return Math.floor(hours / 24) + 'd ago';
            } else if (hours < 8760) {
                return Math.floor(hours / 168) + 'w ago';
            } else {
                return Math.floor(hours / 8760) + 'y ago';
            }
        }

        // const target = document.querySelectorAll('#post');

        // const observer = new IntersectionObserver((entries) => {
        //     entries.forEach(entry => {
        //         if (entry.isIntersecting) {
        //             // @ts-ignore
        //             const postID = entry.target.getAttribute('x-postID');
        //             // @ts-ignore
        //             const url = new URL(window.location);
        //             // @ts-ignore
        //             url.searchParams.set('postID', postID);
        //             window.history.pushState({}, '', url);
        //         }
        //     })
        // });

        // target.forEach(t => {
        //     console.log('d')
        //     observer.observe(t);
        // })

        return <div className="col-span-2 mx-4">
            <Toaster position="bottom-center" />
            {/* Mock profile page ... */}
            <div id="profile" className="bg-gray-700 w-full my-3 pb-4 rounded-md">
                {(() => {
                    if (this.state.isSelf) {
                        // @ts-ignore
                        if (this.state.user.bannerPic === null || !this.state.user.bannerPic || this.state.user.bannerPic === '') {
                            return <>
                                <input type="file" className="hidden" name="profileBanner" id="profileBanner" accept="image/png, image/jpeg" />
                                <div onClick={() => this.uploadBanner()} className="bg-red-600 h-60 w-full rounded-t-md hover:cursor-pointer"></div>
                            </>
                        } else {
                            return <>
                                <input type="file" className="hidden" name="profileBanner" id="profileBanner" accept="image/png, image/jpeg" />
                                {/* @ts-ignore */}
                                <img alt="Upload a banner" title="Upload a banner" src={this.state.user.bannerPic} onClick={() => this.uploadBanner()} className="w-full h-60 rounded-t-md object-cover hover:cursor-pointer" />
                            </>
                        }
                    } else {
                        // @ts-ignore
                        if (this.state.user.bannerPic=== null || !this.state.user.bannerPic || this.state.user.bannerPic === '') {
                            return <div className="bg-red-600 h-60 w-full rounded-t-md"></div>
                        } else {
                            // @ts-ignore
                            return <img src={this.state.user.bannerPic} className="w-full h-60 rounded-t-md object-cover" />
                        }
                    }
                })()}

                {/* <div className="bg-red-600 h-60 w-full rounded-t-md"></div> */}
                <div className="flex items-center h-full w-full px-4 pt-4">
                    {(() => {
                        if (this.state.isSelf) {
                            // @ts-ignore
                            if (this.state.user.profilePic === null || this.state.user.profilePic === undefined || !this.state.user.profilePic) {
                                if (this.state.isLive) {
                                    return <>
                                        <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                        <a href={window.location.href + '/live'}><img alt="Upload a banner" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={ProfilePic} className="h-14 w-14 rounded-full cursor-pointer ring-2 ring-red-500" /></a>
                                        {/* <div onClick={() => this.uploadProfilePic()} className="bg-red-600 h-14 w-14 rounded-full cursor-pointer"></div> */}
                                    </>
                                } else {
                                    return <>
                                        <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                        <img alt="Upload a banner" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={ProfilePic} className="h-14 w-14 rounded-full cursor-pointer" />
                                        {/* <div onClick={() => this.uploadProfilePic()} className="bg-red-600 h-14 w-14 rounded-full cursor-pointer"></div> */}
                                    </>
                                }
                                // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                            } else {
                                if (this.state.isLive) {
                                    return <>
                                        <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                        {/* @ts-ignore */}
                                        {/* <img alt="Upload a profile picture" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.user.username}/profilePic`} className="h-14 w-14 rounded-full cursor-pointer" /> */}
                                        {/* @ts-ignore */}
                                        <img alt="Upload a profile picture" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={this.state.user.profilePic} className="h-14 w-14 rounded-full cursor-pointer" />
                                    </>
                                } else {
                                    return <>
                                        <input type="file" className="hidden" name="profilePicture" id="profilePicture" accept="image/png, image/jpeg" />
                                        {/* @ts-ignore */}
                                        {/* <img alt="Upload a profile picture" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.user.username}/profilePic`} className="h-14 w-14 rounded-full cursor-pointer" /> */}
                                        {/* @ts-ignore */}
                                        <img alt="Upload a profile picture" title="Upload a profile picture" onClick={() => this.uploadProfilePic()} src={this.state.user.profilePic} className="h-14 w-14 rounded-full cursor-pointer" />
                                    </>
                                }
                            }
                        } else {
                            // @ts-ignore
                            if (this.state.user.profilePic === null || this.state.user.profilePic === undefined || !this.state.user.profilePic) {
                                // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                if (this.state.isLive && this.state.followingUser === true) {
                                    return <a href={window.location.href + '/live'}><img src={ProfilePic} className="5-14 w-14 rounded-full" /></a>
                                } else {
                                    return <img src={ProfilePic} className="5-14 w-14 rounded-full" />
                                }
                            } else {
                                // @ts-ignore
                                // return <img src={this.state.user.profilePic} className="h-14 w-14 rounded-full" />
                                // return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.user.username}/profilePic`} className="h-14 w-14 rounded-full" />
                                if (this.state.isLive && this.state.followingUser === true) {
                                    // @ts-ignore
                                    return <a href={window.location.href + '/live'}><img src={this.state.user.profilePic} className="h-14 w-14 rounded-full ring-2 ring-red-500" /></a>
                                } else {
                                    // @ts-ignore
                                    return <img src={this.state.user.profilePic} className="h-14 w-14 rounded-full" />
                                }
                            }
                        }
                    })()}
                    {/* <span className="h-14 w-14 rounded-full bg-red-600"></span> */}
                    {(() => {
                            if (this.state.isSelf) {
                                // @ts-ignore
                                if (this.state.user.verified) {
                                    return <div className="ml-4">
                                        {/* @ts-ignore */}
                                        <h3 onClick={(() => this.openEditModal())} className="text-white text-xl font-bold hover:cursor-pointer">
                                            <div className="inline-flex">
                                                <i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" />
                                                {/* @ts-ignore */}
                                                <div className="pl-2">{this.state.user.displayName}</div>
                                            </div>
                                        </h3>
                                        <p className="text-gray-300 text-base">{formatFollowers(this.state.followers)}</p>
                                        {/* <p onClick={(() => this.openEditModal())} className="text-gray-300 text-xl hover:cursor-pointer">@{this.state.username}</p> */}
                                    </div>
                                } else {
                                    {/* @ts-ignore */}
                                    return <div className="ml-4">
                                        {/* @ts-ignore */}
                                        <h3 onClick={(() => this.openEditModal())} className="text-white text-xl font-bold hover:cursor-pointer"> {this.state.user.displayName} <i className="fa-regular fa-pencil text-gray-300 px-1 text-sm" title="Edit" /></h3>
                                        <p  onClick={(() => this.openEditModal())} className="text-gray-300 text-xl hover:cursor-pointer">@{this.state.username}</p>
                                    </div>
                                }
                            } else {
                                // @ts-ignore
                                if (this.state.user.verified) {
                                    if (this.state.isLive && this.state.followingUser === true) {
                                        return <div className="ml-4">
                                            <h3 className="text-white text-xl font-bold">
                                            <i className="fa-solid fa-badge-check text-sky-500 pr-2" title="Verified Creator" />
                                            {/* @ts-ignore */}
                                            {this.state.user.displayName}
                                            <span className="bg-red-500 text-white px-2 py-1 ml-2 rounded-md text-sm animate-pulse">LIVE</span></h3>
                                            <p className="text-gray-300 text-xl">@{this.state.username}</p>
                                        </div>
                                    } else {
                                        return <div className="ml-4">
                                            <h3 className="text-white text-xl font-bold">
                                            <i className="fa-solid fa-badge-check text-sky-500 pr-2" title="Verified Creator" />
                                            {/* @ts-ignore */}
                                            {this.state.user.displayName}</h3>
                                            <p className="text-gray-300 text-xl">@{this.state.username}</p>
                                        </div>
                                    }
                                } else {
                                    {/* @ts-ignore */}
                                    return <div className="ml-4">
                                        {/* @ts-ignore */}
                                        <h3 className="text-white text-xl font-bold"> {this.state.user.displayName}</h3>
                                        <p className="text-gray-300 text-xl">@{this.state.username}</p>
                                    </div>
                                }
                            }
                        })()}
                    {/* <div className="ml-4">                        
                        <h3 className="text-white text-xl font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {this.state.user.displayName}</h3>
                        <p className="text-gray-300 text-xl">@{this.state.username}</p>
                    </div> */}
                    <div className="flex-grow"></div>
                    <div className="flex items-end">
                        {(() => {
                            if (this.state.isSelf) {
                                // return <Link to={`${process.env.REACT_APP_URL}manage/creator`} className="bg-white text-black text-base font-bold rounded-md px-4 py-1">Manage Creator Account</Link>
                                return <span onClick={(() => this.openEditModal())} className="bg-white text-black text-base font-bold rounded-md px-4 py-1 hover:cursor-pointer">Manage</span>
                            } else {
                                if (!this.state.following) {
                                    return <button onClick={() => this.followUser()} className="bg-red-600 text-white text-base font-bold rounded-md px-4 py-1">Follow ${this.state.creatorSubPrice}</button>
                                } else {
                                    return <button onClick={() => this.unfollowUser()} className="bg-white text-black text-base font-bold rounded-md px-4 py-1">Manage Subscription</button>
                                }
                            }
                        })()}
                    </div>
                </div>
            </div>
            {/* Display Profile Feed */}
            {(() => {
                if (this.state.loading === false && this.state.followingUser === false) {
                    return <div className="bg-gray-700 w-full my-4 px-4 py-4 rounded-md">
                        <h1 className="text-lg font-bold text-center">Follow this creator to see their posts!</h1>
                    </div>
                } else if (this.state.loading === false && this.state.items.length === 0) {
                    return <div className="bg-gray-700 w-full my-4 px-4 py-4 rounded-md">
                        <h1 className="text-lg font-bold text-center">No posts available! Check back later when this creator makes a post!</h1>
                    </div>
                } else if (this.state.loading === false && this.state.items.length !== 0) {
                    return <InfiniteScroll 
                        dataLength={this.state.items.length}
                        next={this.fetchData}
                        hasMore={this.state.hasMore}
                        loader={
                            <div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                                <div className="bg-gray-700 w-full my-4 rounded-md">
                                    <div className="flex animate-pulse items-center h-full px-4 py-4">
                                        <span className="min-h-10 min-w-10 rounded-full bg-slate-700"></span>
                                        <div className="ml-4 w-full">
                                            <div className="h-2 w-1/4 bg-slate-700 rounded"></div>
                                            <div className="h-2 w-1/12 mt-2 bg-slate-700 rounded"></div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse px-4 py-4">
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                        <div className="h-2 mt-2 w-full bg-slate-700 rounded"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        scrollableTarget="scrollableDiv"
                        >
                            {(() => {
                                if (Array.isArray(this.state.items)) {
                                    return this.state.items.map((post, index) => (
                                        <div id="post" x-postID={post.postID} className="bg-gray-700 w-full my-4 rounded-md py-4 px-4">
                                            <div className="flex items-center h-full w-full">
                                                {(() => {
                                                    if (post.isLive) {
                                                        if (post.profilePic === null || post.profilePic === undefined) {
                                                            // return <span className="h-14 w-14 rounded-full bg-red-600 ring-2 ring-red-500"></span>
                                                            return <img src={ProfilePic} className="h-14 w-14 rounded-full ring-2 ring-red-500" />
                                                        } else {
                                                            // return <img src={post.profilePic} className="h-14 w-14 rounded-full" />
                                                            return <a href={window.location.href + '/live'}><img src={post.profilePic} className="h-14 w-14 rounded-full ring-2 ring-red-500" /></a>
                                                        }
                                                    } else {
                                                        if (post.profilePic === null || post.profilePic === undefined) {
                                                            // return <span className="h-14 w-14 rounded-full bg-red-600"></span>
                                                            return <img src={ProfilePic} className="h-14 w-14 rounded-full" />
                                                        } else {
                                                            // return <img src={post.profilePic} className="h-14 w-14 rounded-full" />
                                                            return <img src={post.profilePic} className="h-14 w-14 rounded-full" />
                                                        }
                                                    }
                                                })()}
                                                <div className="ml-4">
                                                    {/* @ts-ignore */}
                                                    <h3 className="text-white font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {this.state.user.displayName}</h3>
                                                    <p className="text-gray-300">{formatFollowers(post.followers)}</p>
                                                </div>
                                                {/* <div className="float-right absolute flex">
                                                    <div className="flex-grow"></div>
                                                    <div className="flex items-end">
                                                        <button className="bg-red-600 text-white text-base font-bold rounded-md px-4 py-1">Follow</button>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="mt-4 px-2">
                                                <h1 className="text-2xl font-bold pb-4">{post.title}</h1>
                                                {/* <p className="pb-4">Juice & Cordae</p> */}
                                            </div>

                                            <div id="description">
                                                <p className="text-white text-lg px-2">{post.description}</p>
                                            </div>
                        
                                            <div id="media" className="mt-4">
                                                {(() => {
                                                    if (post.access === true) {
                                                        return <div className="flex justify-center px-2">
                                                            <div className="flex gap-4">
                                                                {(() => {
                                                                    if (post.content) {
                                                                        if (post.content.length > 1) {
                                                                            // return <></>
                                                                            return <Carousel>
                                                                                {/* {post.content.map((content: any, index: any) => (
                                                                                    <div className="pb-10"><ShakaPlayerComponent name={this.state.myName} manifestURI={`${process.env.REACT_APP_API_URL}files/${content.url}`} type={content.type} contentID={content.id} ogname={content.filename} contentWidth={content.contentWidth} contentHeight={content.contentHeight} /></div>
                                                                                ))} */}
                                                                                {post.content.map((content: any, index: any) => (
                                                                                    <div className="pb-10"><ShakaPlayerComponent name={this.state.myName} wmanifestURI={content.widevineURL} fmanifestURI={content.fairplayURL} type={content.type} contentID={content.id} ogname={content.filename} contentWidth={content.contentWidth} contentHeight={content.contentHeight} /></div>
                                                                                ))}
                                                                            </Carousel>
                                                                            // return <ShakaPlayerComponent name={this.state.myName} wmanifestURI={post.content[1].widevineURL} fmanifestURI={post.content[1].fairplayURL} type={post.content[1].type} contentID={post.content[1].id} ogname={post.content[1].filename} contentWidth={post.content[1].contentWidth} contentHeight={post.content[1].contentHeight} />
                                                                        } else {
                                                                            return <ShakaPlayerComponent name={this.state.myName} wmanifestURI={post.content[0].widevineURL} fmanifestURI={post.content[0].fairplayURL} type={post.content[0].type} contentID={post.content[0].id} ogname={post.content[0].filename} contentWidth={post.content[0].contentWidth} contentHeight={post.content[0].contentHeight} />
                                                                        }
                                                                    }   
                                                                })()}
                                                            </div>
                                                        </div>
                                                    } else {
                                                        return <div className="flex">
                                                            {/* Unlock content */}
                                                            <div className="flex grid grid-rows-6 bg-gray-800 w-full h-96 rounded-md flex items-center justify-center">
                                                                <div></div> 
                                                                <div className="text-white text-2xl row-span-4">Please purchase to unlock this post!</div>
                                                                <button onClick={(e) => this.buy(post.postID, e.target, post.price, post)} id={`buy-${post.postID}`} className="flex bg-gray-900 text-white rounded-md px-2 py-1 justify-center hover:shadow-md hover:-translate-y-1 transition ease-in-out">Pay - ${post.price} USD (excl tax)</button>
                                                            </div>
                                                        </div>
                                                    }
                                                })()}
                                            </div>
                        
                                            <div id="postControls">
                                                <div className="flex gap-4 px-2 pt-4 text-lg">
                                                    <span className="text-white">
                                                        {(() => {
                                                            if (post.access === true) {
                                                                if (post.isPostLiked) {
                                                                    return <>
                                                                        <i className="fa-solid fa-heart text-red-500 hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                        <span className="ml-2">{formatLikes(post.likes)}</span>
                                                                        <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                            <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                            <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                        </span>
                                                                    </>
                                                                } else {
                                                                    return <>
                                                                        <i className="fa-regular fa-heart hover:cursor-pointer" onClick={(e) => this.toggleLike(e, post.postID)}></i>
                                                                        <span className="ml-2">{formatLikes(post.likes)}</span>
                                                                        <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                            <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                            <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                        </span>
                                                                    </>
                                                                }
                                                            } else {
                                                                return <>
                                                                    <i className="fa-regular fa-heart" onClick={(() => {
                                                                        toast.error('Purchase to like the post!', { style: {
                                                                            color: '#ffffff',
                                                                            backgroundColor: '#242C37',
                                                                        }});
                                                                    })}></i>
                                                                    <span className="ml-2" onClick={(() => {
                                                                        toast.error('Purchase to like the post!', { style: {
                                                                            color: '#ffffff',
                                                                            backgroundColor: '#242C37',
                                                                        }});
                                                                    })}>{formatLikes(post.likes)}</span>
                                                                    {/* <span className="text-white hover:cursor-pointer ml-2" onClick={() => this.openCommentModal(post.postID)}>
                                                                        <i className="fa-regular fa-comment hover:cursor-pointer"></i>
                                                                        <span className="ml-2 hover:cursor-pointer">{formatComments(post.comments)}</span>
                                                                    </span> */}
                                                                </>
                                                            }
                                                        })()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            })()}
                    </InfiniteScroll>
                }
            })()}
            {(() => {
                if (this.state.isSelf) {
                    return <Transition appear show={this.state.profileModalShown}>
                        <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeProfileModal()}>
                            <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                            <DialogTitle as="h3" className="text-base/7 font-bold text-white">
                                                Set Display Name / Username
                                            </DialogTitle>
                                            <div className="flex grid grid-rows mt-4">
                                                {(() => {
                                                    if (this.state.user) {
                                                        return <>
                                                            <h1 className="text-white text-sm/6 font-bold">Display Name</h1>
                                                            {/* @ts-ignore */}
                                                            <input ref={this.displayNameInput} className="px-2 py-1 rounded-md bg-gray-900 mt-2 text-white" type="text" name="displayName" id="displayName" defaultValue={this.state.user.displayName} placeholder="Display Name ..." />
                                                            <h1 className="text-white text-sm/6 mt-2 font-bold">Username</h1>
                                                            {/* @ts-ignore */}
                                                            <input ref={this.usernameInput} className="px-2 py-1 rounded-md bg-gray-900 mt-2 text-white" type="text" name="username" id="username" defaultValue={this.state.user.username} placeholder="Username ..." /> 
                                                        </>
                                                    }
                                                })()}
                                            </div>
                                            {/* <p className="mt-2 text-sm/6 text-white/50">
                                                Your payment has been successfully submitted. We've sent you an email with all of the details of
                                                your order.
                                            </p> */}
                                            <div className="mt-6">
                                                <div className="flex grid grid-cols-2 gap-2">
                                                    <button className="px-2 py-1 bg-gray-700 rounded-md text-white" onClick={() => this.closeProfileModal()}>Cancel</button>
                                                    <button className="px-2 py-1 bg-red-600 rounded-md text-white"
                                                        onClick={() => this.updateProfile()}
                                                    >
                                                        {(() => {
                                                            if (this.state.profileUploading) {
                                                                return <span className="inline-flex"> <MoonLoader color="#ffffff" size={14} className="mt-[2px]" /> <span className="pl-2">Loading</span></span>;
                                                            } else {
                                                                return "Save Changes";
                                                            }
                                                        })()}
                                                    </button>
                                                    {/* <button
                                                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                                        onClick={() => this.updateProfile()}
                                                    >
                                                        {(() => {
                                                            if (this.state.profileUploading) {
                                                                return <span className="inline-flex"> <MoonLoader color="#ffffff" size={14} className="mt-[2px]" /> <span className="pl-2">Loading</span></span>;
                                                            } else {
                                                                return "Save Changes";
                                                            }
                                                        })()}
                                                    </button> */}
                                                </div>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                }
            })()}

            {/* Purchase Modal */}
            <Transition appear show={this.state.purchaseShow}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closePaymentModal()}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Purchase Post
                                    </DialogTitle>

                                    {(() => {
                                        if (this.state.twoFacSuccess === true && this.state.totpEnabled === true) {
                                            if (this.state.purchaseData !== null) {
                                                return <div className="mt-6 text-white">
                                                    <div>
                                                        <h3 className="font-bold mt-4 mb-4 text-lg">Payment Summary</h3>

                                                        <EmbeddedCheckoutProvider
                                                            stripe={this.loadStripeComponent()}
                                                            options={this.checkoutSession(this.state.purchaseData?.postID)}
                                                            // options={this.checkoutSession(this.state.purchaseData?.postID)}
                                                        >   
                                                            {(() => {
                                                                setTimeout(() => {
                                                                    var iframe = document.getElementsByName('embedded-checkout')[0];
                                                                    if (iframe) {
                                                                        iframe.style.borderRadius = '0.375rem';
                                                                    }
                                                                }, 1000);

                                                                if (this.state.isPaymentActive) {
                                                                    return <div className="flex justify-center">
                                                                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
                                                                    </div>
                                                                }
                                                            })()}
                                                            <EmbeddedCheckout />
                                                        </EmbeddedCheckoutProvider>
                                                    </div>

                                                    <button ref={this.closeModalBTN} onClick={() => this.closePaymentModal()} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button>
                                                </div>
                                            }
                                        } else if (this.state.totpEnabled === false ) {
                                            return <div className="mt-4">
                                                <div className="mb-4">
                                                    <h3 className="text-white text-lg">To make a purchase please enable Two Factor Authentication under account security settings.</h3>
                                                </div>
                                                {/* <a href={`${process.env.REACT_APP_URL}app/settings#/security`} className="text-red-500 pt-4" target='_blank'>Go to Security Settings</a> */}
                                                <a href={`${process.env.REACT_APP_URL}app/settings#/security`} className="text-white bg-gray-900 rounded-md px-3 py-2 mt-4" target='_blank' rel="noreferrer">Go to Security Settings <i className="fa-sharp fa-solid fa-right px-2"></i></a>
                                            </div>
                                        } else if (this.state.userSuspended === true) {
                                            return <div className="w-full">
                                                <p className="text-white mt-2">Unfortunately your account is suspended. You cannot make any new purchases, subscribe to creators, watch livestreams or comment/like content. You can still access previously purchased content.</p>
                                                {/* <input ref={this.twoFacInput} type="text" className="w-full bg-gray-900 text-white px-2 py-1 rounded-md mt-2" placeholder="2FA Code ..." /> */}
                                                {/* <button onClick={() => this.verifyTwoFac()} className="w-full text-white px-2 py-1.5 bg-red-600 rounded-md mt-2">Submit</button> */}
                                            </div>
                                        } else {
                                            return <div className="w-full">
                                                <h3 className="text-white">Please enter your 2FA code to continue!</h3>
                                                <AuthCode 
                                                    autoFocus={true} 
                                                    ref={this.AuthInputRef}
                                                    allowedCharacters='numeric' 
                                                    onChange={(e) => this.authCodeInput(e)} 
                                                    containerClassName='auth-container'
                                                    inputClassName='auth-input'
                                                />
                                                <button ref={this.closeModalBTN} onClick={() => this.closePaymentModal()} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button>
                                                {/* <input ref={this.twoFacInput} type="text" className="w-full bg-gray-900 text-white px-2 py-1 rounded-md mt-2" placeholder="2FA Code ..." /> */}
                                                {/* <button onClick={() => this.verifyTwoFac()} className="w-full text-white px-2 py-1.5 bg-red-600 rounded-md mt-2">Submit</button> */}
                                            </div>
                                        }
                                    })()}
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {/* Subscription Modal ... */}
            <Transition appear show={this.state.followModal}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.setState({ followModal: false})}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Follow
                                    </DialogTitle>

                                    <div className="mt-6 text-white">
                                        {(() => {
                                            if (this.state.twoFacSuccess === true && this.state.totpEnabled === true) {
                                                if (this.state.planID !== null) {
                                                    return <div>
                                                        <div>
                                                            <h3 className="font-bold mt-4 mb-4 text-lg">Payment Summary</h3>
                
                                                            <EmbeddedCheckoutProvider
                                                                stripe={this.loadStripeComponent()}
                                                                options={this.subscriptionSession(this.state.planID)}
                                                            >   
                                                                {(() => {
                                                                    setTimeout(() => {
                                                                        var iframe = document.getElementsByName('embedded-checkout')[0];
                                                                        if (iframe) {
                                                                            iframe.style.borderRadius = '0.375rem';
                                                                        }
                                                                    }, 1000);
                
                                                                    if (this.state.isPaymentActive) {
                                                                        return <div className="flex justify-center">
                                                                            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
                                                                        </div>
                                                                    }
                                                                })()}
                                                                <EmbeddedCheckout />
                                                            </EmbeddedCheckoutProvider>
                                                        </div>
                                                        <button onClick={() => this.setState({followModal: false})} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button>
                                                    </div>
                                                }
                                            } else if (this.state.totpEnabled === false) {
                                                return <div className="mt-4">
                                                    <div className="mb-4">
                                                        <h3 className="text-white text-lg">To make a purchase please enable Two Factor Authentication under account security settings.</h3>
                                                    </div>
                                                    <a href={`${process.env.REACT_APP_URL}app/settings#/security`} className="text-white bg-gray-900 rounded-md px-3 py-2 mt-4" target='_blank' rel="noreferrer">Go to Security Settings <i className="fa-sharp fa-solid fa-right px-2"></i></a>
                                                </div>
                                            } else if (this.state.userSuspended === true) {
                                                return <div className="w-full">
                                                    <p className="text-white mt-2">Unfortunately your account is suspended. You cannot make any new purchases, subscribe to creators, watch livestreams or comment/like content. You can still access previously purchased content.</p>
                                                </div>
                                            } else {
                                                return <div className="w-full">
                                                <h3 className="text-white">Please enter your 2FA code to continue!</h3>
                                                <AuthCode 
                                                    autoFocus={true} 
                                                    ref={this.AuthInputRef}
                                                    allowedCharacters='numeric' 
                                                    onChange={(e) => this.authCodeInput(e)} 
                                                    containerClassName='auth-container'
                                                    inputClassName='auth-input'
                                                />
                                                <button onClick={() => this.setState({followModal: false})} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button>
                                            </div>
                                            }
                                        })()}

                                        {/* <button onClick={() => this.setState({followModal: false})} className="w-full text-red-500 px-2 py-1.5 bg-gray-900 disabled:bg-gray-700 rounded-md mt-2">Cancel</button> */}
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {/* Comments Modal... */}
            <Transition appear show={this.state.commentModal}>
                <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.closeCommentModal()}>
                    <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                    <DialogTitle as="h3" className="text-2xl font-bold text-white">
                                        Comments
                                    </DialogTitle>
                                    <div className="flex grid grid-rows mt-6 text-white gap-2 overflow-y-auto max-h-96">
                                        {this.state.comments?.map((comment: any, index: any) => {
                                            return (
                                                <div className="flex items-start gap-4">
                                                    {(() => {
                                                        if (comment.profilePic === null || comment.profilePic === undefined || comment.profilePic === '') {
                                                            return (
                                                                // <span className="min-h-10 min-w-10 rounded-full bg-red-600 inline-block"></span>
                                                                <img src={ProfilePic} className="h-10 w-10 rounded-full" />
                                                            );
                                                        } else {
                                                            return (
                                                                <img src={comment.profilePic} className="h-10 w-10 rounded-full inline-block" />
                                                            );
                                                        }
                                                    })()}
                                                    <div className="w-full">
                                                        <div className="flex justify-between">
                                                            <h3 className="text-white font-bold">{(() => {
                                                                if (comment.isCreator) {
                                                                    return <i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" />
                                                                }
                                                            })()} {comment.displayName} <span className="text-gray-400 text-sm">{commentDateFormat(comment.createdAt)}</span>
                                                            {/* <i className="fa-regular fa-flag text-gray-400 text-sm hover:text-white hover:cursor-pointer"></i> */}
                                                            </h3>
                                                            {/* <button 
                                                                className="text-gray-400 px-2 py-1 hover:bg-gray-900 hover:rounded-md" 
                                                                aria-label="More options"
                                                            >
                                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                            </button> */}
                                                            {/* <span className="text-gray-400 px-2 py-1 hover:bg-gray-900 hover:rounded-md"><i className="fa-solid fa-ellipsis-vertical"></i></span> */}
                                                        </div>
                                                        <p className="text-white">{comment.comment}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        {/* Input */}
                                        <div className="mt-6">
                                            <form onSubmit={(e) => this.submitComment(e)}>
                                                <div className="flex grid grid-cols-4 gap-2">
                                                    <input ref={this.commentInput} onChange={(e) => this.setState({commentData: e.target.value})} className="px-2 py-1 col-span-3 rounded-md bg-gray-900 w-full text-white" type="text" placeholder="Comment ..." />
                                                    <button className="px-2 py-1 bg-gray-900 rounded-md text-white">Submit</button> {/* bg-gray-700 */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    }
}