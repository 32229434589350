import { useEffect, useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import AuthCode from 'react-auth-code-input';
// import * as Clerk from '@clerk/elements/common';
// import * as SignIn from '@clerk/elements/sign-in';
import { SignIn } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import clerk from '../utils/clerk';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [security, setSecurity] = useState(false);
    const [code, setCode] = useState('');

    const login = (e: any) => {
        e.preventDefault();
        // Axios request to backend ...
        console.log(email, password)
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/login`, { email, password }).then((res) => {
            console.log(res.data);
            if (res.data.security === true) {
                // Show twoFA
                setSecurity(true);
            } else {
                if (res.data.valid === true) {
                    // Redirect to main page
                    localStorage.setItem('token', res.data.token);
                    window.location.href = '/';
                } else {
                    // Invalid
                    console.error('Invalid Login');
                    console.log(res.data);
                }
            }
            // localStorage.setItem('token', res.data.token);
            // window.location.href = '/';
        }).catch(err => {
            console.error(err);
            if (err.response.data.msg === 'Invalid Password!') {
                toast.error('Invalid Password!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else if (err.response.data.msg === 'No account associated with that email address exists!') {
                toast.error('User not found!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        });
    }

    const securityLogin = (e: any) => {
        e.preventDefault();
        // Axios request to backend ...
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/login`, { email, password, code }).then((res) => {
            console.log(res.data);
            if (res.data.valid === true) {
                // Redirect to main page
                localStorage.setItem('token', res.data.token);
                window.location.href = '/';
            } else {
                // Invalid
                console.error('Code not valid!');
                console.log(res.data);
                toast.error('Code not valid!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        }).catch(err => {
            console.error(err);
            if (err.response.data.msg === 'Invalid Password!') {
                toast.error('Invalid Password!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else if (err.response.data.msg === 'No account associated with that email address exists!') {
                toast.error('User not found!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        });
    }

    const securityLogin2 = async (sCode: string) => {
        // Axios request to backend ...
        await axios.post(`${process.env.REACT_APP_API_URL}api/v1/user/login`, { email, password, code: sCode }).then((res) => {
            console.log(res.data);
            if (res.data.valid === true) {
                // Redirect to main page
                localStorage.setItem('token', res.data.token);
                window.location.href = '/';
            } else {
                // Invalid
                console.error('Code not valid!');
                console.log(res.data);
                toast.error('Code not valid!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        }).catch(err => {
            console.error(err);
            if (err.response.data.msg === 'Invalid Password!') {
                toast.error('Invalid Password!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else if (err.response.data.msg === 'No account associated with that email address exists!') {
                toast.error('User not found!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            } else {
                toast.error('An error has occurred!', { style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }});
            }
        });
    }

    const valueChanged = async (val: any) => {
        setCode(val);
        if (val.length === 6) {
            securityLogin2(val);
        }
    }

    useEffect(() => {
        console.log(clerk.user);
    }, []);

    return <div className="flex justify-center items-center h-screen">
        <Toaster position="bottom-center" />
        <SignIn appearance={{
                baseTheme: dark,
                elements: {
                    backgroundColor: '#374151'
                }
                // elements: {
                //     backgroundColor: '374151'
                // }
        }}
        forceRedirectUrl='/app/'
        />
        {/* <div className="bg-gray-700 p-6 w-full sm:w-4/6 md:1/6 lg:w-1/4 xl:w-1/4 2xl:w-1/4 rounded-md"> */}
            {/* {(() => {
                if (security) {
                    return <form onSubmit={(e) => securityLogin(e)}>
                        <h1 className="text-2xl font-bold text-center">2FA Security Code</h1>
                        <AuthCode onChange={valueChanged} allowedCharacters='numeric' inputClassName="bg-gray-800 py-2 px-2 mr-2 rounded-md w-full text-white" containerClassName="flex flex-inline mt-8" />
                        <button type="submit" className="w-full bg-red-600 text-white rounded-md mt-8 h-8">Login</button>
                    </form>
                } else {
                    return <form onSubmit={(e) => login(e)}>
                        <h1 className="text-2xl font-bold text-center">Login</h1>
                        <h3 className="mt-8 text-xl font-bold">Email Address</h3>
                        <input required onChange={(e) => setEmail(e.target.value) } type="email" placeholder="Email Address ..." className="w-full mt-2 px-4 text-gray-100 text-base bg-gray-800 rounded-md h-8" />
                        <h3 className="mt-8 text-xl font-bold">Password</h3>
                        <input required onChange={(e) => setPassword(e.target.value) } type="password" placeholder="Password ..." className="w-full mt-2 px-4 text-gray-100 text-base bg-gray-800 rounded-md h-8" />
                        <div className="mt-4">
                            <a href="./forgotpassword" className="text-red-600 text-xs font-semibold mt-2">Forgot your password?</a>
                            <a href="./signup" className="text-red-600 text-xs font-semibold mt-2 float-right">Create an account</a>
                        </div>
                        <button type="submit" className="w-full bg-red-600 text-white rounded-md mt-8 h-8">Login</button>
                    </form>
                }
            })()} */}
            {/* <SignIn.Root>
                <SignIn.Step name="start">
                    <h1>Sign in</h1>

                    <Clerk.Field name="identifier">
                        <Clerk.Label>Email</Clerk.Label>
                        <Clerk.Input />
                        <Clerk.FieldError />
                    </Clerk.Field>

                    <SignIn.Action submit>Continue</SignIn.Action>
                </SignIn.Step>

                <SignIn.Strategy name="password">
                    <Clerk.Field name="password">
                        <Clerk.Label>Password</Clerk.Label>
                        <Clerk.Input type="password" />
                        <Clerk.FieldError />
                    </Clerk.Field>

                    <SignIn.Action submit>Continue</SignIn.Action>
                    <SignIn.Action navigate="forgot-password">Forgot password?</SignIn.Action>
                </SignIn.Strategy>

                <SignIn.Step name="forgot-password">
                    <h1>Forgot your password?</h1>

                    <SignIn.SupportedStrategy name="reset_password_email_code">
                    Reset password
                    </SignIn.SupportedStrategy>

                    <SignIn.Action navigate="previous">Go back</SignIn.Action>
                </SignIn.Step>

                <SignIn.Step name="reset-password">
                    <h1>Reset your password</h1>

                    <Clerk.Field name="password">
                    <Clerk.Label>New password</Clerk.Label>
                    <Clerk.Input />
                    <Clerk.FieldError />
                    </Clerk.Field>

                    <Clerk.Field name="confirmPassword">
                    <Clerk.Label>Confirm password</Clerk.Label>
                    <Clerk.Input />
                    <Clerk.FieldError />
                    </Clerk.Field>

                    <SignIn.Action submit>Reset password</SignIn.Action>
                </SignIn.Step>
            </SignIn.Root> */}

            {/* <SignIn appearance={{
                baseTheme: dark
                // elements: {
                //     backgroundColor: '374151'
                // }
            }} /> */}
        {/* </div> */}
    </div>
}