import AdminHeader from "../../components/AdminHeader";
import Links from "../../components/admin/Links";
import AllUsers from "../../components/admin/AllUsers";

export default function UserManagement() {
    return <div>
        <AdminHeader />
        <div className="flex grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 w-full">
            <Links page="User Management" />
            <div className="col-span-2 mx-4 mt-4 bg-gray-700 px-4 py-4 rounded-md">
                <h1 className="text-2xl font-bold">Users</h1>
                <AllUsers />
            </div>
        </div>
    </div>
}