import Header from '../components/Header';
import Subscriptions from '../components/Subscriptions';
import Profile from '../components/Profile';
import UpcomingLives from '../components/UpcomingLives';
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Search() {
    const [user, setUser] = useState();

    useEffect(() => {
        // Check User Token
        var token = localStorage.getItem('token');
        if (token) {
            // Axios request to backend ...
            axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, {}, { headers: { 'x-auth-token': token } }).then((res) => {
                console.log(res.data);
                setUser(res.data);
            }).catch(err => {
                if (err.response.data.msg === 'Not Verified') {
                    // Redirect to login
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                console.error(err);
            });
        } else {
            // Redirect to login
            window.location.href = '/login';
        }
    }, []);

    return <>
        <Header />
        <div className="flex grid grid-cols-4 w-full">
            <Subscriptions />
            <div className="col-span-2 py-4 px-4">
                <h1 className="text-2xl font-bold">Search Results for ...</h1>
            </div>
            <div>
                <Profile />
                <UpcomingLives />
            </div>
        </div>
        <Footer />
    </>
}