import React from 'react';
import ChrisLogo from '../chrislogo.jpg';
import ProfilePic from '../ProfilePicTemp.png';
import axios from 'axios';
import clerk from '../utils/clerk';

type props = {}

type state = {
    live: boolean;
    loading: boolean;
    displayName: string;
    username: string;
    verified: boolean;
    followers: number;
    following: number;
    profilePic: string;
}

export default class Profile extends React.PureComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            live: false,
            loading: true,
            displayName: '',
            username: '',
            verified: false,
            followers: 0,
            following: 0,
            profilePic: ''
        }
    }

    async componentDidMount() {
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                axios.post(`${process.env.REACT_APP_API_URL}api/v1/user`, null, { headers: { 'x-auth-token': token }}).then((res) => {
                    // console.log(res.data);

                    // if displayName is null and page is not /profile, redirect to /profile
                    if (res.data.displayName === null && window.location.pathname !== '/app/profile') {
                        window.location.href = '/app/profile?display=true'
                    }

                    this.setState({ displayName: res.data.displayName, username: res.data.username, verified: res.data.verified, followers: res.data.followers, following: res.data.following, profilePic: res.data.profilePic});
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    console.error(err);
                })
            });
        } else {
            clerk.signOut({redirectUrl: '../login'});
        }
    }

    render() {
        return <div className="bg-gray-800 px-4 py-4 rounded-md my-2 mx-2 mr-4">
            {(() => {
                if (this.state.loading === true) {
                    return <div>
                        <div className="flex items-center h-full pb-4">
                            <span className="min-w-20 min-h-20 rounded-full animate-pulse bg-slate-700" />
                            
                            <div className="ml-4 w-full">
                                <span className="flex h-2 w-40 bg-slate-700 rounded animate-pulse" />
                                <span className="flex h-2 w-24 mt-2 bg-slate-700 rounded animate-pulse" />
                            </div>
                        </div>
                        <div className="flex grid">
                            <div className="flex grid-cols-2 gap-4">
                                <span className="flex h-4 w-24 bg-slate-700 rounded animate-pulse" />
                                <span className="flex h-4 w-24 bg-slate-700 rounded animate-pulse" />
                            </div>
                        </div>
                    </div>
                } else {
                    return <div>
                        <div className="flex items-center h-full pb-4">
                            {(() => {
                                if (this.state.live) {
                                    if (this.state.profilePic !== null) {
                                        // return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.username}/profilepic`} className="w-20 h-20 rounded-full ring ring-red-500 animate-pulse" />
                                        return <img src={this.state.profilePic} className="w-20 h-20 rounded-full ring ring-red-500 animate-pulse" />
                                        // return <img className="w-20 h-20 rounded-full ring ring-red-500 animate-pulse" src={this.state.profilePic} />
                                    } else {
                                        // return <span className="min-w-20 min-h-20 rounded-full ring ring-red-500 bg-red-600 animate-pulse" />
                                        // return <img src={ProfilePic} className="h-20 w-20 rounded-full ring ring-red-500 animate-pulse" />
                                        return <img src={ProfilePic} className="h-20 w-20 rounded-full ring ring-red-500 animate-pulse" />
                                    }
                                    // return <img className="w-20 h-20 rounded-full ring ring-red-500 animate-pulse" src={ChrisLogo} />
                                } else {
                                    if (this.state.profilePic !== null) {
                                        // return <img src={`${process.env.REACT_APP_API_URL}api/v1/user/${this.state.username}/profilepic`} className="h-20 w-20 rounded-full" />
                                        return <img src={this.state.profilePic} className="h-20 w-20 rounded-full" />
                                        // return <img className="w-20 h-20 rounded-full" src={this.state.profilePic} />
                                    } else {
                                        return <img src={ProfilePic} className="h-20 w-20 rounded-full" />
                                        // return <span className="min-w-20 min-h-20 rounded-full bg-red-600" />
                                    }
                                    // return <img className="w-20 h-20 rounded-full" src={ChrisLogo} />
                                }
                            })()}

                            {/* Change Design i.e. Verified badge ... */}
                            
                            {(() => {
                                if (this.state.verified === true) {
                                    return <div className="ml-4">
                                        <h3 className="text-white font-bold"><i className="fa-solid fa-badge-check text-sky-500" title="Verified Creator" /> {this.state.displayName}</h3>
                                        <p className="text-gray-300">@{this.state.username}</p>
                                    </div>
                                } else {
                                    return <div className="ml-4">
                                        <h3 className="text-white font-bold">@{this.state.displayName}</h3>
                                    </div>
                                }
                            })()}
                        </div>
                        <div className="flex grid">
                            <span className="text-md text-white">{this.state.followers} <span className="text-gray-200 px-2">Followers</span> {this.state.following} <span className="text-gray-200 px-2">Following</span></span>
                        </div>
                    </div>
                }
            })()}
        </div>
    }
}