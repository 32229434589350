import React from 'react';
import axios from 'axios';
import clerk from '../utils/clerk';

type state = {
    email: string;
    username: string;
    status: string;
}

class RequestComponent extends React.PureComponent<{}, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
            username: '',
            status: 'none'
        }
        this.changeEmail = this.changeEmail.bind(this);
        this.changeUsername = this.changeUsername.bind(this);
    }

    async componentDidMount() {
        // Check submission status ...
        clerk.session?.getToken().then(async (token) => {
            await axios.get(`${process.env.REACT_APP_API_URL}api/v1/creator/requestStat`, { headers: { "x-auth-token": token }}).then((res) => {
                if (res.data.status == 'pending' || res.data.status == 'accepted' || res.data.status == 'Already Verified') {
                    this.setState({status: res.data.status});
                } else {
                    this.setState({status: 'none'});
                }
            })
        });
    }

    changeEmail = (e: any) => {
        this.setState({email: e.target.value});
    }

    changeUsername = (e: any) => {
        var newVal = e.target.value.toLowerCase();
        e.target.value = newVal;
        this.setState({username: e.target.value})
    }

    submitForm = async (e: any) => {
        e.preventDefault();
        clerk.session?.getToken().then(async (token) => {
            await axios.post(`${process.env.REACT_APP_API_URL}api/v1/creator/request`, {email: this.state.email, username: this.state.username}, { headers: { "x-auth-token": token }}).then((res) => {
                this.setState({status: 'pending'});
            }).catch((err) => {
                console.error(err);
            });
        });
    }

    render() {
        return <div className="flex grid grid-rows mt-2">
            {(() => {
                if (this.state.status !== 'none') {
                    return <h1 className="text-2xl font-bold">Form Submitted!</h1>
                } else {
                    return <form onSubmit={this.submitForm}>
                        <div>
                            <label htmlFor="email" className="text-xl">Email Address:</label>
                            <input required onChange={(e) => this.changeEmail(e)} type="email" name="email" id="email" className="bg-gray-800 px-2 py-1 mt-1 text-white rounded-md w-full" placeholder="Email Address ..." />
                        </div>
                        <div className="mt-2">
                            <label htmlFor="email" className="text-xl">Username: </label>
                            <input required onChange={(e) => this.changeUsername(e)} type="text" name="username" id="username" className="bg-gray-800 px-2 py-1 mt-1 text-white rounded-md w-full" placeholder="Username ..." />
                        </div>
                        <button className="bg-gray-800 w-full mt-4 rounded-md h-12">Submit Request</button>
                    </form>
                }
            })()}
        </div>
    }
}

export default function Request() {
    return <div className="col-span-2 mx-4 mt-4">
        <h1 className="text-2xl font-bold">Request access to creator mode!</h1>
        <div className="bg-gray-700 w-full my-4 px-4 py-4 rounded-md">
            <h1 className="text-lg font-bold">Please provide the following details</h1>
            <span>*We may require you to message us a specific code on a verified platform to ensure authenticity.</span>
            <RequestComponent />
        </div>
    </div>
}