import React from 'react';
import axios from 'axios';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectAccountOnboarding, ConnectComponentsProvider, ConnectNotificationBanner, ConnectBalances, ConnectPayments, ConnectDocuments, ConnectAccountManagement, ConnectTaxSettings, ConnectTaxRegistrations, ConnectPayoutsList } from '@stripe/react-connect-js';
import clerk from '../utils/clerk';
import { Toaster, toast } from 'react-hot-toast';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

type props = {

};

type state = {
    user: any[];
    stripeID: string;
    stripeSetup: Boolean;
    drmSetup: Boolean;
    loading: Boolean;
    showStripe: Boolean;
    showDRM: Boolean;
    privateKey: File | null;
    publicCertificate: File | null;
    privateKeyPass: string;
    ask: string;
    dashboardView: Boolean;
    documentsView: Boolean;
    accManagementView: Boolean;
    taxSettingsView: Boolean;
    taxRegistrationsView: Boolean;
    payoutListView: Boolean;
    subscriptionPlan: Boolean;
    subModalShow: boolean;
    planPrice: number;
    planName: string;
    planDescription: string;
    subscriptionPlans: any[];
    editPlan: boolean;
    planID: string;
}


export default class CreatorComponent extends React.PureComponent<props, state> {
    private AskInput: React.RefObject<HTMLInputElement>;
    private price: any;

    constructor(props: props) {
        super(props);
        this.state = {
            user: [],
            stripeID: '',
            stripeSetup: false,
            drmSetup: false,
            loading: true,
            showStripe: false,
            showDRM: false,
            privateKey: null,
            publicCertificate: null,
            privateKeyPass: '',
            ask: '',
            dashboardView: true, // true
            documentsView: false,
            accManagementView: false,
            taxSettingsView: false,
            taxRegistrationsView: false,
            payoutListView: false,
            subscriptionPlan: false,
            subModalShow: false,
            planPrice: 0.00,
            planName: '',
            planDescription: '',
            subscriptionPlans: [],
            editPlan: false,
            planID: ''
        }
        this.initializeConnect = this.initializeConnect.bind(this);
        this.handleNotificationsChange = this.handleNotificationsChange.bind(this);
        this.postCerts = this.postCerts.bind(this);
        this.setAsk = this.setAsk.bind(this);
        this.AskInput = React.createRef();
        this.changeView = this.changeView.bind(this);
        this.addnewSub = this.addnewSub.bind(this);
        this.price = React.createRef();
        this.editPlanSubmit = this.editPlanSubmit.bind(this);
    }

    componentDidMount(): void {
        if (clerk.user) {
            clerk.session?.getToken().then(async (token) => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/user`, { headers: { 'x-auth-token': token } }).then((res) => {
                    // console.log(res.data);
                    if (res.data.user.verified === false) {
                        // No Permission to view this page, redirect to homepage.
                        window.location.href = '/';
                    } else {
                        // Save data.
                        this.setState({loading: false, user: res.data.user, stripeID: res.data.user.stripeID, stripeSetup: res.data.user.stripeSetup, drmSetup: res.data.user.drmStatus});
                    }
                }).catch(err => {
                    console.error(err);
                });

                axios.get(`${process.env.REACT_APP_API_URL}api/v1/creator/plans`, { headers: { 'x-auth-token': token } }).then((res) => {
                    console.log(res.data);
                    this.setState({subscriptionPlans: res.data.plans});
                }).catch(err => {
                    console.error(err);
                });
            });
        }

        // console.log(this.loadStripeData());
    }

    showStripeOnboarding(): void {
        this.setState({showStripe: true});
    }

    showDMRSetup(): void {
        this.setState({showDRM: true});
    }

    hideStripeOnboarding(): void {
        this.setState({showStripe: false});
    }

    hideDMRSetup(): void {
        this.setState({showDRM: false});
    }

    uploadDRM(): void {
        // Upload DRM Certificate
        this.setState({showDRM: false, drmSetup: true});
    }

    initializeConnect() {
        // const session = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/stripe/onboarding`, {}, { headers: { 'x-auth-token': localStorage.getItem('token') } }).then((res) => {
        //     console.log(res.data);
        //     return res.data.account;
        // }).catch(err => {
        //     console.error(err);
        // });

        const fetchSession = async () => {
            const session = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/stripe/accountSession`, {}, { headers: { 'x-auth-token': await clerk.session?.getToken() } }).then((res) => {
                // console.log(res.data);
                return res.data.account;
            }).catch(err => {
                console.error(err);
            });

            return session.client_secret;
        }

        // console.log(session);
        // console.log(process.env.REACT_APP_STRIPE_KEY);

        const instance = loadConnectAndInitialize({
            // @ts-ignore
            publishableKey: process.env.REACT_APP_STRIPE_KEY,
            fetchClientSecret: fetchSession,
            appearance: {
                overlays: 'dialog',
                variables: {
                    colorText: '#fff',
                    colorBackground: '#151A21',
                    colorPrimary: 'rgb(239, 68, 68)',
                    buttonSecondaryColorBackground: 'rgb(239, 68, 68)',
                    buttonPrimaryColorBackground: "RGB(239, 68, 68)",
                    actionPrimaryColorText: "RGB(239, 68, 68)",
                    formHighlightColorBorder: "RGB(239, 68, 68)",
                    formAccentColor: "RGB(239, 68, 68)",
                }
            }
        })

        // console.log(instance);

        return instance;
    }

    changeView(view: string) {
        switch (view) {
            case 'dashboard':
                this.setState({dashboardView: true, documentsView: false, accManagementView: false, taxSettingsView: false, taxRegistrationsView: false, payoutListView: false, subscriptionPlan: false});
                // Change to dashboard view
                break;
            case 'documents':
                this.setState({dashboardView: false, documentsView: true, accManagementView: false, taxSettingsView: false, taxRegistrationsView: false, payoutListView: false, subscriptionPlan: false});
                break;
            case 'accManagement':
                this.setState({dashboardView: false, documentsView: false, accManagementView: true, taxSettingsView: false, taxRegistrationsView: false, payoutListView: false, subscriptionPlan: false});
                break;
            case 'taxSettings':
                this.setState({dashboardView: false, documentsView: false, accManagementView: false, taxSettingsView: true, taxRegistrationsView: false, payoutListView: false, subscriptionPlan: false});
                break;
            case 'taxRegistrations':
                this.setState({dashboardView: false, documentsView: false, accManagementView: false, taxSettingsView: false, taxRegistrationsView: true, payoutListView: false, subscriptionPlan: false});
                break;
            case 'payoutList':
                    this.setState({dashboardView: false, documentsView: false, accManagementView: false, taxSettingsView: false, taxRegistrationsView: false, payoutListView: true, subscriptionPlan: false});
                    break;
            case 'subscriptionPlan':
                this.setState({dashboardView: false, documentsView: false, accManagementView: false, taxSettingsView: false, taxRegistrationsView: false, payoutListView: false, subscriptionPlan: true});
                break;
            default:
                this.setState({dashboardView: true, documentsView: false, accManagementView: false, taxSettingsView: false, taxRegistrationsView: false, payoutListView: false});
                break;
        }
    }

    handleNotificationsChange = (response: any) => {
        console.log(response)
        // if (response.actionRequired > 0) {
            
        // }
    }

    async postCerts(e: any) {
        e.preventDefault();
        // console.log(this.state.privateKey);

        if (this.state.ask.length !== 32) {
            toast.error('ASK must be 32 characters long!', {
                style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }
            });
        } else {
            var formData = new FormData();
            // @ts-ignore
            formData.append('privateKey', this.state.privateKey);
            // @ts-ignore
            formData.append('appCert', this.state.publicCertificate);
            formData.append('passphrase', this.state.privateKeyPass);
            formData.append('ask', this.state.ask);

            clerk.session?.getToken().then(async (token) => {
                await toast.promise(
                    axios.post(`${process.env.REACT_APP_API_URL}api/v1/creator/certs`, formData, {
                        headers: { 'x-auth-token': token }
                    })
                    .then((res) => {
                        if (res.data.msg === 'Certificates Validated and Generated') {
                            window.location.reload();
                            return res.data;
                        } else {
                            return res.data; // Ensure to return the response data
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        throw err; 
                    }), {
                        loading: 'Generating ...',
                        success: 'Complete',
                        error: 'Failed to upload certificate!'
                    }, {
                        style: {
                            color: '#ffffff',
                            backgroundColor: '#242C37',
                        }
                    }
                );
            });
        }
    }

    setAsk(value: string) {
        this.setState({ask: value});
        // Add ASK verification here i.e. length is correct change ring of input

        if (value.length === 32) {
            this.AskInput.current?.classList.add('ring-2');
            this.AskInput.current?.classList.add('ring-green-500');
            this.AskInput.current?.classList.remove('ring-red-500');
        } else {
            this.AskInput.current?.classList.add('ring-2');
            this.AskInput.current?.classList.add('ring-red-500');
            this.AskInput.current?.classList.remove('ring-green-500');
        }
    }

    addnewSub() {
        // alert('triggered');
        this.setState({subModalShow: true});

        // Trigger Modal.
    }

    costPrice(value: any) {
        if (value === '' || value === 0 || value === 0.0 || value === 0.00 || value === null) {
            this.setState({planPrice: 0.00});
        } else {
            this.setState({planPrice: value.slice(1).replace(/,/g, '')});
        }
    }

    createPlan() {
        const name = this.state.planName;
        const description = this.state.planDescription;
        const price = this.state.planPrice;
        

        if (name !== null || name !== '' || description !== null || description !== '' || price !== null || price !== 0.00) {
        // Send API Req...
            clerk.session?.getToken().then(async (token) => {
                await axios.post(`${process.env.REACT_APP_API_URL}api/v1/creator/plan`, {name: name, desc: description, price: price}, { headers: { 'x-auth-token': token } }).then((res) => {
                    console.log(res.data);
                    if (res.data.msg === 'Plan Created') {
                        window.location.reload();
                    }
                }).catch(err => {
                    console.error(err);
                });
            });
        } else {
            toast.error('Please fill in all fields!', {
                style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }
            });
        }
    }

    editPlanSubmit() {
        const name = this.state.planName;
        const description = this.state.planDescription;
        const price = this.state.planPrice;
        const planID = this.state.planID;
        

        if (name !== null || name !== '' || description !== null || description !== '' || price !== null || price !== 0.00) {
        // Send API Req...
            clerk.session?.getToken().then(async (token) => {
                await axios.post(`${process.env.REACT_APP_API_URL}api/v1/creator/updatePlan`, {name: name, desc: description, price: price, planID: planID}, { headers: { 'x-auth-token': token } }).then((res) => {
                    console.log(res.data);
                    if (res.data.msg === 'Plan Updated') {
                        window.location.reload();
                    }
                }).catch(err => {
                    console.error(err);
                });
            });
        } else {
            toast.error('Please fill in all fields!', {
                style: {
                    color: '#ffffff',
                    backgroundColor: '#242C37',
                }
            });
        }
    }

    render() {
        const currencyMask = createNumberMask({
            prefix: '$',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 7, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        });

        return <ConnectComponentsProvider connectInstance={this.initializeConnect()}>
            <Toaster position="bottom-center" />
            <div className="flex bg-gray-800 rounded-md px-2 py-2 mt-4">
                {/* Prerequistes */}
                {(() => {
                    if (this.state.loading) {
                        return <div className="flex grid grid-cols-2 gap-4 w-full mr-4 h-full">
                        </div>
                        // return <div className="flex grid grid-col-1 w-full">
                        //     <h1 className="text-2xl font-bold">Prerequisites</h1>
                        //     <div className="flex w-full">
                        //         <ul className="w-full">
                        //             <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-2 px-2 py-2">
                        //                 <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-gray-500"></i> <span className="text-xl">DRM Certificate Upload</span></span>
                        //             </li>
                        //             <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-2 px-2 py-2">
                        //                 <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-gray-500"></i> <span className="text-xl">Stripe Onboarding</span></span>
                        //             </li>
                        //         </ul>
                        //     </div>
                        // </div>
                    } else {
                        if (!this.state.drmSetup || !this.state.stripeSetup) {
                            return <div className="flex grid grid-col-1 w-full">
                                <h1 className="text-2xl font-bold">Prerequisites</h1>
                                <div className="flex w-full">
                                    <ul className="w-full">
                                        {(() => {
                                            if (!this.state.drmSetup) {
                                                return <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-2 px-2 py-2">
                                                    <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-gray-500"></i> <span className="text-xl">DRM Certificate Upload</span></span>
                                                    <button onClick={() => this.showDMRSetup()} className="text-left py-2 px-2 bg-gray-800 rounded-md shadow-md">Complete</button>
                                                </li>
                                            } else {
                                                return <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-2 px-2 py-2">
                                                    <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-green-500"></i> <span className="text-xl">DRM Certificate Upload</span></span>
                                                </li>
                                            }
                                        })()}
                                        {(() => {
                                            if (!this.state.stripeSetup) {
                                                return <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-2 px-2 py-2">
                                                    <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-gray-500"></i> <span className="text-xl">Stripe Onboarding</span></span>
                                                    <button onClick={() => this.showStripeOnboarding()} className="text-left py-2 px-2 bg-gray-800 rounded-md shadow-md">Complete</button>
                                                </li>
                                            } else {
                                                return <li className="mt-4 w-full bg-gray-700 rounded-md flex grid grid-rows-1 px-2 py-2">
                                                    <span className="text-white rounded-md"><i className="fa-solid fa-circle-check text-xl text-green-500"></i> <span className="text-xl">Stripe Onboarding</span></span>
                                                </li>
                                            }
                                        })()}
                                    </ul>
                                </div>
                            </div>
                        } else {
                            return <div className="w-full h-full mr-4">
                                <div className="my-2">
                                    <ConnectNotificationBanner
                                        collectionOptions={{
                                            fields: 'eventually_due',
                                            futureRequirements: 'include',
                                        }}
                                        onNotificationsChange={this.handleNotificationsChange}
                                    />
                                </div>

                                <div className="w-full h-full mr-4 flex grid grid-cols-4">
                                    <div className="col-span-1">
                                        <ul>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('dashboard')}>Dashboard</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('documents')}>Documents</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('accManagement')}>Account Management</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('taxSettings')}>Tax Settings</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('taxRegistrations')}>Tax Registrations</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('payoutList')}>Payouts List</li>
                                            <li className="my-1 hover:cursor-pointer hover:underline" onClick={() => this.changeView('subscriptionPlan')}>Subscription Plans</li>
                                        </ul>
                                    </div>
                                    <div className="col-span-3">
                                        {(() => {
                                            if (this.state.dashboardView === true) {
                                                return <>
                                                    <ConnectBalances />
                                                    <div className="mt-2">
                                                        <ConnectPayments />
                                                    </div>
                                                </>
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.documentsView === true) {
                                                return <ConnectDocuments />
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.accManagementView === true) {
                                                return <ConnectAccountManagement
                                                    collectionOptions={{
                                                        fields: 'eventually_due',
                                                        futureRequirements: 'include',
                                                    }}
                                                />
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.taxSettingsView === true) {
                                                return <ConnectTaxSettings />
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.taxRegistrationsView === true) {
                                                return <ConnectTaxRegistrations />
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.payoutListView === true) {
                                                return <ConnectPayoutsList />
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.subscriptionPlan === true && this.state.subscriptionPlans.length > 0) {
                                                return <>
                                                    <h1 className="text-2xl font-bold">Subscription Plans</h1>
                                                    <div className="flex mt-2 w-full grid grid-cols-1 gap-4">
                                                        {this.state.subscriptionPlans.map((plan, index) => {
                                                            return <div key={index} className="bg-gray-700 rounded-md p-4">
                                                                <h1 className="text-xl font-bold">{plan.name}</h1>
                                                                <span>{plan.description}</span>
                                                                <div>
                                                                    <span>${plan.price}</span>
                                                                </div>
                                                                <button onClick={() => this.setState({editPlan: true, planName: plan.name, planDescription: plan.description, planPrice: plan.price, planID: plan._id})} className="text-white bg-red-600 rounded-md w-full mt-2">Edit Plan</button>
                                                            </div>
                                                        })}
                                                    </div>
                                                </>
                                            } else if (this.state.subscriptionPlan === true && this.state.subscriptionPlans.length === 0) {
                                                return <>
                                                    <h1 className="text-2xl font-bold">Subscription Plans</h1>
                                                    <span>No subscription plans exist. Please create a plan! If you dont create a plan, 'following' will be free.</span>
                                                    <div className="flex w-full outline-dashed outline-2 outline-slate-500 justify-center py-8 mt-4 rounded-md" onClick={() => this.addnewSub()}>
                                                        <span className="text-slate-300">+</span>
                                                    </div>
                                                </>
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        }
                    }
                })()}
            </div>

            {/* DRM Certificate Upload */}
            {(() => {
                if (this.state.showDRM) {
                    return <div className="flex mt-4 w-full">
                        <div className="flex bg-gray-800 w-full grid grid-cols-1 rounded-md px-2 py-2 mt-4 h-full">
                            <div className="flex h-full">
                                <form className="w-full px-4 py-2" onSubmit={(e) => this.postCerts(e)}>
                                    <h1 className="text-xl font-bold">DRM Certificate Upload</h1>
                                    <div className="grid grid-rows-4 gap-2">
                                        <div>
                                            <label className="text-white">Private Key (.pem)</label>
                                            {/* @ts-ignore */}
                                            <input accept=".pem" onChange={(e: any) => this.setState({privateKey: e.target.files[0]})} type="file" className="text-white bg-gray-700 rounded-md w-full" />
                                        </div>
                                        <div>
                                            <label className="text-white">Application Certificate (.cer)</label>
                                            {/* @ts-ignore */}
                                            <input accept=".cer" onChange={(e: any) => this.setState({publicCertificate: e.target.files[0]})} type="file" className="text-white bg-gray-700 rounded-md w-full" />
                                        </div>
                                        <div>
                                            <label className="text-white">Private Key Passphrase</label>
                                            <input type="password" onChange={(e: any) => this.setState({privateKeyPass: e.target.value})} className="text-white bg-gray-700 rounded-md w-full px-2 py-1" placeholder="Password ..." />
                                        </div>
                                        <div>
                                            <label className="text-white">Application Secret Key (ASK)</label>
                                            <input ref={this.AskInput} type="text" onChange={(e: any) => this.setAsk(e.target.value)} className="text-white bg-gray-700 rounded-md w-full px-2 py-1" placeholder="ASK ..." />
                                        </div>
                                    </div>
                                    <button onClick={(e) => this.postCerts(e)} className="text-white bg-gray-700 rounded-md w-full mt-2">Upload</button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            })()}

            {/* Stripe Onboarding ... */}
            {(() => {
                if (this.state.showStripe) {
                    return <div className="flex mt-4 w-full">
                        <div className="flex bg-gray-800 w-full grid grid-cols-1 rounded-md px-2 py-2 mt-4 h-full">
                            <div className="flex h-full">
                                <div className="w-full px-4 py-2">
                                    <h1 className="text-xl font-bold">Stripe Onboarding</h1>

                                    <div className="mt-2">
                                        {/* @ts-ignore */}
                                        {/* <ConnectComponentsProvider connectInstance={this.loadStripeData()}> */}
                                            {/* @ts-ignore */}
                                            <ConnectAccountOnboarding
                                                onExit={() => {
                                                    console.log("The account has exited onboarding");
                                                }}
                                            >

                                            </ConnectAccountOnboarding>
                                        {/* </ConnectComponentsProvider> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            })()}

            {/* Add Plan Modal ... */}
            {(() => {
                return <Transition appear show={this.state.subModalShow}>
                    <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.setState({subModalShow: false})}>
                            <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                            <DialogTitle as="h3" className="text-base/7 font-bold text-white">
                                                Create Subscription Plan
                                            </DialogTitle>
                                            <div className="flex grid grid-rows mt-4">
                                                <form onSubmit={() => this.createPlan()}>
                                                    <label className="font-bold text-md text-white">Plan Name</label>
                                                    <input required onChange={(e) => this.setState({planName: e.target.value})} type="text" className="text-white bg-gray-900 rounded-md w-full px-2 py-1 my-2" placeholder="Plan Name" />
                                                    {/* <input type="number" className="text-white bg-gray-700 rounded-md w-full px-2 py-1 mt-2" placeholder="Price (USD)" /> */}
                                                    <label className="font-bold text-md text-white">Plan Description</label>
                                                    <textarea required rows={5} onChange={(e) => this.setState({planDescription: e.target.value})} className="text-white bg-gray-900 rounded-md w-full px-2 py-1 mt-2" placeholder="Plan Description" />
                                                    <label className="font-bold text-md text-white">Plan Price (USD)</label>
                                                    <MaskedInput required id="price-input" ref={this.price} mask={currencyMask} onChange={(e) => this.costPrice(e.target.value)} className="bg-gray-900 disabled:bg-gray-700 my-2 px-2 py-2 rounded-md text-right w-full text-gray-200" placeholder={`$0.00`} />
                                                    <div id="fees" className="flex grid grid-rows float-right">
                                                        <span className="float-right text-gray-400">Platform Fees: <span className="text-gray-200 float-right px-4">-{(() => {
                                                            if (this.state.planPrice === 0) {
                                                                return "";
                                                            } else {
                                                                var fees = this.state.planPrice * 0.15;
                                                                var rounded = Math.round(fees * 100) / 100;
                                                                return `$${rounded}`;
                                                            }
                                                        })()}</span></span>
                                                        <span className="float-right text-gray-400">Total Earnings: <span className="text-gray-200 float-right px-4 font-bold">${(() => {
                                                            if (this.state.planPrice === 0) {
                                                                return "0.00";
                                                            } else {
                                                                var total = this.state.planPrice - (this.state.planPrice * 0.15);
                                                                var rounded = Math.round(total * 100) / 100;
                                                                return rounded; // need to format this number somehow ...
                                                            }
                                                        })()}</span></span>
                                                    </div>
                                                    <button className="text-white bg-red-600 rounded-md w-full px-2 py-1 mt-4">Create Plan</button>
                                                </form>
                                            </div>
                                            {/* <p className="mt-2 text-sm/6 text-white/50">
                                                Your payment has been successfully submitted. We've sent you an email with all of the details of
                                                your order.
                                            </p> */}
                                            <div className="mt-6">
                                                <div className="flex grid grid-cols-2 gap-2">
                                                </div>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                </Transition>
            })()}

            {/* Edit Plan */}
            {(() => {
                return <Transition appear show={this.state.editPlan}>
                    <Dialog as="div" className="relative z-30 focus:outline-none" onClose={() => this.setState({editPlan: false})}>
                            <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-md rounded-xl bg-gray-800 p-6 backdrop-blur-2xl">
                                            <DialogTitle as="h3" className="text-base/7 font-bold text-white">
                                                Edit Subscription Plan
                                            </DialogTitle>
                                            <div className="flex grid grid-rows mt-4">
                                                <form onSubmit={() => this.editPlanSubmit()}>
                                                    <label className="font-bold text-md text-white">Plan Name</label>
                                                    <input required defaultValue={this.state.planName} onChange={(e) => this.setState({planName: e.target.value})} type="text" className="text-white bg-gray-900 rounded-md w-full px-2 py-1 my-2" placeholder="Plan Name" />
                                                    {/* <input type="number" className="text-white bg-gray-700 rounded-md w-full px-2 py-1 mt-2" placeholder="Price (USD)" /> */}
                                                    <label className="font-bold text-md text-white">Plan Description</label>
                                                    <textarea required defaultValue={this.state.planDescription} rows={5} onChange={(e) => this.setState({planDescription: e.target.value})} className="text-white bg-gray-900 rounded-md w-full px-2 py-1 mt-2" placeholder="Plan Description" />
                                                    <label className="font-bold text-md text-white">Plan Price (USD)</label>
                                                    <MaskedInput required defaultValue={this.state.planPrice} id="price-input" ref={this.price} mask={currencyMask} onChange={(e) => this.costPrice(e.target.value)} className="bg-gray-900 disabled:bg-gray-700 my-2 px-2 py-2 rounded-md text-right w-full text-gray-200" placeholder={`$0.00`} />
                                                    <div id="fees" className="flex grid grid-rows float-right">
                                                        <span className="float-right text-gray-400">Platform Fees: <span className="text-gray-200 float-right px-4">-{(() => {
                                                            if (this.state.planPrice === 0) {
                                                                return "";
                                                            } else {
                                                                var fees = this.state.planPrice * 0.15;
                                                                var rounded = Math.round(fees * 100) / 100;
                                                                return `$${rounded}`;
                                                            }
                                                        })()}</span></span>
                                                        <span className="float-right text-gray-400">Total Earnings: <span className="text-gray-200 float-right px-4 font-bold">${(() => {
                                                            if (this.state.planPrice === 0) {
                                                                return "0.00";
                                                            } else {
                                                                var total = this.state.planPrice - (this.state.planPrice * 0.15);
                                                                var rounded = Math.round(total * 100) / 100;
                                                                return rounded; // need to format this number somehow ...
                                                            }
                                                        })()}</span></span>
                                                    </div>
                                                    <button className="text-white bg-red-600 rounded-md w-full px-2 py-1 mt-4">Create Plan</button>
                                                </form>
                                            </div>

                                            <div className="mt-6">
                                                <div className="flex grid grid-cols-2 gap-2">
                                                </div>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                </Transition>
            })()}
        </ConnectComponentsProvider>
    }
}